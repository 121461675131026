import * as React from "react";
import { useSelector } from "react-redux";
import { sortMeasurementsRows } from '../MetaDataTable/MetaDataSortingFunctions';

import { MetaDataTable } from "../MetaDataTable/MetaDataTable";
import { selectCheckpoints, selectDisplayWithInfoRow } from '../../redux/selectors';

export function MeasurementsOrdersTable() {
  const orders: Order[] = useSelector(selectDisplayWithInfoRow(order => !order.approve_date && !order.measure_date && !order.cancel_date));
  const checkpoints: CheckpointType[] = useSelector(selectCheckpoints('measurementsCheckpoints'));

  return (
    <MetaDataTable
      title="Measurements"
      actionType="measurement"
      rows={sortMeasurementsRows(orders)}
      checkpoints={checkpoints}
      noMoveBackward
      disableSorting
      addExtraInfo
      withFlag
    />
  );
}
