import { NormalizedSchema } from 'normalizr';

export const FETCH_COMMENTS = 'FETCH_COMMENTS';
export const FETCH_DEVIATIONS = 'FETCH_DEVIATIONS';
export const FETCH_FAULTS_COMMENT = 'FETCH_FAULTS_COMMENT';
export const FETCH_COMMENTS_BY_PLATE = 'FETCH_COMMENTS_BY_PLATE';
export const FETCH_COMMENTS_BY_ORDER = 'FETCH_COMMENTS_BY_ORDER';
export const FETCH_COMMENT_BY_ID = 'FETCH_COMMENT_BY_ID';
export const FETCH_IMPORTANT_COMMENT_BY_ID = 'FETCH_COMMENT_BY_ID';
export const DELETE_COMMENT_BY_SOCKET = 'DELETE_COMMENT_BY_SOCKET';

export type FetchCommentsAction = { readonly type: typeof FETCH_COMMENTS, comments: NormalizedSchema<CommentEntity, number[]> }
export type FetchDeviationsAction = { readonly type: typeof FETCH_DEVIATIONS, comments: NormalizedSchema<CommentEntity, number[]> }
export type FetchFaultsAction = { readonly type: typeof FETCH_FAULTS_COMMENT, comments: NormalizedSchema<CommentEntity, number[]> }
export type FetchCommentsByPlateAction = { readonly type: typeof FETCH_COMMENTS_BY_PLATE, id: number, comments: CommentType[] }
export type FetchCommentsByOrderAction = { readonly type: typeof FETCH_COMMENTS_BY_ORDER, id: number, comments: CommentType[] }
export type FetchCommentByIdAction = { readonly type: typeof FETCH_COMMENT_BY_ID, plate_id?: number, order_id: number, comment: CommentType }
export type FetchImportantCommentByIdAction = { readonly type: typeof FETCH_IMPORTANT_COMMENT_BY_ID, plate_id?: number, order_id: number, comment: CommentType }
export type DeleteCommentBySocketAction = { readonly type: typeof DELETE_COMMENT_BY_SOCKET, id: number, order_id: number, plate_id: number }

export type CommentActionTypes =
  FetchCommentsAction
  | FetchDeviationsAction
  | FetchFaultsAction
  | FetchCommentsByOrderAction
  | FetchCommentsByPlateAction
  | FetchCommentByIdAction
  | FetchImportantCommentByIdAction
  | DeleteCommentBySocketAction
