import * as React from 'react';
import { useSelector } from 'react-redux';

type DataTableUserCellType = {
  user_id: number
}

export const DataTableUserCell: React.FunctionComponent<DataTableUserCellType> = ({ user_id }) => {
  const user = useSelector((state: GlobalStoreType) => state.configuration.users[user_id]?.name);
  return <React.Fragment>{user}</React.Fragment>;
};
