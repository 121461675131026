export const FETCH_PLATE_TYPES = 'FETCH_PLATE_TYPES';
export const FETCH_PLATE_TYPES_BY_SOCKET = 'FETCH_PLATE_TYPES_BY_SOCKET';

export type FetchNewPlateTypesAction = { readonly type: typeof FETCH_PLATE_TYPES, plateTypes: PlateType[] }
export type FetchPlateTypesBySocketAction = { readonly type: typeof FETCH_PLATE_TYPES_BY_SOCKET, plateTypes: PlateType }

export type PlateTypeActionTypes =
  FetchNewPlateTypesAction
  | FetchPlateTypesBySocketAction

