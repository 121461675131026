import * as React from 'react'
import { Button } from "@material-ui/core";

type Props = { processNextStage: () => void; }

export const WizardStepConfirmButton: React.FC<Props> = ({ processNextStage }) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    processNextStage();
  }

  return (
    <Button variant="contained" color="primary" onClick={handleClick}>
      Confirm
    </Button>
  )
}
