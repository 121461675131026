import * as React from 'react';

import { COMMENT_FOR } from '../../shared/constants';

import { DataTableInformationCommentAdd } from './DataTableInformationCommentAdd';
import { DataTableInformationTableComments } from './DataTableInformationTableComments';
import { DataTableInformationTableFilter } from './DataTableInformationTableFilter';
import { DataTableInformationOldComments } from './DataTableInformationOldComments';

type DataTableInformationCommentsType = {
  orderId?: number,
  plateId?: number,
  comments: CommentType[],
};

export function DataTableInformationComments({ plateId, orderId, comments }: DataTableInformationCommentsType) {
  const [filter, setFilter] = React.useState([] as string[]);
  const onChange = (value: string) => () => {
    if (filter.includes(value)) {
      return setFilter(filter.filter(element => element !== value));
    }

    setFilter([...filter, value]);
  };

  const sortedComments = comments
    .filter(comment => filter ? filter.some(value => comment.comment_for.includes(value)) : true);

  const newComments = sortedComments.filter(c => !c.is_old);
  const oldComments = sortedComments.filter(c => c.is_old);

  return (
    <React.Fragment>
      <DataTableInformationTableFilter filters={COMMENT_FOR} filter={filter} onChange={onChange} />
      <DataTableInformationCommentAdd plateId={plateId} orderId={orderId} canAdd={!filter} filter={filter} />
      {!!oldComments.length && (
        <DataTableInformationOldComments
          withDelete
          withAddedTo
          plateId={plateId}
          orderId={orderId}
          comments={oldComments}
        />
      )}
      <DataTableInformationTableComments
        plateId={plateId}
        orderId={orderId}
        comments={newComments}
        withDelete
        withAddedTo
      />

    </React.Fragment>
  );
}
