import React from 'react';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import {
  SearchState,
  PagingState,
  SummaryState,
  TreeDataState,
  CustomTreeData,
  IntegratedSummary,
  IntegratedFiltering, IntegratedPaging,
  DataTypeProvider, RowDetailState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableTreeColumn,
  Table,
  Toolbar, PagingPanel,
  SearchPanel
} from '@devexpress/dx-react-grid-material-ui';
import dayjs from 'dayjs';

import { Cell, OrderReferenceNumberCell, TypesCell } from './MetaDataTableCell';
import { getActionType } from './MetaDataTableActionProvider';
import { MetaDataGroupingTableRow } from './MetaDataGroupingTableRow';
import { getColumns } from './MetaDataColumnsProvider';
import { sortRows } from './MetaDataSortingFunctions';
import { DataTableDate } from '../../components/DataTable/DataTableDate';
import { PageTitle } from '../../components/Page/PageTitle';
import { useIsAdmin, useIsMonter } from '../withIsAdmin';
import { FilterOrders } from '../../components/Filters/FilterOrders';
import { Theme } from '@material-ui/core';
import { COMMENT_TYPE } from '../../shared/constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  title: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const getChildRows = (row: any, rootRows: any) => {
  const childRows = rootRows.filter((r: any) => r.parentId === (row ? row.rootId : null));
  return childRows.length ? childRows : null;
};

const groupBy = function (xs: any[], key: string) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const getUnique = (arr: any[], comp: string) => arr
  .map(e => e[comp])
  .map((e, i, final) => final.indexOf(e) === i && i)
  .filter((e: any) => arr[e])
  .map((e: any) => arr[e]);

const isDeliverySameForAll = (element: Plate[], key: 'delivery_date' | 'delivery_method') => [...new Set(element.map(s => s[key]))].length === 1;
const earliestDate = (element: Plate[]) => element.sort((a, b) => dayjs(a.delivery_date).isBefore(dayjs(b.delivery_date)) ? -1 : 1)[0].delivery_date;
const isOnHold = (element: Plate[]) => element.every(plate => plate.comments
  .some(comment => [COMMENT_TYPE.ON_HOLD, COMMENT_TYPE.ON_HOLD_FLOW].includes(comment.comment_type as any)
    && comment.comment_status && comment.plate_id === plate.id));

const getUniqueGroups = (rows: Plate[]) => {
  const groupedPlates = groupBy(rows, 'order_reference_number');
  const groups = Object.keys(groupedPlates).map(key => ({
    deliveryDate: earliestDate(groupedPlates[key]),
    deliveryMethod: isDeliverySameForAll(groupedPlates[key], 'delivery_method'),
    onHold: isOnHold(groupedPlates[key]),
    count: groupedPlates[key].length,
    key: key
  }));

  const uniqueRows = getUnique(rows, 'order_reference_number')
    .map(elem => ({ ...elem, ...groups.find(c => c.key === elem.order_reference_number) }));

  return uniqueRows.map((element: Plate & { count: number, onHold: boolean }) => ({
    parentId: null,
    reference_number: `(${element.count})`,
    rootId: element.order_reference_number,
    order_reference_number: element.order_reference_number,
    id: element.order_id,
    order_id: element.order_id,
    name: element.material?.name,
    type: element.material?.type,
    thickness: element.material?.thickness,
    need_impregnation: element.material?.need_impregnation,
    comments: element.comments,
    delivery_date: element.delivery_date,
    onHold: element.onHold,
    noDeliveries: true
  }));
};


const PlateTypesFormatter = (props: any) => {
  if (!props.row.order_id) {
    return null;
  }

  return (
    <TypesCell {...props.row} />
  );
};

const ProceedFormatter = (props: any) => {
  if (props.row.rootId) {
    return null;
  }

  return getActionType({
    actionType: 'plate',
    checkpoints: props.row.checkpoints,
    data: props.row,
    stageTitle: props.row.stageTitle
  });
};

const OrderReferenceNumberFormatter = (props: any) => {
  if (props.row.noOrderReferenceNumber) {
    return null;
  }

  return (
    <OrderReferenceNumberCell {...props.row} />
  );
};

const DateFormatter = (props: any) => {
  if (props.row.noOrderReferenceNumber) {
    return null;
  }

  return (
    <DataTableDate orderId={props.row.order_id || props.row.id} plateId={props.row.order_id ? props.row.id : ''}
                   delivery_date={props.row.delivery_date} disable />
  );
};

const PlateTypesFormatterProvider = (props: any) => (
  <DataTypeProvider
    formatterComponent={PlateTypesFormatter}
    {...props}
  />
);

const ProceedFormatterProvider = (props: any) => (
  <DataTypeProvider
    formatterComponent={ProceedFormatter}
    {...props}
  />
);

const OrderReferenceNumberFormatterProvider = (props: any) => (
  <DataTypeProvider
    formatterComponent={OrderReferenceNumberFormatter}
    {...props}
  />
);

export const PlateDateFormatter = (props: any) => (
  <DataTypeProvider
    formatterComponent={DateFormatter}
    {...props}
  />
);

const desktopColumns = [
  { name: 'order_reference_number', title: 'Order' },
  { name: 'reference_number', title: 'Plate' },
  { name: 'types', title: 'Types' },
  { name: 'name', title: 'Material' },
  { name: 'type', title: 'Surface' },
  { name: 'plate_number', title: 'Platenumber' },
  { name: 'thickness', title: 'Thickness' },
  { name: 'delivery_date', title: 'Finish Production' },
  { name: 'delivery_method', title: 'Packing Method' },
  { name: 'proceed', title: ' ' },
];

const createRowObjects = (elem: Plate, checkpoints: CheckpointType[], noMoveBackward: boolean, title: string) => ({
  ...elem,
  noOrderReferenceNumber: true,
  parentId: elem.order_reference_number,
  name: elem.material?.name,
  type: elem.material?.type,
  thickness: elem.material?.thickness,
  need_impregnation: elem.material?.need_impregnation,
  checkpoints,
  noMoveBackward,
  stageTitle: title?.toUpperCase()
});

export const MetaDataGroupingTable = ({ rows, checkpoints, title, noMoveBackward, withFlag }: any) => {
  const classes = useStyles();
  const isAdmin = useIsAdmin();
  const isMontor = useIsMonter();

  const data = getUniqueGroups(sortRows(rows));

  const setData = data.map((row: any) => ({ ...row, withFlag }));

  const [totalSummaryItems] = React.useState([{ columnName: 'order_reference_number', type: 'count' }]);
  const [treeSummaryItems] = React.useState([{ columnName: 'order_reference_number', type: 'count' }]);

  const { desktopColumnsWidth } = getColumns('find');

  const columns = desktopColumns.filter(column => ['Find', 'Cut'].includes(title) || column.name !== 'plate_number');
  const columnsWidth = desktopColumnsWidth;

  return (
    <React.Fragment>
      <PageTitle title={title} />
      <Paper className={classes.root}>
        <Grid
          getRowId={(row: any) => {
            console.log(row.id || row.parentId)
            return row.id || row.parentId
          }}
          rows={[...setData, ...rows.map((plate: Plate) => createRowObjects(plate, checkpoints, noMoveBackward, title))]}
          columns={columns}
        >
          <OrderReferenceNumberFormatterProvider for={['order_reference_number']} />
          <SearchState />
          <PagingState defaultCurrentPage={0} pageSize={30} />
          <PlateTypesFormatterProvider for={['types']} />
          <ProceedFormatterProvider for={['proceed']} />
          <PlateDateFormatter for={['delivery_date']} />
          <TreeDataState />
          <SummaryState
            totalItems={totalSummaryItems}
            treeItems={treeSummaryItems}
          />
          <CustomTreeData getChildRows={getChildRows} />
          <IntegratedSummary />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Toolbar />
          <SearchPanel />
          {(isAdmin || isMontor) && <FilterOrders />}
          <RowDetailState />
          <Table cellComponent={Cell} rowComponent={MetaDataGroupingTableRow} columnExtensions={columnsWidth} />
          <TableHeaderRow />
          <TableTreeColumn for="order_reference_number" />
          <PagingPanel />
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

