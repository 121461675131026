import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Paper, Typography, Divider, Theme, MenuItem, Select } from '@material-ui/core';
import dayjs from 'dayjs';

import { MetaDataTable } from '../MetaDataTable/MetaDataTable';
import { selectFaultyPlates } from '../../selectors/PlateSelectors';
import { fetchCompletedPlates } from '../../redux/plates/PlateActions';
import { fetchFaultComments } from '../../redux/comments/CommentActions';
import { fetchCompletedOrders } from "../../redux/orders/OrderActions";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: 0,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 1)
  },
  value: {
    fontWeight: 700,
    marginLeft: theme.spacing(2)
  },
  select: {
    padding: theme.spacing(1),
  },
  selectBase: {
    marginRight: theme.spacing(1)
  }
}));

const months = (year: number) => {
  const currentMonth = dayjs().year() === year ? dayjs().month() : 11;
  const allMonths = [];
  for (let i = 0; i <= currentMonth; i++) {
    allMonths.push(i);
  }

  return allMonths;
};

const years = (plates: Plate[]) => {
  const min = dayjs(plates[plates.length - 1]?.fault_at).year();
  const max = dayjs(plates[0]?.fault_at).year();

  const allYear = [];
  for (let i = min; i <= max; i++) {
    allYear.push(i);
  }

  return allYear;
}

export function FaultOrdersTable() {
  const handleYearChange = (event: React.ChangeEvent<any>) => {
    const year = event.target.value;
    setYear(parseInt(year));
    setMonths(months(year));
    const currentDate = dayjs();
    setMonth(currentDate.year() === year ? dayjs().month() : 0);
  };

  const handleMonthChange = (event: React.ChangeEvent<any>) => setMonth(parseInt(event.target.value));

  const styles = useStyles();
  const dispatch = useDispatch();
  const plates: Plate[] = useSelector(selectFaultyPlates);

  const [allYears, setYears] = React.useState<number[]>([]);
  const [year, setYear] = React.useState<number>(dayjs().year());

  const [allMonths, setMonths] = React.useState(months(year));
  const [month, setMonth] = React.useState<number>(dayjs().month());

  const currentYearPlates = plates.filter(element => dayjs(element.fault_at).year() === year).reduce((a, b) => a + (b.cost ? parseInt(b.cost) : 0), 0);
  const currentMonthPlates = plates.filter(element => dayjs(element.fault_at).year() === year && dayjs(element.fault_at).month() === month).reduce((a, b) => a + (b.cost ? parseInt(b.cost) : 0), 0);

  React.useEffect(() => {
    dispatch(fetchCompletedOrders())
    dispatch(fetchCompletedPlates());
    dispatch(fetchFaultComments());
  }, []);

  React.useEffect(() => {
    if (plates.length) {
      setYears(years(plates));
    }
  }, [plates]);

  return (
    <MetaDataTable
      pageSubtitle={
        <Paper className={styles.root}>
          <div className={styles.row}>
            <Typography variant='subtitle1'>
              This year cost
            </Typography>
            <Typography variant='button' className={styles.value}>
              {currentYearPlates}
            </Typography>
          </div>
          <Divider />
          <div className={styles.row}>
            <Select
              labelId="year-label"
              id="year"
              value={year}
              onChange={handleYearChange}
              variant="outlined"
              classes={{ root: styles.select }}
              className={styles.selectBase}
            >
              {allYears.map(element => (
                <MenuItem key={element} value={element}>{element}</MenuItem>
              ))}
            </Select>
            <Select
              labelId="month-label"
              id="month"
              value={month}
              onChange={handleMonthChange}
              variant="outlined"
              classes={{ root: styles.select }}
              className={styles.selectBase}
            >
              {allMonths.map(element => (
                <MenuItem key={element} value={element}>{element + 1}</MenuItem>
              ))}
            </Select>
            <Typography variant='subtitle1'>
              month cost
            </Typography>
            <Typography variant='button' className={styles.value}>
              {currentMonthPlates}
            </Typography>
          </div>
        </Paper>
      }
      disableSorting
      title="Faults"
      actionType="faults"
      rows={plates}
    />
  );
}

