import * as React from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@material-ui/core';

import { useIsAdmin } from '../../containers/withIsAdmin';
import { setFaultCostPlate } from '../../redux/actions/plateActions';
import { PreventPropagation } from '../PreventPropagation';

type DataTableCostCellType = {
  faultId: number,
  cost: number
}

export const DataTableCostCell: React.FunctionComponent<DataTableCostCellType> = ({ faultId, cost }: DataTableCostCellType) => {
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(cost || '0');
  const debounceTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    setValue(cost);
  }, [cost]);

  if (!isAdmin) {
    return <React.Fragment>{value}</React.Fragment>;
  }

  const debouncedOnChange = (newValue: number | string) => {
    dispatch(setFaultCostPlate(faultId, newValue));
  };

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target.value;
    setValue(newValue);
    
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      debouncedOnChange(newValue);
    }, 300);
  };

  return (
    <PreventPropagation>
      <TextField
        onChange={onChange}
        value={value}
        type="number"
        autoComplete="off"
        fullWidth
      />
    </PreventPropagation>
  );
};
