import { NormalizedSchema } from 'normalizr';

export const FETCH_FILES = 'FETCH_FILES';
export const FETCH_FILES_BY_PLATE = 'FETCH_FILES_BY_PLATE';
export const FETCH_FILES_BY_ORDER = 'FETCH_FILES_BY_ORDER';

export const FETCH_FILE_BY_SOCKET = 'FETCH_FILE_BY_SOCKET';
export const DELETE_FILE_BY_SOCKET = 'DELETE_FILE_BY_SOCKET';

export type FetchNewFilesAction = { readonly type: typeof FETCH_FILES, files: NormalizedSchema<FileEntity, number[]> }
export type FetchFilesByPlate = { readonly type: typeof FETCH_FILES_BY_PLATE, id: number, files: FileType[] }
export type FetchFilesByOrder = { readonly type: typeof FETCH_FILES_BY_ORDER, id: number, files: FileType[] }

export type FetchFileBySocket = { readonly type: typeof FETCH_FILE_BY_SOCKET, order_id: number, plate_id: number, file: FileType }
export type DeleteFileBySocket = { readonly type: typeof DELETE_FILE_BY_SOCKET, order_id: number, plate_id: number, file: FileType }

export type FileActionTypes =
  FetchNewFilesAction | FetchFilesByPlate | FetchFilesByOrder | FetchFileBySocket | DeleteFileBySocket
