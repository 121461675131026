const selectOrderInfo = (state: GlobalStoreType) => (element: Order) => {
  const comments = state.comments.list
    .map(key => state.comments.entities[key])
    .filter(comment => comment.order_id === element.id);

  if (!element.measure_date) {
    element.history = {};
  }

  return { ...element, comments, actions: [], files: [] };
};

export const selectDisplayNewOrderRow = (filterFunc?: ((element: Order) => boolean)) => (state: GlobalStoreType) => {
  const entities = state.orders.list.map(key => state.orders.entities[key]);
  if (filterFunc) {
    return entities.filter(filterFunc).map(selectOrderInfo(state));
  }

  return entities.map(selectOrderInfo(state));
};

export const selectDisplayWithInfoRow = (filterFunc?: ((element: Order) => boolean)) => (state: GlobalStoreType) => {
  const entities = state.orders.list.map(key => state.orders.entities[key]);
  if (filterFunc) {
    return entities.filter(filterFunc).map(order => ({ ...order, ...state.orders.newOrdersInfoEntities[order.reference_number as any] })).map(selectOrderInfo(state));
  }

  return entities.map(order => ({ ...order, ...state.orders.newOrdersInfoEntities[order.reference_number as any] })).map(selectOrderInfo(state));
};

export const selectCheckpoints = (dictionary: keyof DictionaryStoreType) => (state: GlobalStoreType) => state.dictionary[dictionary];
