import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/styles';
import { Theme, Typography } from '@material-ui/core';

type FileDropzoneType = {
  onUpload: (evt: HTMLInputEvent) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  fullHeight: {
    display: 'flex',
    flex: '1 1 100%',
    flexDirection: 'column',
    outline: 'none'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderRadius: 10,
    borderColor: '#eee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    marginTop: theme.spacing(1),
    flex: '1 1 100%'
  }
}));

export const FileDropzone: React.FunctionComponent<FileDropzoneType> = ({ children, onUpload }) => {
  const styles = useStyles();
  const onDrop = (acceptedFiles: any) => onUpload({ target: { files: acceptedFiles } } as any);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className={styles.fullHeight}>
      <input hidden {...isDragActive ? getInputProps() : {}} />
      {isDragActive ? (
        <div className={styles.container}>
          <Typography variant="body1">
            Drop files here
          </Typography>
        </div>
      ) : (
        children
      )}
    </div>
  );
};
