import * as React from "react";
import { TableRow, Tooltip } from "@material-ui/core";

import { MetaDataTableRowInformationElement } from "./MetaDataTableRowInformationElement";
import { useStylesTable } from "./MetaDataStyles";
import { COMMENT_TYPE } from "../../shared/constants";
import classnames from "classnames";

export const MetaDataGroupingTableRow = ({ row, ...restParams }: any) => {
  const [isOpen, setOpen] = React.useState(false);
  const classes = useStylesTable();

  const handleMock = () => null;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const isPlate = !!row.order_id && !row.rootId;
  const isOrderOnHold = (row: Order & Plate & { onHold: boolean }, comment: CommentType) => {
    if (row.onHold) {
      return comment;
    }

    if (isPlate) {
      if (comment.order_id === row.order_id && !comment.plate_id) {
        return comment;
      }

      if (comment.order_id === row.order_id && comment.plate_id === row.id) {
        return comment;
      }

      return null;
    }

    return null;
  };

  const onHoldComment = row.comments?.find((comment: CommentType) => 
    [COMMENT_TYPE.ON_HOLD, COMMENT_TYPE.ON_HOLD_FLOW].includes(comment.comment_type as any)
    && comment.comment_status && isOrderOnHold(row, comment));

  let commentText = onHoldComment?.comment_text || "";
  let classRow = classnames({ [classes.onHoldAnimation]: onHoldComment });

  if (onHoldComment?.is_separately) {
    commentText += " - will be mounted separately";
  }

  const onClick = row.noDeliveries ? handleMock : handleOpen;

  return (
    <React.Fragment>
      <Tooltip title={commentText} classes={{ popper: classes.tooltip }}>
        <TableRow  {...restParams} onClick={onClick} style={{ cursor: "pointer" }} className={classRow} />
      </Tooltip>
      <MetaDataTableRowInformationElement isOpen={isOpen} handleClose={handleClose} row={row} />
    </React.Fragment>
  );
};
