import * as React from 'react';
import { IntegratedGrouping, GroupingState, } from '@devexpress/dx-react-grid';
import { Grid, TableHeaderRow, Table, TableGroupRow } from '@devexpress/dx-react-grid-material-ui';

import { PlateDateFormatter } from '../../containers/MetaDataTable/MetaDataGroupingTable';
import { HistoryTableCheckboxFormatter } from './HistoryTableFormatters';
import { HistoryTableColumnsWidth, HistoryTableGroupingColumns } from './HistoryTableColumns';
import dayjs from 'dayjs';
import { Cell } from '../../containers/MetaDataTable/MetaDataTableCell';

type DataTableInformationGroupingHistoryType = {
  rows: HistoryMetaAction[]
}

export function HistoryGroupingTable({ rows }: DataTableInformationGroupingHistoryType) {
  return (
    <Grid
      rows={rows
        .sort((a, b) => a.plate! > b.plate! ? 1 : -1)
        .sort((a, b) => dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1)
      }
      columns={HistoryTableGroupingColumns}
    >
      <HistoryTableCheckboxFormatter for={['value']} />
      <PlateDateFormatter for={['created_at']} />
      <GroupingState grouping={[{ columnName: 'plate' }]} />
      <IntegratedGrouping />
      <Table cellComponent={Cell} columnExtensions={HistoryTableColumnsWidth} />
      <TableHeaderRow />
      <TableGroupRow />
    </Grid>
  );
}
