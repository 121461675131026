import * as React from "react";

import { useSelector } from "react-redux";
import { PLATE_TYPE } from "../shared/constants";
import { MetaDataGroupingTable } from "./MetaDataTable/MetaDataGroupingTable";
import { selectCheckpoints } from '../redux/selectors';
import { selectPlateFullData } from '../selectors/PlateSelectors';

export function FindOrdersTable() {
  const plates: Plate[] = useSelector(selectPlateFullData(PLATE_TYPE.FIND));
  const checkpoints: CheckpointType[] = useSelector(selectCheckpoints("findCheckpoints"));
  return (
    <MetaDataGroupingTable
      rows={plates}
      title="Find"
      checkpoints={checkpoints}
      noMoveBackward
      withFlag
    />
  );
}
