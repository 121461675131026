import * as React from 'react';
import { TextField } from '@material-ui/core';

type MyDialogUserType = {
  onChange: (evt: any) => void,
  user: number,
  userError?: boolean,
  disabled?: boolean
};

export function MyDialogUser({ onChange, user, userError, disabled }: MyDialogUserType) {
  return (
    <TextField
      onChange={onChange}
      value={user}
      error={userError}
      fullWidth
      label="User ID"
      type="number"
      autoComplete="off"
      disabled={disabled}
    />
  );
}
