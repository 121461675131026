import React from 'react';
import { makeStyles } from "@material-ui/styles";

type EmbeddedPageType = {
  url: string;
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
  },
  frame: {
    width: '100%',
    height: '100%',
    border: 'none'
  },
  value: {
    flex: '1',
    padding: 20
  }
}));

export const EmbeddedPage = ({ url }: EmbeddedPageType) => {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(true);

  const handleLoad = () => {
    setLoading(false);
  };


  return (
    <div className={classes.root}>
      {loading && <div className={classes.value}>Loading...</div>}
      <iframe
        src={url}
        className={classes.frame}
        onLoad={handleLoad}
      />
    </div>
  );
};
