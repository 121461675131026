import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TableCell,
  TableRow,
  Typography,
  FormControlLabel,
  Checkbox,
  Theme,
  CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { formatToDateWithTime } from '../../services/DateFormatter';

import { MyDialog } from '../Dialog/MyDialog';
import { deleteFile, setOld } from '../../redux/actions/fileActions';
import { FileUploadTableImage } from './FileUploadTableImage';
import { FileUploadTablePdf } from './FileUploadTablePdf';
import { useIsAdmin } from '../../containers/withIsAdmin';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 60
  },
  oldDrawing: {
    width: 70,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  mobileColumn: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  }
}));

type FileUploadTableRowType = {
  file: FileType & FileUploadServer,
  withActions?: boolean,
  isDrawing?: boolean,
};

export function FileUploadTableRow({ file, withActions, isDrawing }: FileUploadTableRowType) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state: GlobalStoreType) => state.configuration.users[file.user_id]?.name);

  const isAdmin = useIsAdmin();

  const confirmDelete = async () => await dispatch(deleteFile(file.id));
  const confirmOld = async () => await dispatch(setOld(file, !file.is_old));

  const showHandler = () => {
    const path = file.path;
    if (!path) {
      return <CircularProgress />;
    }

    if (path && (path.includes('.pdf') || path.includes('.dxf') || path.includes('.prlsketch') || path.includes('.prlprj') || path.includes('.prl8') || path.includes('.og') || path.includes('.prlo'))) {
      return <FileUploadTablePdf file={file} />;
    }

    return <FileUploadTableImage file={file} />;
  };

  return (
    <TableRow className={classes.root}>
      <TableCell padding="checkbox">
        {showHandler()}
      </TableCell>
      <TableCell align="right" padding="checkbox" style={{ width: '80px' }}>
        {user}
      </TableCell>
      <TableCell align="right" padding="checkbox" style={{ width: 140 }} className={classes.mobileColumn}>
        {formatToDateWithTime(file.created_at)}
      </TableCell>
      {withActions && (
        <React.Fragment>
          {(isAdmin && isDrawing && (
            <TableCell align="center" padding="checkbox" style={{ width: 40 }} className={classes.oldDrawing}>
              <FormControlLabel
                control={
                  <Checkbox value="isOld" />
                }
                style={{ margin: 0 }}
                checked={file.is_old}
                onChange={confirmOld}
                label=""
              />
            </TableCell>
          ))}
          <TableCell align="right" padding="checkbox" style={{ width: 60 }} className={classes.mobileColumn}>
            {(isAdmin && file.id) && (
              <MyDialog
                dialogTitle="Test"
                icon={<DeleteIcon />}
                size="medium"
                color="secondary"
                modalSize="sm"
                submitButtonColor="secondary"
                submitButtonText="Delete"
                onSubmit={confirmDelete}
              >
                <Typography variant="subtitle2">
                  Are you sure that you want to delete file
                  <Typography variant="subtitle2" style={{ fontWeight: 700 }}>{file.name}?</Typography>
                </Typography>
              </MyDialog>
            )}
          </TableCell>
        </React.Fragment>
      )}
    </TableRow>
  );
}
