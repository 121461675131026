import { InjectedProps } from "@material-ui/core/withMobileDialog";
import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  withMobileDialog
} from "@material-ui/core";
import { Button } from "../Button";

type HandleSubmitParams = {
  type: string,
  count: number,
  plate: SetPlate
}

type SetPlateTypesCountType = {
  type: SetPlateType,
  plate: SetPlate,
  handleSubmit: (params: HandleSubmitParams) => void,
  handleClose: () => void
}

const SetPlateTypesCount = ({ type, plate, handleSubmit, handleClose, fullScreen }: SetPlateTypesCountType & InjectedProps) => {
  const [count, setCount] = React.useState(type.count);

  const onSubmit = () => handleSubmit({ plate, count, type: type.type });
  const onCountChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.value.match(/[a-z]/i)) {
      setCount(parseFloat(evt.target.value));
    } else {
      // @ts-ignore
      setCount(evt.target.value);
    }
  };

  return (
    <div>
      <Dialog
        open
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Number</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Insert occurrence number of type {type.type}
          </DialogContentText>
          <TextField
            onChange={onCountChange}
            autoFocus
            margin="dense"
            id="number"
            label="Insert number"
            type="number"
            fullWidth
            autoComplete="off"
            value={count}
            inputProps={{ step: 0.5 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={onSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const connected = withMobileDialog<SetPlateTypesCountType>()(SetPlateTypesCount);
export { connected as SetPlateTypesCount };
