import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { LockOrientationContext } from '../../components/LockOrientation';
import { useSelector } from 'react-redux';
import {
  IntegratedFiltering,
  SearchState,
  PagingState,
  IntegratedPaging,
  TreeDataState,
  CustomTreeData,
  DataTypeProvider,
  RowDetailState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  SearchPanel,
  TableHeaderRow,
  TableRowDetail,
  Table,
  Toolbar,
  PagingPanel,
  TableTreeColumn
} from '@devexpress/dx-react-grid-material-ui';
import { Paper, Theme } from '@material-ui/core';

import { CANCEL_TYPE, PLATE_TYPE } from '../../shared/constants';
import { Cell, OrderReferenceNumberCell } from '../../containers/MetaDataTable/MetaDataTableCell';
import { MetaDataTableRow } from '../../containers/MetaDataTable/MetaDataTableRow';
import { selectPlateEntities } from '../../selectors/PlateSelectors';
import { selectDisplayWithInfoRow } from '../../redux/selectors';
import { getContentComponent } from '../../containers/MetaDataTable/MetaDataContentComponentProvider';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flex: 1,
  }
}));

const columns = [
  { name: 'order_reference_number', title: 'Order Id' },
  { name: 'reference_number', title: 'Plate Id' },
  { name: 'current_stage', title: 'Current Stage' }
];

const desktopColumnsWidth = [
  { columnName: 'order_reference_number', width: 140 },
  { columnName: 'reference_number', width: 100 },
  { columnName: 'current_stage' },
];

const getStage = (order: Partial<Order>) => {
  if (!order.measure_date) {
    return 'MEASUREMENTS';
  }

  if (!order.approve_date) {
    return 'INTERNAL_CONTROL';
  }

  return 'PREPARE';
};

const groupBy = function (xs: any[], key: string) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const getUnique = (arr: any[], comp: string) => arr
  .map(e => e[comp])
  .map((e, i, final) => final.indexOf(e) === i && i)
  .filter((e: any) => arr[e])
  .map((e: any) => arr[e]);

const getUniqueGroups = (rows: Partial<Order>[]) => {
  const groupedOrders = groupBy(rows, 'rootId');
  const groups = Object.keys(groupedOrders).map(key => ({
    count: groupedOrders[key].length,
    key: key
  }));

  const uniqueRows = getUnique(rows, 'rootId')
    .map(elem => ({
      ...elem, ...groups
        .find(c => c.key === elem.id)
    }));

  return uniqueRows.map((element: Order) => ({
    ...element,
    parentId: null,
    rootId: element.order_reference_number + '_' + element.reference_number,
    comments: element.comments
  }));
};

const createGroupInfoObjects = (elem: Partial<Order>) => ({
  isExtraInfo: true,
  parentId: elem.order_reference_number + '_' + elem?.reference_number,
  deliveryAddress: elem.deliveryAddress,
  materialStatus: elem.materialStatus,
  cutouts: elem.cutouts,
  craneRequired: elem.history && elem.history['Crane required'],
  extraHelpForLift: elem.history && elem.history['Extra help required to lift'],
  estimatedInstalationTime: elem.history && elem.history['Estimated Installation Time']
});

const getChildRows = (row: any, rootRows: any) => {
  const childRows = rootRows.filter((r: any) => r.parentId === (row ? row.rootId : null));
  return childRows.length ? childRows : null;
};

const ReferenceNumberFormatter = (props: any) => (
  <OrderReferenceNumberCell {...props.row} />
);

const ReferenceNumberFormatterProvider = (props: any) => (
  <DataTypeProvider
    formatterComponent={ReferenceNumberFormatter}
    {...props}
  />
);

export function SearchOrder() {
  const classes = useStyles();
  const theme = useTheme<Theme>();

  const isDesktopMedia = useMediaQuery(theme.breakpoints.up('sm'));
  const { isDesktopDevice, isMediumDevice } = React.useContext(LockOrientationContext);

  let isDesktop = isDesktopMedia;

  if (isDesktopDevice) {
    isDesktop = true;
  }

  if (isMediumDevice) {
    isDesktop = false;
  }

  const newOrderRows: Partial<Plate | Order>[] = useSelector((state: GlobalStoreType) => selectDisplayWithInfoRow(order => !order.cancel_date && !order.offer_version)(state)
    .map(order => ({
      ...order,
      reference_number: '',
      deliveryAddress: state.orders.newOrdersInfoEntities[+order.reference_number]?.deliveryAddress,
      order_reference_number: order.reference_number,
      current_stage: getStage(order)
    }))
    .sort((a, b) => {
      const textA = a.current_stage;
      const textB = b.current_stage;
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    }));

  const newOrderData = newOrderRows.map(row => ({
    ...row,
    rootId: row.id
  }));

  const plateRows: Plate[] = useSelector((state: GlobalStoreType) => selectPlateEntities(state)
    .filter((element: Plate) => element.current_stage !== PLATE_TYPE.COMPLETED && element.current_stage !== CANCEL_TYPE)
    .map((element: Plate) => ({
      ...element,
      order_reference_number: state.orders.entities[element.order_id]?.reference_number,
      deliveryAddress: state.orders.entities[element.order_id]?.deliveryAddress,
      materialStatus: state.orders.entities[element.order_id]?.materialStatus,
      cutouts: state.orders.entities[element.order_id]?.cutouts,
      history: state.orders.entities[element.order_id]?.history
    })));

  const plateData = plateRows.map(row => ({
    ...row,
    rootId: row.order_id + '_' + row.reference_number
  }));

  const data = [...newOrderData, ...plateData];
  const rows = [...newOrderRows, ...plateRows];

  const setRows = [...getUniqueGroups(data), ...rows.map((order: Partial<Order>) => createGroupInfoObjects(order))];

  return (
    <Paper className={classes.root}>
      <Grid
        getRowId={(row: any) => {
          console.log(row.id, row.parentId)
          return row.id || row.parentId
        }}
        rows={setRows} columns={columns}>
        <ReferenceNumberFormatterProvider for={['order_reference_number']} />
        <SearchState />
        <PagingState defaultCurrentPage={0} pageSize={10} />
        <TreeDataState />
        <CustomTreeData getChildRows={getChildRows} />

        <IntegratedFiltering />
        <IntegratedPaging />
        <Toolbar />
        <SearchPanel />
        <RowDetailState />

        <Table cellComponent={Cell} rowComponent={MetaDataTableRow} columnExtensions={desktopColumnsWidth} />
        <TableHeaderRow />
        {!isDesktop && (<TableRowDetail contentComponent={getContentComponent} />)}
        {isDesktop && (<TableTreeColumn for="order_reference_number" />)}
        <PagingPanel />
      </Grid>
    </Paper>
  );
}
