import * as React from 'react';
import { useDispatch } from 'react-redux';
// @ts-ignore
import io from 'socket.io-client';
import { getForEnv } from '../shared/getForEnv';
import { enforceRefresh } from '../redux/AppConfigurationRedux';
import { fetchCommentById, fetchImportantCommentById, deleteCommentBySocket } from '../redux/comments/CommentActions';
import { deleteFileBySocket, fetchFileBySocket } from '../redux/files/FileActions';
import { deleteOrderBySocket, fetchOrderBySocket, fetchOrderInfoBySocket } from '../redux/orders/OrderActions';
import { fetchFaultBySocket, fetchPlateBySocket } from '../redux/plates/PlateActions';
import { fetchPlateTypesBySocket } from '../redux/plateTypes/PlateTypeActions';
import { fetchNotificationBySocket } from '../redux/notifications/NotificationActions';

const url = window.location.protocol + '//' + window.location.hostname + ':' + getForEnv(3001, 443);
const socket = io(url);

export function SocketListener({ children }: any) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    socket.on('refresh-file', (data: FileType) => {
      dispatch(fetchFileBySocket(data));
    });
    socket.on('delete-file', (data: FileType) => {
      dispatch(deleteFileBySocket(data));
    });
    socket.on('refresh-comment', (data: any) => {
      dispatch(fetchCommentById(data));
    });
    socket.on('refresh-important-comment', (data: any) => {
      dispatch(fetchImportantCommentById(data));
      dispatch(fetchCommentById(data));
    });
    socket.on('delete-comment', (data: CommentType) => {dispatch(deleteCommentBySocket(data))});

    socket.on('refresh-order', (data: Order) => dispatch(fetchOrderBySocket(data)));
    socket.on('refresh-order-info', (data: OrderInfo) => dispatch(fetchOrderInfoBySocket(data)));

    socket.on('delete-order', (id: number) => dispatch(deleteOrderBySocket(id)));

    socket.on('refresh-plate', (data: Plate) => dispatch(fetchPlateBySocket(data)));
    socket.on('refresh-plates', (data: Plate[]) => data.map((plate: Plate) => dispatch(fetchPlateBySocket(plate))));
    socket.on('refresh-plate-type', (data: PlateType) => dispatch(fetchPlateTypesBySocket(data)));
    socket.on('refresh-plates-types', (data: PlateType[]) => data.map((plate: PlateType) => dispatch(fetchPlateTypesBySocket(plate))));

    socket.on('refresh-fault', (data: Fault) => dispatch(fetchFaultBySocket(data)));

    socket.on('refresh-notification', (data: NotificationType) => dispatch(fetchNotificationBySocket(data)));

    socket.on('disconnect', () => {
    });
    socket.on('reconnect_attempt', (attemptNumber: number) => {
      if (attemptNumber > 9) {
        dispatch(enforceRefresh());
      }
    });
    socket.on('error', () => {
      dispatch(enforceRefresh());
    });
  }, []);
  return children;
}
