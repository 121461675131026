import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Theme } from "@material-ui/core";

type MyDialogCheckboxesType = {
  needImpregnation?: boolean,
  checkpoints: CheckpointType[],
  selected: string[],
  onChange: (checkpoint: CheckpointType) => void
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  label: {
    flex: "1 1 100%",
    [theme.breakpoints.up("sm")]: {
      flex: "1 1 48%",
    },
  }
}));

export const MyDialogCheckboxes = ({ needImpregnation, checkpoints, selected, onChange }: MyDialogCheckboxesType) => {
  const classes = useStyles();

  const needImpregnationBox = checkpoints.find(ch => ["Impregnation is done", "Box with impregnation is added to fidtbag"].includes(ch.value));
  if (needImpregnationBox) {
    needImpregnationBox.disabled = !needImpregnation;
  }

  return (
    <div className={classes.root}>
      {checkpoints.map(checkpoint => (
        <FormControlLabel
          key={checkpoint.value}
          classes={{ root: classes.label }}
          control={
            <Checkbox
              checked={selected.includes(checkpoint.value)}
              onChange={() => onChange(checkpoint)}
              color="primary"
              disabled={checkpoint.disabled}
            />
          }
          label={checkpoint.text}
        />
      ))}
    </div>
  );
};

MyDialogCheckboxes.defaultProps = {
  onChange: () => ({})
};
