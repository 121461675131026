import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 0)
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2)
  }
}));

export const dataTableClasses = useStyles;
