import * as React from 'react';
import { DialogTitle, Theme, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

type MyDialogTitleType = {}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    [theme.breakpoints.down('xs')]: {
      padding: '8px 8px 6px'
    },
  }
}));

export const MyDialogTitle: React.FunctionComponent<MyDialogTitleType> = ({ children }) => {
  const styles = useStyles();
  const theme = useTheme<Theme>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <DialogTitle id="form-dialog-title" className={styles.title} disableTypography>
      <Typography variant={isMobile ? 'h6' : 'h5'}>
        {children}
      </Typography>
    </DialogTitle>
  );
};
