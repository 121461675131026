import * as FileTypes from './FileTypes';
import { FileActionTypes } from './FileTypes';

const initialState: FileStoreType = {
  entities: {},
  list: []
};


export function fileReducer(state = initialState, action: FileActionTypes): FileStoreType {
  switch (action.type) {
    case FileTypes.FETCH_FILES:
      return {
        ...state,
        entities: action.files.entities.file,
        list: action.files.result
      };

    default:
      return state;
  }
}
