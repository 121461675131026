import * as React from 'react';
import { ListItemText, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setLastNotificationId } from '../../redux/notifications/NotificationActions';

type NotificationMarkAllType = {
  onClickHandler: () => void
}

export const NotificationMarkAll: React.FunctionComponent<NotificationMarkAllType> = ({ onClickHandler }) => {
  const dispatch = useDispatch();
  const lastNotificationId = useSelector((state: GlobalStoreType) => state.notifications.list.reduce((p, v) => (p > v ? p : v), 0));
  const onClick = () => {
    onClickHandler();
    dispatch(setLastNotificationId(lastNotificationId));
  };

  return (
    <MenuItem divider onClick={onClick}>
      <ListItemText primary="Mark All as Read" style={{ textAlign: 'center' }} />
    </MenuItem>
  );
};
