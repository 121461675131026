import * as React from "react";
import {connect, useSelector} from "react-redux";

type withIsAdminType = {
  isAdmin: boolean,
  isProduction: boolean
};

const mapStateToProps = (state: GlobalStoreType) => ({
  isAdmin: state.account.isAdmin,
  isProduction: state.account.isProduction
});

export function withIsAdmin<T>(Component: any) {
  // @ts-ignore
  return connect<AccountStoreType>(mapStateToProps)(class extends React.Component<withIsAdminType & T> {
    render() {
      return (
        <Component {...this.props} isAdmin={this.props.isAdmin} isProduction={this.props.isProduction}/>
      )
    }
  });
}

export const useIsAdmin = () => useSelector((state: GlobalStoreType) => state.account.isAdmin);
export const useIsMonter = () => useSelector((state: GlobalStoreType) => state.account.isMonter);
export const useIsProduction = () => useSelector((state: GlobalStoreType) => state.account.isProduction);
