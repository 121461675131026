import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ErrorMessage } from '../../components/Common/ErrorMessage';
import { DataTableInformationCommentAdd } from '../../components/DataTableInformation/DataTableInformationCommentAdd';
import { DataTableInformationTableComments } from '../../components/DataTableInformation/DataTableInformationTableComments';
import { MyDialogUser } from '../../components/Dialog/MyDialogUser';

import { FileUploader } from '../../components/FileUploader';
import { FileUploadTable } from '../../components/FileUploadTable/FileUploadTable';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { useUserInput } from '../../hooks/useUserInput';

import { COMMENT_TYPE } from '../../shared/constants';

import { useFileUploader } from '../../hooks/useFileUploader';
import { FileDropzone } from '../../components/Common/FileDropzone';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 200,
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  left: {
    flex: 1,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(3)
    }
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2)
    }
  },
  table: {
    flex: 1,
    overflowY: 'auto'
  },
  button: {
    marginTop: theme.spacing(1),
    alignSelf: 'flex-end'
  },
  comment: {
    marginBottom: theme.spacing(1)
  }
}));

type PlateDeviationDialogType = {
  reference_number: string,
  order_id: number,
  id?: number,
  comments: CommentType[],
  files: FileType[]
}

export function PlateDeviation({ reference_number, comments, files, order_id, id }: PlateDeviationDialogType) {
  const classes = useStyles();
  const { onUserChange, user } = useUserInput();
  const { error, handleError, resetError, isUserError } = useErrorHandler();

  const { filesState, handleSetFilesAndSend, resetState } = useFileUploader({
    prependName: `DEVIATION-ORDER-${reference_number}`,
    type: 'DEVIATION',
    orderId: order_id,
    plateId: id,
    userId: user,
    isPlate: !!id
  });

  const onUpload = async (evt: HTMLInputEvent) => {
    try {
      await handleSetFilesAndSend(evt);
      resetState();
    } catch (err) {
      handleError(err);
    }
  };

  React.useEffect(() => {
    resetError();
  }, [user]);

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <DataTableInformationCommentAdd plateId={id} orderId={order_id} type={COMMENT_TYPE.DEVIATION} />
        <DataTableInformationTableComments
          comments={comments.filter(element => element.comment_type === COMMENT_TYPE.DEVIATION)}
        />
      </div>
      <div className={classes.right}>
        <MyDialogUser onChange={onUserChange} userError={isUserError} user={user} />
        <ErrorMessage message={error} isVisible={!!error} />

        <FileDropzone onUpload={onUpload}>
          <div className={classes.table}>
            <FileUploadTable
              files={[...files, ...filesState].filter((element: any) => element.file_type === COMMENT_TYPE.DEVIATION) as any}
            />
          </div>
          <div className={classes.button}>
            <FileUploader addFiles={onUpload} />
          </div>
        </FileDropzone>
      </div>
    </div>
  );
}
