import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import { useIsAdmin, useIsProduction, useIsMonter } from '../../containers/withIsAdmin';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { useUserInput } from '../../hooks/useUserInput';
import { ErrorMessage } from '../Common/ErrorMessage';

import { MyDialogUser } from '../Dialog/MyDialogUser';
import { FileUploader } from '../FileUploader';
import { FileUploadTable } from '../FileUploadTable/FileUploadTable';
import { useFileUploader } from '../../hooks/useFileUploader';
import { DataTableInformationDrawingOld } from './DataTableInformationDrawingOld';
import { FileDropzone } from '../Common/FileDropzone';
import { Theme } from '@material-ui/core';

type DataTableInformationDrawing = {
  files: FileType[],
  multiple: boolean,
  isPlate: boolean,
  label: string,
  type: string,
  orderId: number,
  plateId?: number,
  prependName: string,
  allowMonter?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    flex: 1,
    overflowY: 'auto',
    marginBottom: theme.spacing(2)
  },
  middle: {
    marginTop: theme.spacing(1)
  }
}));


export function DataTableInformationDrawing({ files, multiple, label, allowMonter, ...rest }: DataTableInformationDrawing & FileUploadSettings) {
  const classes = useStyles();

  const isAdmin = useIsAdmin();
  const isProduction = useIsProduction();
  const isMonter = useIsMonter();

  const { onUserChange, user } = useUserInput();
  const { error, handleError, isUserError, resetError } = useErrorHandler();
  const { handleSetFilesAndSend, filesState, resetState } = useFileUploader({ ...rest, userId: user });

  const onUpload = async (evt: HTMLInputEvent) => {
    try {
      await handleSetFilesAndSend(evt);
      resetState();
    } catch (err) {
      // @ts-ignore
      handleError(err);
    }
  };

  React.useEffect(() => {
    resetError();
  }, [user, filesState]);

  const showUpload = isAdmin || isProduction || (allowMonter && isMonter);

  const oldFiles = files.filter(file => file.is_old);
  return (
    <React.Fragment>
      {showUpload && (
        <React.Fragment>
          <MyDialogUser onChange={onUserChange} userError={isUserError} user={user} />
          <ErrorMessage message={error} isVisible={!!error} />
        </React.Fragment>
      )}
      <FileDropzone onUpload={onUpload}>
        {showUpload && (
          <div className={classes.middle}>
            <FileUploader addFiles={onUpload} multiple={multiple} label={label} />
          </div>
        )}
        <div className={classes.table}>
          <FileUploadTable
            isDrawing
            files={[...files.filter(file => !file.is_old), ...filesState] as (FileUploadServer & FileType)[]}
          />
        </div>
        {!!oldFiles.length && (
          <DataTableInformationDrawingOld files={oldFiles as (FileUploadServer & FileType)[]} />
        )}
      </FileDropzone>
    </React.Fragment>
  );
}

DataTableInformationDrawing.defaultProps = {
  multiple: true,
  label: 'Upload Files'
};

