import * as React from 'react';
import { TextField, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { useErrorHandler } from '../../hooks/useErrorHandler';

import {
  insertRegularComment,
  insertInfoComment,
  insertDeviationComment
} from '../../redux/actions/commentActions';
import { useUserInput } from '../../hooks/useUserInput';
import { COMMENT_TYPE } from '../../shared/constants';
import { Button } from '../Button';
import { ErrorMessage } from '../Common/ErrorMessage';
import { MyDialogUser } from '../Dialog/MyDialogUser';

type DataTableInformationCommentAddType = {
  commentId?: number,
  plateId?: number,
  orderId?: number,
  commentText: string,
  type?: string,
  filter?: string[],
  isDisabled?: boolean,
  canAdd?: boolean,
  insertRegularComment: (params: CommentAction & RegularCommentAction) => void,
  insertInfoComment: (params: CommentAction) => void,
  insertDeviationComment: (params: CommentAction) => void,
  callback?: () => void
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
  comment: {
    flex: 1,
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
  },
}));

function DataTableInformationCommentAdd({
                                          orderId, plateId, type, isDisabled, canAdd, commentText, filter,
                                          insertRegularComment, insertInfoComment, insertDeviationComment,
                                          callback
                                        }: DataTableInformationCommentAddType) {
  const classes = useStyles();
  const [comment, setComment] = React.useState(commentText);
  const { user, resetUser, onUserChange } = useUserInput();
  const { error, handleError, isUserError , resetError} = useErrorHandler();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value);

  async function submitOnHold() {
    try {
      switch (type) {
        case COMMENT_TYPE.INFO:
          await insertInfoComment({ orderId, plateId, comment_text: comment, user_id: user });
          break;

        case COMMENT_TYPE.DEVIATION:
          await insertDeviationComment({ orderId, plateId, comment_text: comment, user_id: user });
          break;

        case COMMENT_TYPE.REGULAR:
        default:
          await insertRegularComment({
            orderId,
            plateId,
            comment_text: comment,
            user_id: user,
            comment_for: filter as any
          });
          break;
      }
      setComment('');
      resetUser();

      if (callback) {
        callback();
      }
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  React.useEffect(() => {
    resetError();
  }, [comment, user])

  return (
    <div className={classes.root}>
      <TextField
        value={comment}
        onChange={onChange}
        multiline
        rows={2}
        className={classes.comment}
        label="Comment"
        disabled={isDisabled}
      />
      <div className={classes.row}>
        <MyDialogUser onChange={onUserChange} userError={isUserError} user={user} />
        <div className={classes.row}>
          <Button variant="contained" onClick={submitOnHold} disabled={canAdd || !user}>Submit</Button>
        </div>
      </div>
      <ErrorMessage message={error} isVisible={!!error} />
    </div>
  );
}

DataTableInformationCommentAdd.defaultProps = {
  commentText: ''
};

const connected = connect(null, {
  insertRegularComment,
  insertInfoComment,
  insertDeviationComment,
})(DataTableInformationCommentAdd);
export { connected as DataTableInformationCommentAdd };
