import * as React from 'react';
import { NOTIFICATION_TYPE } from '../../shared/constants';
import { NotificationFault } from './NotificationFault';

type NotificationElementType = {
  notification: NotificationType
}

export const NotificationElement: React.FunctionComponent<NotificationElementType> = ({ notification }) => {
  switch (notification.type) {
    case NOTIFICATION_TYPE.FAULT:
      return <NotificationFault notification={notification} />;

    default:
      return null;
  }
};
