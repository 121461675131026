import * as React from 'react';
import {
  Dialog,
  DialogActions,
  InputLabel,
  TextField, Theme,
  withMobileDialog
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { InjectedProps } from '@material-ui/core/withMobileDialog';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../../components/Button';
import { ErrorMessage } from '../../components/Common/ErrorMessage';
import { MyDialogTitle } from '../../components/Dialog/MyDialogTitle';
import { MyDialogContent } from '../../components/Dialog/MyDialogContent';
import { MyDialogUser } from '../../components/Dialog/MyDialogUser';

import { useErrorHandler } from '../../hooks/useErrorHandler';
import { useUserInput } from '../../hooks/useUserInput';
import { updateFaultPlate } from '../../redux/actions/plateActions';
import { FaultReason } from '../../components/Common/FaultReason';

type MetaDataTableRowFaultType = {
  onCancel: () => void,
  faultId: number,
  reason: string,
  commentId: number,
  commentText: string
};

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    minWidth: 300,
    [theme.breakpoints.up('sm')]: {
      minWidth: 500,
    },
  },
  row: {
    marginTop: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(3),
    flex: '1 1 100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      overflowY: 'auto',
    },
  }
}));


function MetaDataTableRowFault({ onCancel, fullScreen, faultId, reason, commentId, commentText }: MetaDataTableRowFaultType & InjectedProps) {
  const styles = useStyles();
  const dispatch = useDispatch();

  const dictionary = useSelector((state: GlobalStoreType) => state.dictionary.faultReasonDictionary);
  const [localReason, setLocalReason] = React.useState(reason);

  const [localComment, setLocalComment] = React.useState(commentText);
  const onChangeComment = (e: React.ChangeEvent<HTMLInputElement>) => setLocalComment(e.target.value);

  const { onUserChange, user } = useUserInput();
  const { error, handleError, isUserError, resetError } = useErrorHandler();

  async function onSubmit() {
    try {
      await dispatch(updateFaultPlate({ id: faultId, userId: user, reason: localReason, commentText: localComment, commentId }));
      onCancel();
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  React.useEffect(() => {
    resetError();
  }, [user]);

  return (
    <Dialog
      open
      maxWidth="sm"
      fullScreen={fullScreen}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: styles.dialog }}
    >
      <MyDialogTitle>Fault</MyDialogTitle>
      <MyDialogContent>
        <InputLabel htmlFor="select-helper">Reason</InputLabel>
        <FaultReason dictionary={dictionary} value={localReason} setValue={setLocalReason} />
        <div className={styles.row}>
          <MyDialogUser onChange={onUserChange} userError={isUserError} user={user} />
          <ErrorMessage message={error} isVisible={!!error} />
        </div>
        <div className={styles.row}>
          <TextField
            value={localComment}
            onChange={onChangeComment}
            multiline
            rows={2}
            label="Comment"
            fullWidth
          />
        </div>
      </MyDialogContent>
      <DialogActions>
        <Button variant="text" color="default" onClick={onCancel}>
          Close
        </Button>
        <Button variant="text" color="primary" onClick={onSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const connected = withMobileDialog<MetaDataTableRowFaultType>()(MetaDataTableRowFault);
export { connected as MetaDataTableRowFault };
