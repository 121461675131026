import axios from "axios";
import { getForEnv } from "../shared/getForEnv";
import { storeInstance } from "../index";

const { variables } = getForEnv(require("../shared/config/variables.dev"), require("../shared/config/variables"));

class APIService {
  constructor() {
    this.instance = axios.create({ baseURL: variables.url });
    this.instance.interceptors.request.use(function (config) {
      const state = storeInstance.store.getState();
      config.headers.Authorization = `Bearer ${state.account.token}`;
      return config;
    });
  }

  get(url) {
    return this.instance.get(url);
  }

  post(url, data) {
    return this.instance.post(url, data);
  }

  put(url, data, config = null) {
    return this.instance.put(url, data, config);
  }

  delete(url, data = null) {
    return this.instance.delete(url, data);
  }
}

export const APIClient = new APIService();
