import * as React from 'react';
import { TableCell, TableHead, TableRow, Theme } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

type DataTableInformationCommentHeader = {
  withAddedTo?: boolean,
  withStatus?: boolean,
  withDelete?: boolean,
  withIsSeparately?: boolean
}

export const DataTableInformationCommentHeader = ({ withStatus, withAddedTo, withDelete, withIsSeparately }: DataTableInformationCommentHeader) => {
  const theme = useTheme<Theme>();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <TableHead>
      <TableRow>
        {withStatus && (
          <TableCell style={{ width: '40px' }} padding="none" />
        )}
        <TableCell padding="checkbox" style={{ width: '40px' }}>Comment</TableCell>
        {withIsSeparately && (
          <TableCell align="right" style={{ width: '80px' }} padding="checkbox">Will be mounted separately</TableCell>
        )}
        <TableCell align="right" style={{ width: '80px' }} padding="checkbox">User</TableCell>
        {isDesktop && (
          <TableCell align="right" style={{ width: '100px' }} padding="checkbox">
            Date
          </TableCell>
        )}
        {withAddedTo && isDesktop && (
          <TableCell align="right" padding="checkbox" style={{ width: '200px' }}>For</TableCell>
        )}
        <TableCell align="right" padding="checkbox" style={{ width: '60px' }}>Plate</TableCell>
        {!withStatus && <TableCell align="right" padding="checkbox" style={{ width: '75px' }}>Move</TableCell>}
        {withDelete && isDesktop && (
          <TableCell align="right" style={{ width: '100px' }} />
        )}
      </TableRow>
    </TableHead>
  );
};
