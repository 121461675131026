import * as React from "react";
import { Button as MaterialButton } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { ButtonProps } from "@material-ui/core/Button";
import { throttled } from "../services/helpers";
import { orange, green, yellow } from "@material-ui/core/colors";
import { MouseEventHandler } from "react";

type MyButtonProps = {
  ariaLabel?: string,
  triggerClassName?: string,
  customColor?: string,
  children?: string
}

const Button = (params: MyButtonProps & ButtonProps) => {
  const [isDisabled, setDisabled] = React.useState(false);
  const onClickHandler = throttled(2000, async (evt: MouseEventHandler) => {
    setDisabled(true);
    try {
      // @ts-ignore
      await params.onClick(evt);
    } catch (err) {
    }

    setDisabled(false);
  });

  return (
    <MaterialButton
      {...params}
      onClick={onClickHandler}
      aria-label={params.ariaLabel}
      className={params.triggerClassName}
      disabled={params.disabled || isDisabled}
    >
      {params.children}
    </MaterialButton>
  );
};


const OrangeButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700],
    },
  },
}))(Button);

const YellowButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(yellow[500]),
    backgroundColor: yellow[500],
    '&:hover': {
      backgroundColor: yellow[700],
    },
  },
}))(Button);

const GreenBorderButton = withStyles(() => ({
  root: {
    borderWidth: "2px",
    borderColor: green[300],
    '&:hover': {
      borderWidth: "2px",
      borderColor: green[700],
    },
  },
}))(Button);

const _Button = ({ customColor, ...props }: MyButtonProps & ButtonProps) => {
  switch (customColor) {
    case "orange":
      return <OrangeButton {...props} />

    case "yellow":
      return <YellowButton {...props} />

    case "greenBorder":
      return <GreenBorderButton {...props} />

    default:
      return <Button {...props}/>
  }
};

export { _Button as Button }
