import * as React from "react";
import { Typography } from "@material-ui/core";
import { red, green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import classnames from "classnames";
import { PLATE_TYPE } from "../shared/constants";

type PlateBadgeType = {
  number: string,
  actionType: string
};

const useStyles = makeStyles(() => ({
  badge: {
    display: "block",
    borderRadius: 20,
    padding: 6,
    margin: 2
  },
  installation: {
    backgroundColor: green[500]
  },
  completed: {
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: green[500],
    color: green[500]
  },
  wrong: {
    backgroundColor: red[500]
  }
}));

export function PlateBadge({ number, actionType }: PlateBadgeType) {
  const classes = useStyles();
  const className = classnames({
    [classes.badge]: true,
    [classes.installation]: actionType === PLATE_TYPE.INSTALLATION,
    [classes.completed]: actionType === PLATE_TYPE.COMPLETED,
    [classes.wrong]: actionType !== PLATE_TYPE.INSTALLATION && actionType !== PLATE_TYPE.COMPLETED
  });

  return (
    <Typography variant="button" className={className}>{number}</Typography>
  );
}


