import { Dispatch } from 'redux';
import { normalize, NormalizedSchema, schema } from 'normalizr';

import * as PlateTypes from './PlateTypes';
import { PlateActionTypes } from './PlateTypes';

import { APIClient } from '../../services/apiService';

const plateSchema = new schema.Entity('plate');
const platesSchema = new schema.Array(plateSchema);

const faultSchemaById = new schema.Entity('fault');
const faultsSchemaById = new schema.Array(faultSchemaById);
const faultSchema = new schema.Entity('fault', {}, { idAttribute: 'fault_plate_id' });
const faultsSchema = new schema.Array(faultSchema);
const faultSchemaPlateId = new schema.Entity('fault', {}, { idAttribute: 'plate_id' });
const faultsSchemaPlateId = new schema.Array(faultSchemaPlateId);

const defaultMaterial = (element: Plate): Plate => ({ ...element, material: { type: '', thickness: '', name: '', need_impregnation: false } });
const fetchPlatesSuccess = (plates: NormalizedSchema<PlateEntity, number[]>): PlateActionTypes => ({
  type: PlateTypes.FETCH_PLATES,
  plates
});
export const fetchPlates = () => async (dispatch: Dispatch) => {
  const response = await APIClient.get(`plates`);
  const normalizedPlates = normalize<'plate', PlateEntity, number[]>(response.data.map(defaultMaterial), platesSchema);

  dispatch(fetchPlatesSuccess(normalizedPlates));
};

const fetchCompletedPlatesSuccess = (plates: NormalizedSchema<PlateEntity, number[]>): PlateActionTypes => ({
  type: PlateTypes.FETCH_COMPLETED_PLATES,
  plates
});
export const fetchCompletedPlates = () => async (dispatch: Dispatch) => {
  const response = await APIClient.get(`plates/completed`);
  const normalizedPlates = normalize<'plate', PlateEntity, number[]>(response.data.map(defaultMaterial), platesSchema);

  dispatch(fetchCompletedPlatesSuccess(normalizedPlates));
};

const fetchFaultsSuccess = (faults: NormalizedSchema<FaultEntity, number[]>, plateFaults: NormalizedSchema<FaultEntity, number[]>, faultsById: NormalizedSchema<FaultEntity, number[]>): PlateActionTypes => ({
  type: PlateTypes.FETCH_FAULTS,
  faults,
  plateFaults,
  faultsById
});
export const fetchFaults = () => async (dispatch: Dispatch) => {
  const response = await APIClient.get(`plates/fault`);
  const normalizedPlates = normalize<'fault', FaultEntity, number[]>(response.data, faultsSchema);
  const normalizedPlatesId = normalize<'fault', FaultEntity, number[]>(response.data, faultsSchemaPlateId);
  const normalizedFaultId = normalize<'fault', FaultEntity, number[]>(response.data, faultsSchemaById);

  dispatch(fetchFaultsSuccess(normalizedPlates, normalizedPlatesId, normalizedFaultId));
};

export const fetchPlateBySocket = (plate: Plate) => ({
  type: PlateTypes.FETCH_PLATE_BY_SOCKET,
  plate
});

export const fetchFaultBySocket = (fault: Fault) => ({
  type: PlateTypes.FETCH_FAULT_BY_SOCKET,
  fault
});
