import * as React from "react";
import { Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

type DataTableInformationTabsType = {
  value: TABS,
  setValue: (value: TABS) => void
};

export enum TABS {
  COMMENTS = "COMMENTS",
  HOLD = "HOLD",
  INFO = "INFO",
  PICTURES = "PICTURES",
  DRAWING = "DRAWING",
  DEVIATION = "DEVIATION",
  HISTORY = "HISTORY",
  ORDREBESKRIVELSE = "ORDREBESKRIVELSE",
  REPORT = "REPORT",
}

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 100
  }
}));

export function DataTableInformationTabs({ value, setValue }: DataTableInformationTabsType) {
  const classes = useStyles();

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: TABS) => setValue(newValue);

  return (
    <Tabs value={value} variant="scrollable" scrollButtons="on" onChange={handleChange}>
      {Object.keys(TABS).filter(key => key !== "INFO").map(tab => (
        <Tab
          key={tab}
          label={tab}
          value={tab}
          style={{ opacity: value === tab ? 1 : 0.3 }}
          classes={{ root: classes.root }}
        />
      ))}
    </Tabs>
  );
}
