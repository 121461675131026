import { Dispatch } from 'redux';

import * as MaterialTypes from './MaterialTypes';
import { MaterialActionTypes } from './MaterialTypes';

import { APIClient } from '../../services/apiService';

const fetchMaterialsSuccess = (materials: Material[]): MaterialActionTypes => ({
  type: MaterialTypes.FETCH_MATERIALS,
  materials
});

export const fetchMaterials = () => async (dispatch: Dispatch) => {
  const response = await APIClient.get(`materials`);
  dispatch(fetchMaterialsSuccess(response.data));
};
