import * as React from 'react';

import { Button } from './Button';

export function FileUploader({ addFiles, label, multiple }: FileUploaderType) {
  const inputRef: React.RefObject<HTMLInputElement> = React.useRef(null);
  const onClick = () => inputRef.current!.click();
  const onReset = (evt: any) => evt.target.value = null;

  return (
    <React.Fragment>
      <div>
        <Button variant="contained" onClick={onClick}>{label}</Button>
      </div>
      <input
        style={{ display: 'none' }} onChange={addFiles} onClick={onReset} type="file" multiple={multiple} ref={inputRef}
      />
    </React.Fragment>
  );
}

FileUploader.defaultProps = {
  label: 'Upload Files',
  multiple: true
};
