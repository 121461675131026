import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { DatePicker, TimePicker } from 'material-ui-pickers';
import { Checkbox, Theme, Typography } from '@material-ui/core';
import { ErrorMessage } from '../../components/Common/ErrorMessage';

import { MyDialog } from '../../components/Dialog/MyDialog';

import { MyDialogUser } from '../../components/Dialog/MyDialogUser';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { useUserInput } from '../../hooks/useUserInput';
import {
  confirmOrder,
  getContactList,
  sendConfirmationDataInfo
} from '../../redux/actions/ordersActions';
import { useIsAdmin, useIsMonter } from '../withIsAdmin';
import { formatToDate, formatToSaveDate, formatToTime } from '../../services/DateFormatter';

type MeasurementsConfirmDialogType = {
  orderId: number,
  referenceNumber: string,
  date: Date,
  estimateDate?: Date,
  buttonLabel?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    "& > div": {
      margin: theme.spacing(1)
    }
  }
}));

export function MeasurementsConfirmDialog({ orderId, referenceNumber, date, estimateDate, buttonLabel }: MeasurementsConfirmDialogType) {
  const isAdmin = useIsAdmin();
  const isMonter = useIsMonter();

  const { onUserChange, user, resetUser } = useUserInput();
  const { error, handleError, resetError, isUserError } = useErrorHandler();

  const [_date, changeDate] = React.useState(date);
  const [_time, changeTime] = React.useState(date);

  const classes = useStyles();
  const dispatch = useDispatch();

  const onChangeDate = async (newDate: Date) => changeDate(newDate || new Date);
  const onChangeTime = async (newTime: Date) => changeTime(newTime);

  React.useEffect(() => {
    resetError();
  }, [user]);

  const contactList = useSelector((state: GlobalStoreType) => {
    return state.confirmation.contactList
  });

  const [isOpen, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (isOpen) {
      dispatch(getContactList(referenceNumber));
    }
  }, [isOpen]);

  if (!(isAdmin || isMonter)) {
    return null;
  }

  function resetLocalState() {
    resetUser();
  }

  async function submitConfirmOrder() {
    try {
      const type = "measurements";

      const confirmDate = _date ? formatToSaveDate(_date) : null;
      const confirmTime = _time ? formatToTime(_time) : null;

      const dateTime = `${confirmDate} ${confirmTime}`;
      await confirmOrder(orderId, { user_id: user, date: dateTime });

      const phoneNumberList = contactList.filter(contact => contact.toSend).map(contact => contact.telefon);
      dispatch(sendConfirmationDataInfo(referenceNumber, {type, phoneNumberList, confirmDate, confirmTime}));

      resetLocalState();
    } catch (err) {
      // @ts-ignore
      handleError(err);
      throw err;
    }
  }

  const formattedDate = estimateDate ? formatToDate(estimateDate) : buttonLabel;

  return (
    <MyDialog
      buttonLabel={formattedDate}
      dialogTitle="Confirm"
      onEnter={() => setOpen(true)}
      onCancel={() => setOpen(false)}
      customColor={'yellow'}
      variant={'contained'}
      onSubmit={submitConfirmOrder}
      modalSize="sm"
    >
      <div className={classes.root}>
        {!contactList && <strong>LOADING...</strong>}
        {contactList?.length > 0 && (contactList.map((contact: Contact) =>
            <div>
              <Checkbox
                color="primary"
                onClick={() => contact.toSend = true}
              />{contact.rolle} - {contact.navn} - {contact.telefon}
            </div>
          ))}
        {contactList.length == 0 && <strong>NO CONTACTS</strong>}
        <div className={classes.row}>
          <Typography variant="subtitle2">Confirm date and time of measurement</Typography>
        </div>
        <div className={classes.row}>
          <DatePicker
            fullWidth
            animateYearScrolling
            variant="outlined"
            label="Date"
            value={_date}
            onChange={onChangeDate}
            disablePast
            format="dd-MM-yyyy"
            minDateMessage=""
            clearable
          />
          <TimePicker
            fullWidth
            variant="outlined"
            label="Time"
            value={_time}
            onChange={onChangeTime}
            format="HH:mm"
            ampm={false}
          />
        </div>
        <div className={classes.row}>
          <MyDialogUser onChange={onUserChange} userError={isUserError} user={user} />
        </div>
        <ErrorMessage message={error} isVisible={!!error} />
      </div>
    </MyDialog>
  );
}
