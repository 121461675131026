import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlateFullData } from "../../selectors/PlateSelectors";
import { CANCEL_TYPE, PLATE_TYPE } from '../../shared/constants';
import { MetaDataTable } from '../MetaDataTable/MetaDataTable';
import { selectDisplayWithInfoRow } from '../../redux/selectors';
import { fetchCompletedOrders } from "../../redux/orders/OrderActions";
import { fetchCompletedPlates } from "../../redux/plates/PlateActions";

export function CompletedOrdersTable() {
  const orders: Order[] = useSelector(selectDisplayWithInfoRow());
  const plates: Plate[] = useSelector(selectPlateFullData());
  const dispatch = useDispatch()

  const ordersWithInstallation = orders.map(order => ({
    ...order,
    plates: plates.filter(plate => plate.order_id === order.id)
  }))
    .filter(order => order.cancel_date || order.plates.some(plate => plate.current_stage === PLATE_TYPE.COMPLETED || plate.current_stage === CANCEL_TYPE));

    React.useEffect(() => {
      dispatch(fetchCompletedOrders());
      dispatch(fetchCompletedPlates());
  }, [])

  return (
    <MetaDataTable
      title="Completed"
      actionType="completed"
      rows={ordersWithInstallation}
      noMoveBackward
    />
  );
}

