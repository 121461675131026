import React from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";


import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { Button } from "../components/Button";
import { ErrorMessage } from "../components/Common/ErrorMessage";

import { login } from "../redux/actions/accountActions";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    flex: 1
  },
  container: {
    flex: 1,
    padding: theme.spacing(2),
    maxWidth: 500
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2)
  }
}));

export function Login({ history }: RouteComponentProps) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [hasError, setHasError] = React.useState(false);

  const onChangeUsername = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setHasError(false);
    setUsername(evt.target.value);
  };
  const onChangePassword = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setHasError(false);
    setPassword(evt.target.value);
  };

  const onSubmit = async () => {
    try {
      await dispatch(login({ username, password }));
      history.push("/");
    } catch (err) {
      setHasError(true);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.container} elevation={1}>
        <TextField
          label="Email"
          margin="normal"
          onChange={onChangeUsername}
          value={username}
          fullWidth
        />
        <TextField
          label="Password"
          margin="normal"
          onChange={onChangePassword}
          value={password}
          fullWidth
          type="password"
        />
        <ErrorMessage message="Email / Password is incorrect" isVisible={hasError}/>
        <div className={classes.buttons}>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            Log in
          </Button>
        </div>
      </Paper>
    </div>
  );
}
