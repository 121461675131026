import * as React from 'react'
import { WizardStepStageTextRow } from "./WizardStepStageTextRow";
import { makeStyles } from "@material-ui/styles";
import { Button, DialogActions, Theme } from "@material-ui/core";
import { TextValue } from "../../shared/WizardStep/WizardTypes";

type Props = {
  listValue: Array<TextValue>;
  setListValue: (listValue: Array<TextValue>) => void;
  isMultiple?: boolean;
  isMultiline?: boolean;
  additionalLineComponent: ((element?: number) => JSX.Element | null);
}

const useStyles = makeStyles((theme: Theme) => ({
  list: { width: '100%' },
  upload: { marginBottom: theme.spacing(2) }
}));

export const WizardStepStageText: React.FC<React.PropsWithChildren<Props>> = ({
                                                                                children,
                                                                                listValue,
                                                                                isMultiple,
                                                                                isMultiline,
                                                                                setListValue,
                                                                                additionalLineComponent
                                                                              }) => {
  const classes = useStyles();

  const onChangeTextValuesItem = (value: string, valueIndex: number) => {
    const newList = listValue.map((item, iterateIndex) => {
      if (iterateIndex === valueIndex) {
        return { ...item, value };
      } else {
        return item;
      }
    });

    setListValue(newList)
  }

  const onDeleteTextValuesItem = (index: number) => {
    const newList = listValue.filter((_, iterateIndex) => iterateIndex !== index);
    setListValue(newList);
  }

  const onAddTextValuesItem = () => setListValue([...listValue, { value: '' }]);

  const renderMultiple = () => {
    if (isMultiple) {
      return (
        <DialogActions>
          <Button variant="contained" onClick={onAddTextValuesItem}>Add item</Button>
          {children}
        </DialogActions>
      )
    }

    return null;
  }

  const renderSingle = () => {
    if (isMultiple) {
      return null;
    }

    return (
      <DialogActions>
        {children}
      </DialogActions>
    )
  }

  const renderAdditionalLineComponent = (index: number) => {
    if (!additionalLineComponent) {
      return null;
    }

    return (
      <div className={classes.upload}>
        {additionalLineComponent(index)}
      </div>
    )
  }

  return (
    <div className={isMultiple ? classes.list : ""}>
      {listValue.map((value, index) => (
        <React.Fragment key={index}>
          <WizardStepStageTextRow
            index={index} value={value.value} isMultiple={isMultiple}
            isMultiline={isMultiline} onChange={onChangeTextValuesItem}
            onDelete={onDeleteTextValuesItem}
          >
            {renderSingle()}
          </WizardStepStageTextRow>
          {renderAdditionalLineComponent(index)}
        </React.Fragment>
      ))}
      {renderMultiple()}
    </div>
  )
}
