import * as NotificationTypes from './NotificationTypes';
import { NotificationActionTypes } from './NotificationTypes';

const initialState: NotificationStoreType = {
  entities: {},
  list: [],
  lastNotificationId: 0,
  lastNotificationDate: null,
};


export function notificationReducer(state = initialState, action: NotificationActionTypes): NotificationStoreType {
  switch (action.type) {
    case NotificationTypes.FETCH_NOTIFICATIONS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.notifications.entities.notification,
        },
        list: action.notifications.result,
        lastNotificationId: action.lastNotificationId,
        lastNotificationDate: action.lastNotificationDate,
      };

    case NotificationTypes.SET_LAST_NOTIFICATION:
      return {
        ...state,
        lastNotificationId: action.lastNotificationId,
        lastNotificationDate: action.lastNotificationDate
      };

    case NotificationTypes.FETCH_NOTIFICATION_BY_SOCKET:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.notification.id]: action.notification
        },
        list: [
          ...state.list,
          action.notification.id
        ]
      };

    default:
      return state;
  }
}
