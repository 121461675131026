import { Dispatch } from 'redux';
import { normalize, NormalizedSchema, schema } from 'normalizr';

import * as CommentTypes from './CommentTypes';
import { CommentActionTypes } from './CommentTypes';

import { APIClient } from '../../services/apiService';

const fetchCommentsSuccess = (comments: NormalizedSchema<CommentEntity, number[]>): CommentActionTypes => ({
  type: CommentTypes.FETCH_COMMENTS,
  comments
});
export const fetchComments = () => async (dispatch: Dispatch) => {
  const response = await APIClient.get(`comments`);
  const commentSchema = new schema.Entity('comment');
  const commentsSchema = new schema.Array(commentSchema);
  const normalizedData = normalize<'comment', CommentEntity, number[]>(response.data, commentsSchema);

  dispatch(fetchCommentsSuccess(normalizedData));
};

const fetchCommentsByPlate = (id: number, comments: CommentType[]): CommentActionTypes => ({
  type: CommentTypes.FETCH_COMMENTS_BY_PLATE,
  id,
  comments
});
const fetchCommentsByOrder = (id: number, comments: CommentType[]): CommentActionTypes => ({
  type: CommentTypes.FETCH_COMMENTS_BY_ORDER,
  id,
  comments
});
export const fetchCommentsForRecord = (orderId: number, plateId?: number) => async (dispatch: Dispatch) => {
  if (plateId) {
    const response = await APIClient.get(`comments/order/${orderId}/plate/${plateId}`);
    dispatch(fetchCommentsByPlate(plateId, response.data));
  } else {
    const response = await APIClient.get(`comments/order/${orderId}`);
    dispatch(fetchCommentsByOrder(orderId, response.data));
  }
};

export const fetchCommentById = (comment: CommentType): CommentActionTypes => ({
  type: CommentTypes.FETCH_COMMENT_BY_ID,
  comment,
  order_id: comment.order_id,
  plate_id: comment.plate_id
});

export const fetchImportantCommentById = (comment: CommentType): CommentActionTypes => ({
  type: CommentTypes.FETCH_COMMENT_BY_ID,
  comment,
  order_id: comment.order_id,
  plate_id: comment.plate_id
});

export const deleteCommentBySocket = (comment: CommentType): CommentActionTypes => ({
  type: CommentTypes.DELETE_COMMENT_BY_SOCKET,
  id: comment.id,
  order_id: comment.order_id,
  plate_id: comment.plate_id
});

export const deleteComment = (comment: CommentType) => async () => {
  await APIClient.post(`comments/delete`, { comment });
};

export const fetchDeviations = () => async (dispatch: Dispatch) => {
  const response = await APIClient.get(`/comments/deviation`);
  const commentSchema = new schema.Entity('comment');
  const commentsSchema = new schema.Array(commentSchema);
  const normalizedData = normalize<'comment', CommentEntity, number[]>(response.data, commentsSchema);

  dispatch(fetchDeviationList(normalizedData));
};

const fetchDeviationList = (comments: NormalizedSchema<CommentEntity, number[]>): CommentActionTypes => ({
  type: CommentTypes.FETCH_DEVIATIONS,
  comments
});

const fetchFaultCommentsSuccess = (comments: NormalizedSchema<CommentEntity, number[]>): CommentActionTypes => ({
  type: CommentTypes.FETCH_FAULTS_COMMENT,
  comments
});

export const fetchFaultComments = () => async (dispatch: Dispatch) => {
  const response = await APIClient.get(`/comments/fault`);
  const commentSchema = new schema.Entity('comment');
  const commentsSchema = new schema.Array(commentSchema);
  const normalizedData = normalize<'comment', CommentEntity, number[]>(response.data, commentsSchema);

  dispatch(fetchFaultCommentsSuccess(normalizedData));
};
