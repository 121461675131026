import { InjectedProps } from '@material-ui/core/withMobileDialog';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField, Theme,
  Typography,
  withMobileDialog
} from '@material-ui/core';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { useUserInput } from '../../hooks/useUserInput';
import { insertOnHoldOffComment } from '../../redux/actions/commentActions';

import { Button } from '../Button';
import { ErrorMessage } from '../Common/ErrorMessage';
import { MyDialog } from '../Dialog/MyDialog';
import { useIsAdmin, useIsProduction } from '../../containers/withIsAdmin';
import { MyDialogUser } from '../Dialog/MyDialogUser';
import { PreventPropagation } from '../PreventPropagation';

type DataTableInformationCommentDeleteType = {
  orderId?: number,
  plateId?: number,
  comment: CommentType
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  leftButtons: {
    display: 'flex',
    flex: 1
  },
  comment: {
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
  },
}));

function DataTableInformationCommentHoldOff({ comment: { comment_status, comment_text, id }, plateId, orderId }: DataTableInformationCommentDeleteType & InjectedProps) {
  const isAdmin = useIsAdmin();
  const isProduction = useIsProduction();
  const dispatch = useDispatch();

  const classes = useStyles();
  const [comment, setComment] = React.useState('');
  const [isOpen, setOpen] = React.useState(false);
  const { onUserChange, user, resetUser } = useUserInput();
  const { error, handleError, isUserError, resetError } = useErrorHandler();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value);

  async function onSubmit() {
    try {
      await dispatch(insertOnHoldOffComment({
        orderId,
        plateId,
        comment_text: comment,
        user_id: user,
        comment_id: id
      }));
      clear();
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  async function onSubmitAll() {
    try {
      await dispatch(insertOnHoldOffComment({ orderId, comment_text: comment, user_id: user, comment_id: id }));
      clear();
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  function clear() {
    setComment('');
    resetUser();
    setOpen(false);
  }

  React.useEffect(() => {
    resetError();
  }, [user, comment]);

  if (!isAdmin && !isProduction) {
    return null;
  }

  return (
    <MyDialog
      checkbox
      checked={comment_status}
      dialogTitle="Hold Off"
      size="medium"
      color="secondary"
      modalSize="sm"
      onSubmitEnabled={false}
    >
      {({ onClose }: any) => (
        <React.Fragment>
          <div className={classes.root}>
            <Typography variant="subtitle2">
              Are you sure that you want to turn off On Hold
              <Typography variant="subtitle2" style={{ fontWeight: 700 }}>{comment_text}?</Typography>
            </Typography>

            <div className={classes.root}>
              <TextField
                value={comment}
                onChange={onChange}
                multiline
                rows={2}
                className={classes.comment}
                label="Comment"
              />
              <div className={classes.row}>
                <MyDialogUser onChange={onUserChange} userError={isUserError} user={user} />
                <div className={classes.row}>
                  <Button variant="contained" disabled={!user} onClick={async () => {
                    if (plateId) {
                      setOpen(true);
                    } else {
                      await onSubmit();
                      onClose();
                    }
                  }}>
                    Submit
                  </Button>
                </div>
              </div>
              <ErrorMessage message={error} isVisible={!!error} />
            </div>
          </div>

          <PreventPropagation>
            <Dialog
              maxWidth="sm"
              open={isOpen}
              onClose={() => setOpen(false)}
              disableBackdropClick
              disableEscapeKeyDown
              fullWidth
            >
              <DialogTitle id="form-dialog-title">Off Hold All?</DialogTitle>
              <DialogContent>
                <div>
                  Do you want to put Hold Off all plates within that order?
                </div>
              </DialogContent>
              <DialogActions>
                <div className={classes.leftButtons}>
                  <Button color="default" onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                </div>
                <Button variant="contained" color="secondary" onClick={async () => {
                  await onSubmitAll();
                  onClose();
                }}>
                  Hold Off all plates
                </Button>

                <Button variant="contained" color="primary" onClick={async () => {
                  await onSubmit();
                  onClose();
                }}>
                  Hold Off only this
                </Button>
              </DialogActions>
            </Dialog>
          </PreventPropagation>
        </React.Fragment>
      )}
    </MyDialog>
  );
}

const connected = withMobileDialog<DataTableInformationCommentDeleteType>()(DataTableInformationCommentHoldOff);
export { connected as DataTableInformationCommentHoldOff };
