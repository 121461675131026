import { NormalizedSchema } from 'normalizr';
import {
  FetchCommentByIdAction,
  FetchCommentsByOrderAction,
  DeleteCommentBySocketAction
} from '../comments/CommentTypes';
import { DeleteFileBySocket, FetchFileBySocket, FetchFilesByOrder } from '../files/FileTypes';
import { FetchHistoryOrderAction } from '../history/HistoryTypes';
import { FetchNewMaterialsAction } from '../materials/MaterialTypes';

export const FETCH_NEW_ORDERS = 'FETCH_NEW_ORDERS';
export const FETCH_NEW_ORDERS_INFO = 'FETCH_NEW_ORDERS_INFO';
export const FETCH_EXISTING_ORDERS = 'FETCH_EXISTING_ORDERS';
export const FETCH_ORDER_BY_SOCKET = 'FETCH_ORDER_BY_SOCKET';
export const FETCH_ORDER_INFO_BY_SOCKET = 'FETCH_ORDER_INFO_BY_SOCKET';
export const DELETE_ORDER_BY_SOCKET = 'DELETE_ORDER_BY_SOCKET';
export const LOG_HISTORY_UPDATE = 'LOG_HISTORY_UPDATE';

export type FetchNewOrdersAction = {
  readonly type: typeof FETCH_NEW_ORDERS,
  orders: NormalizedSchema<OrderEntity, number[]>
}
export type FetchExistingOrdersAction = {
  readonly type: typeof FETCH_EXISTING_ORDERS,
  orders: NormalizedSchema<OrderEntity, number[]>
}
export type FetchNewOrdersInfoAction = {
  readonly type: typeof FETCH_NEW_ORDERS_INFO,
  orders: NormalizedSchema<OrderEntity, number[]>
}
export type FetchOrderBySocketAction = { readonly type: typeof FETCH_ORDER_BY_SOCKET, order: Order }
export type FetchOrderInfoBySocketAction = { readonly type: typeof FETCH_ORDER_INFO_BY_SOCKET, order: OrderInfo }
export type DeleteOrderBySocketAction = { readonly type: typeof DELETE_ORDER_BY_SOCKET, id: number }
export type LogHistoryUpdateAction = {
  readonly type: typeof LOG_HISTORY_UPDATE,
  payload: { id: number; key: string; value: string; isOrderInfoEntity: boolean; }
}

export type OrderActionTypes =
  FetchNewOrdersAction
  | FetchExistingOrdersAction
  | FetchNewOrdersInfoAction
  | FetchOrderBySocketAction
  | FetchOrderInfoBySocketAction
  | DeleteOrderBySocketAction
  | FetchCommentsByOrderAction
  | FetchCommentByIdAction
  | DeleteCommentBySocketAction
  | FetchFilesByOrder
  | FetchFileBySocket
  | DeleteFileBySocket
  | FetchHistoryOrderAction
  | FetchNewMaterialsAction
  | LogHistoryUpdateAction
