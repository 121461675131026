import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { Button } from '../Button';
import { FileUploadTable } from '../FileUploadTable/FileUploadTable';
import { PreventPropagation } from '../PreventPropagation';
import { fetchFilesForRecord } from '../../redux/files/FileActions';
import { useDispatch, useSelector } from 'react-redux';

type DataTableDeviationImagesType = {
  plate: Order & Plate,
};

export function DataTableDeviationImages({ plate }: DataTableDeviationImagesType) {
  const dispatch = useDispatch();
  const files = useSelector((state: GlobalStoreType) => state.orders.entities[plate.order_id]?.files) || [];
  const [isOpen, setOpen] = React.useState(false);

  const onOpen = async () => {
    setOpen(true);
    dispatch(fetchFilesForRecord(plate.order_id, plate.id));
  };

  return (
    <React.Fragment>
      <Button onClick={onOpen} color="default" variant="outlined">
        Images
      </Button>
      <PreventPropagation>
        <Dialog
          maxWidth="sm"
          open={isOpen}
          onClose={() => setOpen(false)}
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
        >
          <DialogTitle id="form-dialog-title">Deviation Files</DialogTitle>
          <DialogContent>
            <FileUploadTable
              isDrawing
              files={files.filter(file => file.key.includes('DEVIATION'))}
              withActions={false}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </PreventPropagation>
    </React.Fragment>
  );
}

