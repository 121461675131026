import * as PlateTypes from './PlateTypes';
import * as CommentTypes from '../comments/CommentTypes';
import * as FileTypes from '../files/FileTypes';
import * as HistoryTypes from '../history/HistoryTypes';
import * as MaterialTypes from '../materials/MaterialTypes';
import * as PlateTypeTypes from '../plateTypes/PlateTypeTypes';

const initialState: PlateStoreType = {
  entities: {},
  list: [],

  faultEntitiesById: {},
  faultEntities: {},
  faultListById: [],
  faultList: []
};


export function plateReducer(state = initialState, action: PlateTypes.PlateActionTypes): PlateStoreType {
  switch (action.type) {
    case PlateTypes.FETCH_PLATES:
      return {
        ...state,
        entities: { ...state.entities, ...action.plates.entities.plate },
        list: [...new Set([...state.list, ...action.plates.result])]
      };

    case PlateTypes.FETCH_COMPLETED_PLATES:
        return {
          ...state,
          entities: { ...state.entities, ...action.plates.entities.plate },
          list: [...new Set([...state.list, ...action.plates.result])]
        };

    case PlateTypes.FETCH_FAULTS:
      return {
        ...state,

        faultEntitiesById: action.faultsById.entities.fault,
        faultEntities: action.faults.entities.fault,
        faultListById: action.faultsById.result,
        faultList: action.faults.result,

        entities: {
          ...state.entities,
          ...action.plateFaults.result.map(key => ({
            [key]: {
              ...state.entities[key],
              // @ts-ignore
              fault_plate_id: action.plateFaults.entities?.fault?.[key]?.fault_plate_id
            }
          })).reduce((acc, value) => ({ ...acc, ...value }), {})
        }
      };

    case PlateTypes.FETCH_FAULT_BY_SOCKET:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.fault.plate_id]: {
            ...state.entities[action.fault.plate_id],
            fault_plate_id: action.fault.fault_plate_id
          }
        },
        faultEntities: {
          ...state.faultEntities,
          [action.fault.fault_plate_id]: action.fault,
        },
        faultEntitiesById: {
          ...state.faultEntitiesById,
          [action.fault.id]: action.fault,
        },

        faultListById: [...new Set([action.fault.id, ...state.faultListById])],
        faultList: [...new Set([action.fault.fault_plate_id, ...state.faultList])],
      };

    case CommentTypes.FETCH_COMMENTS_BY_PLATE:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.id]: {
            ...state.entities[action.id],
            comments: action.comments
          }
        }
      };

    case CommentTypes.FETCH_COMMENT_BY_ID:
      return action.plate_id ? {
        ...state,
        entities: {
          ...state.entities,
          [action.plate_id]: {
            ...state.entities[action.plate_id],
            comments: state.entities[action.plate_id].comments ? [action.comment, ...state.entities[action.plate_id].comments.filter(e => e.id !== action.comment.id)] : [action.comment]
          }
        }
      } : state;


    case CommentTypes.DELETE_COMMENT_BY_SOCKET:
      return action.plate_id ? {
        ...state,
        entities: {
          ...state.entities,
          [action.plate_id]: {
            ...state.entities[action.plate_id],
            comments: state.entities[action.plate_id].comments ? [...state.entities[action.plate_id].comments.filter(e => e.id !== action.id)] : []
          }
        }
      } : state;

    case FileTypes.FETCH_FILES_BY_PLATE:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.id]: {
            ...state.entities[action.id],
            files: action.files
          }
        }
      };

    case FileTypes.FETCH_FILE_BY_SOCKET:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.plate_id ? {
            [action.plate_id]: {
              ...state.entities[action.plate_id],
              files: [...state.entities[action.plate_id].files.filter(e => e.id !== action.file.id), action.file]
            }
          } : {}
        }
      };

    case FileTypes.DELETE_FILE_BY_SOCKET:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.plate_id ? {
            [action.plate_id]: {
              ...state.entities[action.plate_id],
              files: [...state.entities[action.plate_id].files.filter(e => e.id !== action.file.id)]
            }
          } : {}
        }
      };

    case HistoryTypes.FETCH_HISTORY_BY_PLATE:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.id]: {
            ...state.entities[action.id],
            actions: action.history
          }
        }
      };

    case MaterialTypes.FETCH_MATERIALS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...state.list.map(key => ({
            [key]: {
              ...state.entities[key],
              material: action.materials.find(element => element.order_id === state.entities[key].order_id) || {
                name: '',
                thickness: '',
                type: '',
                need_impregnation: false
              }
            }
          })).reduce((acc, value) => ({ ...acc, ...value }), {})
        }
      };

    case PlateTypes.FETCH_PLATE_BY_SOCKET:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.plate.id]: {
            ...state.entities[action.plate.id],
            ...action.plate
          }
        },
        list: [...new Set([...state.list, action.plate.id])]
      };

    case PlateTypeTypes.FETCH_PLATE_TYPES:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...state.list.map(key => ({
            [key]: {
              ...state.entities[key],
              types: action.plateTypes.filter(element => element.plate_id === key)
            }
          })).reduce((acc, value) => ({ ...acc, ...value }), {})
        }
      };

    case PlateTypeTypes.FETCH_PLATE_TYPES_BY_SOCKET:
      return {
        ...state,
        entities: {
          ...state.entities,

        }
      };

    default:
      return state;
  }
}
