import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle, Theme, Tooltip, Typography } from '@material-ui/core';
import Info from '@material-ui/icons/Info';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../Button';
import { FileUploadTable } from '../FileUploadTable/FileUploadTable';
import { PreventPropagation } from '../PreventPropagation';
import { fetchFilesForRecord } from '../../redux/files/FileActions';

type DataTableFaultyOrderType = {
  fault_plate_id: number,
  fault: string
};

const useStyles = makeStyles((theme: Theme) => ({
  fault: {
    marginRight: theme.spacing(1)
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    marginRight: theme.spacing(1)
  }
}));

export function DataTableFaultyOrder({ fault_plate_id }: DataTableFaultyOrderType) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isOpen, setOpen] = React.useState(false);

  const faultInfo = useSelector((state: GlobalStoreType) => state.plates.faultEntities?.[fault_plate_id]);
  const plate = useSelector((state: GlobalStoreType) => state.plates.entities[fault_plate_id]);
  const user = useSelector((state: GlobalStoreType) => state.configuration.users[faultInfo?.user_id])?.name;
  const comment = useSelector((state: GlobalStoreType) => state.comments.list
    .map(e => state.comments.entities[e]))
    .find(e => e.plate_id === faultInfo.fault_plate_id)?.comment_text;

  if (!fault_plate_id) {
    return null;
  }

  if (!faultInfo) {
    return null;
  }

  const onOpen = async () => {
    setOpen(true);
    dispatch(fetchFilesForRecord(plate.order_id, plate.id));
  };

  return (
    <React.Fragment>
      <Tooltip title={`Faulty (${fault_plate_id})`} onClick={onOpen}>
        <Info color="primary" classes={{ root: classes.fault }} />
      </Tooltip>
      <PreventPropagation>
        <Dialog
          maxWidth="sm"
          open={isOpen}
          onClose={() => setOpen(false)}
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
        >
          <DialogTitle id="form-dialog-title">Fault Information</DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom className={classes.wrapper}>
              <Typography variant="body2" className={classes.label}>Fault</Typography> {comment}
            </Typography>
            <Typography variant="body1" gutterBottom className={classes.wrapper}>
              <Typography variant="body2" className={classes.label}>Reason</Typography> {faultInfo.reason_text}
            </Typography>
            <Typography variant="body1" gutterBottom className={classes.wrapper}>
              <Typography variant="body2" className={classes.label}>User</Typography> {user}
            </Typography>
            <FileUploadTable
              isDrawing
              files={plate.files?.filter(file => file.key.includes('FAULT')) || []}
              withActions={false}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </PreventPropagation>
    </React.Fragment>
  );
}

