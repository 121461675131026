import * as React from "react";
import { Checkbox, FormControlLabel, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { PLATE_TYPE } from "../../shared/constants";

type InstallationPlateCheckboxType = {
  plates: Plate[],
  onChangeCheckbox: (value: number) => void,
  isChecked: any
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2)
  },
  checkboxes: {
    display: "flex"
  },
  checkbox: {
    marginRight: theme.spacing(4)
  }
}));

export function InstallationPlateCheckbox({ plates, onChangeCheckbox, isChecked }: InstallationPlateCheckboxType) {
  const classes = useStyles();

  const onChange = (value: number) => onChangeCheckbox(value);

  return (
    <div className={classes.root}>
      <div className={classes.checkboxes}>
        {plates.map(plate => (
          <FormControlLabel
            classes={{ root: classes.checkbox }}
            control={
              <Checkbox value="isOld" />
            }
            checked={isChecked[plate.id] || plate.current_stage === PLATE_TYPE.COMPLETED}
            onChange={() => onChange(plate.id)}
            label={plate.reference_number}
            disabled={plate.current_stage !== PLATE_TYPE.INSTALLATION}
          />
        ))}
      </div>
    </div>
  );
}
