import * as React from "react";
import { useSelector } from "react-redux";

import { selectCheckpoints } from "../redux/selectors";
import { selectPlateFullData } from '../selectors/PlateSelectors';
import { PLATE_TYPE } from "../shared/constants";
import { MetaDataTable } from "./MetaDataTable/MetaDataTable";

type PlateMetaTableType = {
  title: string,
  dictionary: keyof DictionaryStoreType,
  filterStage: keyof typeof PLATE_TYPE
};

export function PlateMetaTable({ title, filterStage, dictionary }: PlateMetaTableType) {
  const plates: Plate[] = useSelector(selectPlateFullData(filterStage));
  const checkpoints: CheckpointType[] = useSelector(selectCheckpoints(dictionary));

  return (
    <MetaDataTable
      title={title}
      actionType="plate"
      rows={plates}
      checkpoints={checkpoints}
    />
  );
}
