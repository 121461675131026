import * as MaterialTypes from './MaterialTypes';
import { MaterialActionTypes } from './MaterialTypes';

const initialState: MaterialStoreType = {
  entities: {},
  list: []
};


export function materialReducer(state = initialState, action: MaterialActionTypes): MaterialStoreType {
  switch (action.type) {
    case MaterialTypes.FETCH_MATERIALS:
      return {
        ...state,
      };

    default:
      return state;
  }
}
