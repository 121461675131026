import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import {
  InputLabel,
  FormControl,
  TextField,
  Theme,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { useErrorHandler } from '../../hooks/useErrorHandler';

import { useFileUploader } from '../../hooks/useFileUploader';
import { useUserInput } from '../../hooks/useUserInput';
import { faultPlate } from '../../redux/actions/plateActions';
import { ErrorMessage } from '../Common/ErrorMessage';
import { MyDialog } from '../Dialog/MyDialog';
import { MyDialogUser } from '../Dialog/MyDialogUser';
import { FileUploader } from '../FileUploader';
import { FileUploadTable } from '../FileUploadTable/FileUploadTable';
import { useIsProduction } from '../../containers/withIsAdmin';
import { FileDropzone } from '../Common/FileDropzone';
import { FaultReason } from '../../components/Common/FaultReason';

type FaultPlateParams = {
  id: number,
  userId: number
  reason: string,
  comment: string,
  moveToFind: boolean
}

type DataTableInformationFaultType = {
  files: FileType[],
  dictionary: DictionaryType[],
  reference_number: string,
  order_id: number,
  plate_id: number,
  faultPlate: ({ id, userId, reason, comment, moveToFind }: FaultPlateParams) => void,
  custom?: JSX.Element
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  row: {
    marginTop: theme.spacing(2)
  }
}));

function DataTableInformationFault({ files = [], dictionary, order_id, plate_id, reference_number, faultPlate, custom }: DataTableInformationFaultType) {
  const isProduction = useIsProduction();

  const classes = useStyles();
  const [reason, setReason] = React.useState('');
  const [comment, setComment] = React.useState('');
  const [moveToFind, setMoveToFind] = React.useState(true);
  const { onUserChange, user } = useUserInput();
  const { error, handleError, isUserError, resetError } = useErrorHandler();

  const { filesState, handleSetFilesAndSend, resetState } = useFileUploader({
    prependName: `FAULT-ORDER-${reference_number}`,
    type: 'PICTURE',
    orderId: order_id,
    plateId: plate_id,
    userId: user,
    isPlate: true
  });

  const handleMoveToFindChange = () => setMoveToFind(!moveToFind);

  React.useEffect(() => {
    resetError();
  }, [user]);

  if (isProduction) {
    return null;
  }

  async function onSubmit() {
    try {
      await faultPlate({ id: plate_id, userId: user, reason, comment, moveToFind });
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  const onUpload = async (evt: HTMLInputEvent) => {
    try {
      await handleSetFilesAndSend(evt);
      resetState();
    } catch (err) {
      handleError(err);
    }
  };

  const onChangeComment = (e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value);

  return (
    <MyDialog
      dialogTitle="Fault"
      size="medium"
      color="secondary"
      buttonLabel="Fault"
      modalSize="sm"
      onSubmit={onSubmit}
      custom={custom}
    >
      <div className={classes.root}>
        <FormControl>
          <InputLabel htmlFor="select-helper">Reason</InputLabel>
          <FaultReason dictionary={dictionary} value={reason} setValue={setReason} />
        </FormControl>
        <div className={classes.row}>
          <MyDialogUser onChange={onUserChange} userError={isUserError} user={user} />
        </div>
        <div className={classes.row}>
          <FormControlLabel
            control={
              <Checkbox color="primary" />
            }
            checked={moveToFind}
            onChange={handleMoveToFindChange}
            label="Move to Find"
          />

        </div>
        <ErrorMessage message={error} isVisible={!!error} />
        <FileDropzone onUpload={onUpload}>
          <div className={classes.row}>
            <TextField
              value={comment}
              onChange={onChangeComment}
              multiline
              rows={2}
              label="Comment"
              fullWidth
            />
          </div>
          <div className={classes.row}>
            <FileUploader addFiles={onUpload} />
          </div>
          <div className={classes.row}>
            <FileUploadTable
              files={[...files.filter(file => file.key.includes('FAULT')), ...filesState] as (FileUploadServer & FileType)[]} />
          </div>
        </FileDropzone>
      </div>
    </MyDialog>
  );
}

const mapStateToProps = (state: GlobalStoreType) => ({ dictionary: state.dictionary.faultReasonDictionary });
const connected = connect(mapStateToProps, { faultPlate })(DataTableInformationFault);
export { connected as DataTableInformationFault };
