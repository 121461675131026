import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectPlateEntities } from '../../selectors/PlateSelectors';

import { PLATE_TYPE } from '../../shared/constants';
import { MetaDataTable } from '../MetaDataTable/MetaDataTable';
import { selectDisplayNewOrderRow } from '../../redux/selectors';

export function InstallationOrders() {
  const orders: Order[] = useSelector(selectDisplayNewOrderRow());
  const plates: Plate[] = useSelector(selectPlateEntities);

  const ordersWithInstallation = orders
    .map(order => ({
      ...order,
      plates: plates.filter(plate => plate.order_id === order.id)
    }))
    .filter(order => order.plates.some(plate => plate.current_stage === PLATE_TYPE.INSTALLATION));

  return (
    <MetaDataTable
      title="Installation"
      actionType="installation"
      rows={ordersWithInstallation}
      checkpoints={[]}
      addExtraInfo
    />
  );
}
