import { Component } from 'react';
import { connect } from 'react-redux';
import { getDictionaries } from "./redux/actions/dictionaryActions";

export class DictionaryProvider extends Component {
  componentDidMount() {
    this.props.getDictionaries();
  }

  render() {
    return this.props.children
  }
}

const mapDispatchToProps = { getDictionaries };
DictionaryProvider = connect(null, mapDispatchToProps)(DictionaryProvider);
