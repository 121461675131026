import * as React from 'react';
import { useSelector } from 'react-redux';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import FaultIcon from '@material-ui/icons/ErrorOutline';
import { makeStyles } from '@material-ui/styles';
import { deepOrange } from '@material-ui/core/colors';

import { formatToDateWithTime } from '../../services/DateFormatter';

type NotificationFaultType = {
  notification: NotificationType
}

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  avatar: {
    backgroundColor: deepOrange[500],
  },
  avatarRead: {
    backgroundColor: deepOrange[100],
  }
}));

export const NotificationFault: React.FunctionComponent<NotificationFaultType> = ({ notification }) => {
  const fault = useSelector((state: GlobalStoreType) => state.plates.faultEntitiesById[notification.fault_id!]);
  const user = useSelector((state: GlobalStoreType) => state.configuration.users[fault?.user_id]);
  const plate = useSelector((state: GlobalStoreType) => state.plates.entities[fault?.fault_plate_id]);
  const order = useSelector((state: GlobalStoreType) => state.orders.entities[plate?.order_id]);

  const styles = useStyles();
  if (!fault || !user || !plate || !order) {
    return null;
  }

  const isReadStyle = notification.isRead ? 'caption' : 'button';

  return (
    <ListItem alignItems="flex-start" style={{ width: 350 }} divider selected={false}>
      <ListItemAvatar>
        <Avatar className={notification.isRead ? styles.avatarRead : styles.avatar}>
          <FaultIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={(
          <React.Fragment>
            <div className={styles.row}>
              <Typography variant={isReadStyle}>
                {user.name}
              </Typography>
              <Typography
                variant="button"
                align="right"
              >
                {formatToDateWithTime(fault.created_at)}
              </Typography>
            </div>

            <Typography display="block" variant={isReadStyle}>
              Plate: {order.reference_number} - {plate.reference_number}
            </Typography>
          </React.Fragment>
        )}
        secondary={(
          <React.Fragment>
            <Typography variant={isReadStyle}>
              {fault.reason}
            </Typography>
          </React.Fragment>
        )}
      />
    </ListItem>
  );
};
