import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import { MyDialog } from '../../components/Dialog/MyDialog';
import { MyDialogCheckboxes } from '../../components/Dialog/MyDialogCheckboxes';
import { MyDialogUser } from '../../components/Dialog/MyDialogUser';
import { useCheckpointsHandler } from "../../hooks/useCheckpointsHandler";
import { useUserInput } from '../../hooks/useUserInput';

import { internalControlOrder } from '../../redux/actions/ordersActions';
import { COMMENT_TYPE } from '../../shared/constants';
import { ErrorMessage } from '../../components/Common/ErrorMessage';
import { useErrorHandler } from '../../hooks/useErrorHandler';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    alignSelf: 'flex-start'
  }
}));

function InternalControlDialog({ data: { id, comments }, internalControlOrder, checkpoints }: any) {
  const classes = useStyles();
  const { onUserChange, user } = useUserInput();
  const { error, handleError, isUserError } = useErrorHandler();
  const { checkpointsState, getPreparedCheckpoints, handleCheckpointChange, resetState } = useCheckpointsHandler(checkpoints);

  async function onSubmit() {
    try {
      await internalControlOrder(id, { user_id: user, checkboxes: getPreparedCheckpoints() });
      resetState();
    } catch (err) {
      // @ts-ignore
      handleError(err);
      throw err;
    }
  }

  const isInfo = comments.find((element: any) => element.comment_status && element.comment_type === COMMENT_TYPE.INFO);

  return (
    <MyDialog
      icon={<CheckIcon />}
      color="primary"
      dialogTitle="Internal Control"
      onSubmit={onSubmit}
      maxHeight={400}
      customColor={isInfo ? 'orange' : ''}
    >
      <div className={classes.root}>
        <div>
          <MyDialogCheckboxes
            checkpoints={checkpoints}
            selected={checkpointsState}
            onChange={handleCheckpointChange}
          />
        </div>
        <MyDialogUser onChange={onUserChange} userError={isUserError} user={user} />
        <ErrorMessage message={error} isVisible={!!error} />
      </div>
    </MyDialog>
  );
}

const mapDispatchToProps = { internalControlOrder };
const connected = connect(null, mapDispatchToProps)(InternalControlDialog);

export { connected as InternalControlDialog };
