import * as React from "react";

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  Table,
  TableBody
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/core/SvgIcon/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import { DataTableInformationCommentHeader } from "./DataTableInformationCommentHeader";
import { DataTableInformationComment } from "./DataTableInformationComment";

const useStyles = makeStyles(() => ({
  root: {
    margin: `0 1px`
  },

}));

type DataTableInformationOldComments = {
  withDelete?: boolean,
  withAddedTo?: boolean,
  withStatus?: boolean,
  plateId?: number,
  orderId?: number,
  comments: CommentType[]
}

export function DataTableInformationOldComments({ withDelete, withAddedTo, withStatus, plateId, orderId, comments }: DataTableInformationOldComments) {
  const classes = useStyles();

  return (
    <ExpansionPanel className={classes.root}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="button">Old Comments</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Table>
          <DataTableInformationCommentHeader withDelete={withDelete} withAddedTo={withAddedTo} />
          <TableBody>
            {comments.map((comment) => (
              <DataTableInformationComment
                key={comment.id}
                comment={comment}
                withStatus={withStatus}
                withDelete={withDelete}
                withAddedTo={withAddedTo}
                plateId={plateId}
                orderId={orderId}
              />
            ))}
          </TableBody>
        </Table>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
