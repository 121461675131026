import * as React from 'react'
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";

type Props = {
  className: string;
}

const useStyles = (isBold?: boolean) => makeStyles(() => ({
  header: { fontWeight: isBold ? 'normal' : 'normal', fontSize: 14 },
}));

export const WizardStepHeaderTitle: React.FC<React.PropsWithChildren<Props>> = ({ children, className }) => {
  const classes = useStyles(true)();
  return <div className={classNames({ [classes.header]: true, [className]: !!className })}>{children}</div>
}
