import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Divider,
  ListItemIcon,
  ListItem,
  List,
  ListItemText,
  Hidden,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import DoneIcon from '@material-ui/icons/Done';
import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import DeviationIcon from '@material-ui/icons/Create';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import UserIcon from '@material-ui/icons/PersonAdd';
import LogOutIcon from '@material-ui/icons/ExitToApp';

import logo from '../assets/logo.png';
import { logout } from '../redux/actions/accountActions';
import { Notifications } from './Notifications/Notifications';

const homeRoute = '/';
const completedRoute = '/completed';
const calendarRoute = '/calendar';
const faultRoute = '/fault';
const deviationRoute = '/deviation';
const manageUsersRoute = '/manage-users';

const nestedRoutes = {
  measurementsRoute: { route: '/measurements', title: 'Measurements' },
  internalControlRoute: { route: '/internal_control', title: 'Internal Control' },
  prepareRoute: { route: '/prepare', title: 'Prepare' },
  findRoute: { route: '/find', title: 'Find' },
  cutRoute: { route: '/cut', title: 'Cut' },
  edgePolishRoute: { route: '/edgepolish', title: 'Edge Polish' },
  holesCncRoute: { route: '/holes-cnc', title: 'Holes/Cnc' },
  manualFinishRoute: { route: '/manual-finish', title: 'Manual Finish' },
  installSinkRoute: { route: '/install-sink', title: 'Install Sink' },
  endControlRoute: { route: '/endcontrol', title: 'End Control' },
  packingRoute: { route: '/packing', title: 'Packing' },
  installationRoute: { route: '/installation', title: 'Installation' }
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    maxHeight: theme.mixins.toolbar.minHeight
  },
  toolbar: {
    height: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0)
    }
  },
  menu: {
    zIndex: 1,
    marginRight: theme.spacing(3),
  },
  header: {
    display: 'flex',
    height: theme.mixins.toolbar.minHeight - 10,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      left: 0,
      right: 0,
      justifyContent: 'center'
    },
  },
  headerLink: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 40,
      display: 'flex',
      alignSelf: 'center'
    },
  },
  img: {
    height: '100%'
  },
  list: {
    width: 300,
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  logoutWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    flex: 1
  },
  nested: {
    paddingLeft: theme.spacing(4),
  }
}));


const AppToolbar = ({ location, setSearch, searchOpen }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  function onLogout() {
    dispatch(logout());
  }

  const onClickSearch = () => setSearch(!searchOpen);

  const isLoggedIn = useSelector((state) => state.account.isLoggedIn);
  const requireRefresh = useSelector((state) => state.configuration.requireRefresh);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        {isLoggedIn && !requireRefresh ? (
          <React.Fragment>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              className={classes.menu}
            >
              <MenuIcon />
            </IconButton>
            <Drawer open={open} onClose={handleDrawerClose}>
              <div
                className={classes.list}
                tabIndex={0}
                role="button"
                onClick={handleDrawerClose}
                onKeyDown={handleDrawerClose}
              >
                <List component="nav">
                  <ListItem button component={Link} to={homeRoute} selected={location.pathname === homeRoute}>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                  </ListItem>
                  <List component="nav" disablePadding>
                    {Object.keys(nestedRoutes).map(key => (
                      <ListItem
                        key={key}
                        button
                        className={classes.nested}
                        component={Link}
                        to={nestedRoutes[key].route}
                        selected={location.pathname === nestedRoutes[key].route}
                      >
                        <ListItemText primary={nestedRoutes[key].title} />
                      </ListItem>
                    ))}
                  </List>

                  <Divider />
                  <ListItem button component={Link} to={completedRoute} selected={location.pathname === completedRoute}>
                    <ListItemIcon>
                      <DoneIcon />
                    </ListItemIcon>
                    <ListItemText primary="Completed" />
                  </ListItem>

                  <ListItem button component={Link} to={calendarRoute} selected={location.pathname === calendarRoute}>
                    <ListItemIcon>
                      <CalendarIcon />
                    </ListItemIcon>
                    <ListItemText primary="Calendar" />
                  </ListItem>

                  <ListItem button component={Link} to={faultRoute} selected={location.pathname === faultRoute}>
                    <ListItemIcon>
                      <InfoIcon />
                    </ListItemIcon>
                    <ListItemText primary="Faults" />
                  </ListItem>

                  <ListItem button component={Link} to={deviationRoute} selected={location.pathname === deviationRoute}>
                    <ListItemIcon>
                      <DeviationIcon />
                    </ListItemIcon>
                    <ListItemText primary="Deviations" />
                  </ListItem>
                  <Divider />

                  <ListItem button component={Link} to={manageUsersRoute}
                            selected={location.pathname === manageUsersRoute}>
                    <ListItemIcon>
                      <UserIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manage Users" />
                  </ListItem>
                </List>
                <List className={classes.logoutWrapper}>
                  <ListItem button onClick={onLogout}>
                    <ListItemIcon>
                      <LogOutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Log Out" />
                  </ListItem>
                </List>
              </div>
            </Drawer>
          </React.Fragment>
        ) : <div />}
        <div className={classes.header}>
          <Link to={homeRoute} className={classes.headerLink}>
            <img src={logo} alt="" className={classes.img} />
          </Link>
        </div>
        <div>
          {isLoggedIn && !requireRefresh && (
            <Notifications />
          )}
          {location.pathname === '/' && (
            <Hidden mdUp>
              <IconButton
                aria-label="Show Search"
                onClick={onClickSearch}
                color={searchOpen ? 'secondary' : 'inherit'}
              >
                <SearchIcon />
              </IconButton>
            </Hidden>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(AppToolbar);
