import dayjs from 'dayjs';
import { PLATE_TYPE, CANCEL_TYPE, COMMENT_TYPE } from '../shared/constants';

export const selectPlateEntities = (state: GlobalStoreType) => state.plates.list
  .filter((element: number) => !state.plates.faultList.includes(element) || state.plates.faultEntities[element].no_new_plate)
  .map(key => state.plates.entities[key]);

const selectPlateDisplay = (filterStage?: string) => (state: GlobalStoreType) => {
  const entities = selectPlateEntities(state);
  if (filterStage) {
    return entities.filter((plate) => plate.current_stage === filterStage && plate.delivery_date && plate.delivery_method);
  }

  return selectPlateEntities(state);
};

export const selectPlateFullData = (filterStage?: string) => (state: GlobalStoreType) => selectPlateDisplay(filterStage)(state)
  .map((element: Plate) => {
    const order = state.orders.entities[element.order_id] || { reference_number: '' };
    const order_reference_number = order.reference_number;

    const comments = state.comments.list
      .map(key => state.comments.entities[key])
      .filter(comment => comment.order_id === element.order_id || comment.plate_id === element.id);

    const files = state.files.list
      .map(key => state.files.entities[key])
      .filter(file => file.order_id === element.id);

    const actions = state.history.list
      .map(key => state.history.entities[key])
      .filter(history => history.order_id === element.id);

    return { ...element, order_reference_number, comments, files, actions };
  });


export const selectFaultyPlates = (state: GlobalStoreType) => {
  const faultPlates = state.plates.faultListById.map(fault => state.plates.faultEntitiesById[fault]);
  return faultPlates
    .map(fp => {
      const plate = state.plates.entities[fp.plate_id];

      const order = state.orders.entities[plate.order_id] || { reference_number: '' };
      const order_reference_number = order.reference_number;

      const reasonDict = state.dictionary.faultReasonDictionary;
      const reasonKeys = fp.reason.split("-").reduce((keys: string[], key: string, i: number) => [...keys, keys.slice(-1) + (i ? "-" : "") + key], []);
      const reasonText = reasonKeys.reduce((text: string, key: string) => text += reasonDict.find(d => d.value === key)?.text + "\n", "");
      fp.reason_text = reasonText;

      const faultComment = state.comments.list
        .map(comment => state.comments.entities[comment])
        .find(comment => comment.id === fp.comment_id);

      return {
        ...plate,
        order_reference_number,
        fault_plate_id: fp.fault_plate_id,
        fault_id: fp.id,
        cost: fp.cost,
        reason: fp.reason,
        reason_text: fp.reason_text,
        fault_at: fp.created_at,
        fault_by: fp.fault_by,
        user: state.configuration.users[fp.user_id]?.name,
        comment_id: faultComment?.id,
        comment_text: faultComment?.comment_text
      };
    })
    .sort((a, b) => dayjs(a.fault_at).isBefore(dayjs(b.fault_at)) ? 1 : -1);
};

export const selectOrderFullData = (state: GlobalStoreType) => state.orders.list
  .map(key => state.orders.entities[key])
  .map((order: Order) => {
    const order_reference_number = order.reference_number;

    const comments = state.comments.list
      .map(key => state.comments.entities[key])
      .filter(comment => comment.order_id === order.id);

    const files = state.files.list
      .map(key => state.files.entities[key])
      .filter(file => file.order_id === order.id);

    const actions = state.history.list
      .map(key => state.history.entities[key])
      .filter(history => history.order_id === order.id);

    return { ...order, order_reference_number, comments, files, actions };
  });

export const selectCalendarEvents = () => (state: GlobalStoreType) => {
  const plateList = selectPlateFullData()(state).filter(plate => ![PLATE_TYPE.COMPLETED, CANCEL_TYPE].includes(plate.current_stage));

  const plateDic = plateList.reduce((acc: any, plate: Plate) => {
    if (acc[plate.order_id]) {
      if (acc[plate.order_id][plate.delivery_date]) {
        acc[plate.order_id][plate.delivery_date].push(plate);
      } else {
        acc[plate.order_id] = { ...acc[plate.order_id], [plate.delivery_date]: [plate] }
      }
    } else {
      acc[plate.order_id] = { [plate.delivery_date]: [plate] }
    }
    return acc;
  }, {});

  const ordersList = selectOrderFullData(state).filter((order: Order) => order.measure_date != null && order.delivery_date != null && order.cancel_date == null);

  const eventOrders = ordersList
    .reduce((acc: any[], order: Order) => {
      if (!order) return acc;

      const dates = plateDic[order.id];

      if (dates) {
        const dateKeys = Object.keys(dates);
        const plates = dateKeys.reduce((acc: any[], date: string) => [...acc, ...dates[date]], []);

        const events = dateKeys.map((date: string) => {
          const dayPlates = dates[date];
          const plateNumbers = " [" + dayPlates.map((plate: Plate) => plate.reference_number).join(", ") + "]";
          const title = order.reference_number + plateNumbers;
          const start = new Date(date);
          start.setHours(0)
          start.setMinutes(0)
          start.setSeconds(0)
          start.setMilliseconds(0)
          const end = start;
          const types = dayPlates?.reduce((acc: any, plate: Plate) => (plate.types ? [...acc, ...plate.types.filter((tp: PlateTypes) => tp.plate_type.includes("VASK_"))] : acc), [])
          
          let onHoldCounter = 0;
          let finishStageCounter = 0;
          const isUnfinished = plates?.some((p: Plate) => {
            const holdPlate = p.comments?.find(comment => comment.plate_id === p.id && comment.comment_type === COMMENT_TYPE.ON_HOLD && comment.comment_status);
            if (holdPlate) {
              if (!holdPlate.is_separately) {  
                onHoldCounter++;
              }
              return onHoldCounter > 1;
            } else if ([PLATE_TYPE.INSTALLATION, PLATE_TYPE.PACKING, PLATE_TYPE.COMPLETED].includes(<any>p.current_stage)) {
              finishStageCounter++;
              return false;
            } else {
              return true;
            }
          });
          const isFinish = !isUnfinished && finishStageCounter > 0;

          return { ...order, title, start, end, plates, types, isFinish }
        });

        return [...acc, ...events];
      } else {
        const newOrderInfo = { ...order, ...state.orders.newOrdersInfoEntities[order.reference_number as any] }
        const title = order.reference_number;
        const start = new Date(order.delivery_date);
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(0)
        start.setMilliseconds(0)
        const end = start;
        const event = { ...newOrderInfo, title, start, end };

        return [...acc, event]
      }
    }, []);

  return eventOrders;
};
