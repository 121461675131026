import { Dispatch } from 'redux';
import { APIClient } from '../services/apiService';
import { normalize, NormalizedSchema, schema } from 'normalizr';

type FetchConfigurationsAction = { readonly type: typeof FETCH_CONFIGURATIONS, configurations: AppConfiguration, users: NormalizedSchema<UserEntity, number[]> }
type AddUserAction = { readonly type: typeof ADD_USER_SUCCESS, userId: string, name: string }
type EnforceRefreshAction = { readonly type: typeof ENFORCE_REFRESH }
type ConfigurationActionTypes = FetchConfigurationsAction | AddUserAction | EnforceRefreshAction

const userSchema = new schema.Entity('user');
const usersSchema = new schema.Array(userSchema);

const initialState: AppConfiguration = {
  requireRefresh: false,
  orderBaseUrl: '',
  users: {}
};

const ENFORCE_REFRESH = 'ENFORCE_REFRESH';
const FETCH_CONFIGURATIONS = 'FETCH_CONFIGURATIONS';
const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';

export const enforceRefresh = () => ({
  type: ENFORCE_REFRESH
});

const fetchConfigurationsSuccess = (configurations: AppConfiguration, users: NormalizedSchema<UserEntity, number[]>): ConfigurationActionTypes => ({
  type: FETCH_CONFIGURATIONS,
  configurations,
  users
});
export const fetchConfigurations = () => async (dispatch: Dispatch) => {
  const response = await APIClient.get(`configuration`);
  const normalizedUsers = normalize<'user', UserEntity, number[]>(response.data.users, usersSchema);

  dispatch(fetchConfigurationsSuccess(response.data, normalizedUsers));
};

type AddUserParams = {
  userId: string,
  name: string
}
const addUserSuccess = (params: AddUserParams) => ({ type: ADD_USER_SUCCESS, ...params });
export const addUser = (body: AddUserParams) => async (dispatch: Dispatch) => {
  await APIClient.post(`configuration/user`, body);
  dispatch(addUserSuccess(body));
};

export function configurationReducer(state = initialState, action: ConfigurationActionTypes): AppConfiguration {
  switch (action.type) {
    case FETCH_CONFIGURATIONS:
      return {
        ...state,
        orderBaseUrl: action.configurations.orderBaseUrl,
        users: action.users.entities.user
      };

    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.userId]: { id: action.userId, name: action.name }
        },
      };

    case ENFORCE_REFRESH:
      return {
        ...state,
        requireRefresh: true
      };

    default:
      return state;
  }
}
