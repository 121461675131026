import * as React from 'react';
import {
  MenuItem,
  Select
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

type FaultReason = {
  dictionary: DictionaryType[],
  value: string,
  setValue: (value: string) => void
}

const useStyles = makeStyles(() => ({
  select: {
    marginTop: '0.5rem'
  }
}));

export const FaultReason: React.FunctionComponent<FaultReason> = ({ dictionary, value, setValue}) => {
  const styles = useStyles();

  const lev1Keys = dictionary.filter(arr => arr.value.split("-").length === 1);
  const lev2Keys = dictionary.filter(arr => arr.value.split("-").length === 2);
  const lev3Keys = dictionary.filter(arr => arr.value.split("-").length === 3);
  const lev4Keys = dictionary.filter(arr => arr.value.split("-").length === 4);

  const filterKeys = (keys: any[], key: any) => {
    const result = keys.filter(k => k.value.includes(key.value));
    return result.length ? result : false;
  }

  const lev2Dic: any = lev1Keys.reduce((d, k) => ({...d, [k.value]: filterKeys(lev2Keys, k)}), {});
  const lev3Dic: any = lev2Keys.reduce((d, k) => ({...d, [k.value]: filterKeys(lev3Keys, k)}), {});
  const lev4Dic: any = lev3Keys.reduce((d, k) => ({...d, [k.value]: filterKeys(lev4Keys, k)}), {});

  const reasonKeys = value.split("-");
  const reason1 = reasonKeys[0];
  const reason2 = reasonKeys[1] ? [reason1, reasonKeys[1]].join("-") : "";
  const reason3 = reasonKeys[2] ? [reason2, reasonKeys[2]].join("-") : "";
  const reason4 = reasonKeys[3] ? [reason3, reasonKeys[3]].join("-") : "";

  const [localReason1, setLocalReason1] = React.useState(reason1);
  const [localReason2, setLocalReason2] = React.useState(reason2);
  const [localReason3, setLocalReason3] = React.useState(reason3);
  const [localReason4, setLocalReason4] = React.useState(reason4);

  const onChangeReason1 = (evt: React.ChangeEvent<OnChangeMaterial>) => setLocalReason1(evt.target.value as string);
  const onChangeReason2 = (evt: React.ChangeEvent<OnChangeMaterial>) => setLocalReason2(evt.target.value as string);
  const onChangeReason3 = (evt: React.ChangeEvent<OnChangeMaterial>) => setLocalReason3(evt.target.value as string);
  const onChangeReason4 = (evt: React.ChangeEvent<OnChangeMaterial>) => setLocalReason4(evt.target.value as string);

  React.useEffect(() => {
    if (localReason2 && (!lev2Dic[localReason1] || !lev2Dic[localReason1].some((item: any) => item.value === localReason2))) {
      setLocalReason2("" as string);
    }
  }, [localReason1]);
  
  React.useEffect(() => {
    if (localReason3 && (!lev3Dic[localReason2] || !lev3Dic[localReason2].some((item: any) => item.value === localReason3))) {
      setLocalReason3("" as string);
    }
  }, [localReason2]);
  
  React.useEffect(() => {
    if (localReason4 && (!lev4Dic[localReason3] || !lev4Dic[localReason3].some((item: any) => item.value === localReason4))) {
      setLocalReason4("" as string);
    }
  }, [localReason3]);

  React.useEffect(() => {
    setValue(localReason4 || localReason3 || localReason2 || localReason1);
  }, [localReason1, localReason2, localReason3, localReason4]);

  return (
    <>
      <Select
        value={localReason1}
        onChange={onChangeReason1}
        fullWidth
        inputProps={{ name: 'select', id: 'select-helper' }}
      >
        {lev1Keys.map(({ text , value }: DictionaryType) => (
          <MenuItem key={value} value={value}>
            {text}
          </MenuItem>
        ))}
      </Select>
      {localReason1 && lev2Dic[localReason1] &&
        <Select
          value={localReason2}
          onChange={onChangeReason2}
          fullWidth
          inputProps={{ name: 'select', id: 'select-helper' }}
          className={ styles.select }
          >
          {lev2Dic[localReason1].map(({ text, value }: DictionaryType) => (
            <MenuItem key={value} value={value}>
              {text}
            </MenuItem>
          ))}
        </Select>
      }
      {localReason2 && lev3Dic[localReason2] &&
        <Select
          value={localReason3}
          onChange={onChangeReason3}
          fullWidth
          inputProps={{ name: 'select', id: 'select-helper' }}
          className={ styles.select }
          >
          {lev3Dic[localReason2].map(({ text, value }: DictionaryType) => (
            <MenuItem key={value} value={value}>
              {text}
            </MenuItem>
          ))}
        </Select>
      }
      {localReason3 && lev4Dic[localReason3] &&
        <Select
          value={localReason4}
          onChange={onChangeReason4}
          fullWidth
          inputProps={{ name: 'select', id: 'select-helper' }}
          className={ styles.select }
        >
          {lev4Dic[localReason3].map(({ text, value }: DictionaryType) => (
            <MenuItem key={value} value={value}>
              {text}
            </MenuItem>
          ))}
        </Select>
      }
    </>
  );
};
