import { NormalizedSchema } from 'normalizr';

export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const FETCH_NOTIFICATION_BY_SOCKET = 'FETCH_NOTIFICATION_BY_SOCKET';
export const SET_LAST_NOTIFICATION = 'SET_LAST_NOTIFICATION';

export type FetchNotificationAction = { readonly type: typeof FETCH_NOTIFICATIONS, notifications: NormalizedSchema<NotificationEntity, number[]>, lastNotificationId: number, lastNotificationDate: Date }
export type FetchNotificationBySocketAction = { readonly type: typeof FETCH_NOTIFICATION_BY_SOCKET, notification: NotificationType }
export type SetLastNotificationIdAction = { readonly type: typeof SET_LAST_NOTIFICATION, lastNotificationId: number, lastNotificationDate: Date }

export type NotificationActionTypes = FetchNotificationAction | FetchNotificationBySocketAction | SetLastNotificationIdAction;
