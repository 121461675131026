import { NormalizedSchema } from 'normalizr';
import { MaterialActionTypes } from '../materials/MaterialTypes';
import { PlateTypeActionTypes } from '../plateTypes/PlateTypeTypes';
import { FetchCommentByIdAction, FetchCommentsByPlateAction, DeleteCommentBySocketAction } from '../comments/CommentTypes';
import { DeleteFileBySocket, FetchFileBySocket, FetchFilesByPlate } from '../files/FileTypes';
import { FetchHistoryPlateAction } from '../history/HistoryTypes';

export const FETCH_PLATES = 'FETCH_PLATES';
export const FETCH_COMPLETED_PLATES = 'FETCH_COMPLETED_PLATES';
export const FETCH_FAULTS = 'FETCH_FAULTS';

export const FETCH_PLATE_BY_SOCKET = 'FETCH_PLATE_BY_SOCKET';
export const FETCH_FAULT_BY_SOCKET = 'FETCH_FAULT_BY_SOCKET';
export const FETCH_FAULT_COST_BY_SOCKET = 'FETCH_FAULT_COST_BY_SOCKET';

export type FetchNewPlatesAction = { readonly type: typeof FETCH_PLATES, plates: NormalizedSchema<PlateEntity, number[]> }
export type FetchCompletedPlatesAction = { readonly type: typeof FETCH_COMPLETED_PLATES, plates: NormalizedSchema<PlateEntity, number[]> }
export type FetchFaultsAction = { readonly type: typeof FETCH_FAULTS, faults: NormalizedSchema<FaultEntity, number[]>, plateFaults: NormalizedSchema<FaultEntity, number[]>, faultsById: NormalizedSchema<FaultEntity, number[]> }
export type FetchPlateBySocketAction = { readonly type: typeof FETCH_PLATE_BY_SOCKET, plate: Plate }
export type FetchFaultBySocketAction = { readonly type: typeof FETCH_FAULT_BY_SOCKET, fault: Fault }
export type FetchFaultCostBySocketAction = { readonly type: typeof FETCH_FAULT_COST_BY_SOCKET, fault: Fault }

export type PlateActionTypes =
  FetchNewPlatesAction
  | FetchCompletedPlatesAction
  | FetchPlateBySocketAction

  | FetchFaultsAction
  | FetchFaultBySocketAction
  | FetchFaultCostBySocketAction

  | MaterialActionTypes
  | PlateTypeActionTypes
  | FetchCommentsByPlateAction
  | FetchCommentByIdAction
  | DeleteCommentBySocketAction
  | FetchFilesByPlate
  | FetchFileBySocket
  | DeleteFileBySocket
  | FetchHistoryPlateAction
