const TYPES = {
  DRAWING_NOT_READY: 'DRAWING-NOT-READY',
  AFFECT_PRICE: 'AFFECT-PRICE',
  DRAWING_NOT_READY_AFFECT_PRICE: 'DRAWING-NOT-READY-AFFECT-PRICE'
};

const DRAWING_NOT_READY = 'Details need to be clarified';
const AFFECT_PRICE = 'New order confirmation';
const DRAWING_NOT_READY_AFFECT_PRICE = `${DRAWING_NOT_READY} + ${AFFECT_PRICE}`;

const COMMENTS = {
  [TYPES.DRAWING_NOT_READY]: DRAWING_NOT_READY,
  [TYPES.AFFECT_PRICE]: AFFECT_PRICE,
  [TYPES.DRAWING_NOT_READY_AFFECT_PRICE]: DRAWING_NOT_READY_AFFECT_PRICE
}

export const PRICE_FROM_OFFICE = "PRICE_FROM_OFFICE";

const OnHoldFlowConstants = {
  TYPES,
  COMMENTS
};

export default OnHoldFlowConstants;