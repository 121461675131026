import * as CommentTypes from './CommentTypes';
import { CommentActionTypes } from './CommentTypes';

const initialState: CommentStoreType = {
  entities: {},
  list: []
};

export function commentReducer(state = initialState, action: CommentActionTypes): CommentStoreType {
  switch (action.type) {
    case CommentTypes.FETCH_COMMENTS:
    case CommentTypes.FETCH_DEVIATIONS:
    case CommentTypes.FETCH_FAULTS_COMMENT:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.comments.entities.comment,
        },
        list: [...new Set([...state.list, ...action.comments.result])]
      };

    case CommentTypes.FETCH_IMPORTANT_COMMENT_BY_ID:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.comment.id]: action.comment
        },
        list: [...new Set([...state.list, action.comment.id])]
      };


    default:
      return state;
  }
}
