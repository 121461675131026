import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import { HistoryGroupingTable } from '../HistoryTable/HistoryGroupingTable';
import { HistoryTable } from '../HistoryTable/HistoryTable';
import { useSelector } from 'react-redux';
import { Theme } from '@material-ui/core';

type DataTableInformationHistoryType = {
  actions: HistoryMetaAction[],
  withPlateId: boolean
};

const useStyles = makeStyles((theme: Theme) => ({
  spacing: {
    margin: theme.spacing(3, 0)
  },
}));

export function DataTableInformationHistory({ actions, withPlateId }: DataTableInformationHistoryType) {
  const styles = useStyles();
  const plates = useSelector((state: GlobalStoreType) => state.plates.entities);
  const faults = useSelector((state: GlobalStoreType) => state.plates.faultEntities);

  if (withPlateId) {
    const plateIndex: any = {};
    let currentFault: any = [];
    const plateRows = actions
      .filter(action => action.plate_id)
      .map(action => {
        const plate = plates[action.plate_id];
        const fault = faults[action.plate_id];

        if (fault) {
          currentFault = [...new Set([...currentFault, action.plate_id])];
        }

        if (!plateIndex[action.plate_id]) {
          plateIndex[action.plate_id] = fault ? `${plate.reference_number} - Fault ${currentFault.length}` : plate.reference_number;

        }

        return {
          ...action,
          plate: plateIndex[action.plate_id]
        };
      });


    return (
      <React.Fragment>
        <HistoryTable rows={actions.filter(action => !action.plate_id)} />
        <div className={styles.spacing} />
        <HistoryGroupingTable rows={plateRows} />
      </React.Fragment>
    );
  }

  return (
    <HistoryTable rows={actions} />
  );
}
