export const HistoryTableColumns = [
  { name: 'value', title: ' ' },
  { name: 'key', title: 'Key' },
  { name: 'user_id', title: 'User' },
  { name: 'created_at', title: 'Date' },
  { name: 'step', title: 'Step' },
];

export const HistoryTableColumnsWidth = [
  { columnName: 'value', width: 100 },
  { columnName: 'key', wordWrapEnabled: true },
  { columnName: 'user_id', width: 100 },
  { columnName: 'created_at', width: 140 },
  { columnName: 'step', width: 120 },
];

export const HistoryTableGroupingColumns = [
  { name: 'value', title: ' ' },
  { name: 'plate', title: 'Plate' },
  { name: 'key', title: 'Key' },
  { name: 'user_id', title: 'User' },
  { name: 'created_at', title: 'Date' },
  { name: 'step', title: 'Step' },
];
