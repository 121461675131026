import * as React from 'react';
import { DialogContent, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

type MyDialogContentType = {}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: '0 8px 8px'
    },
  },
}));

export const MyDialogContent: React.FunctionComponent<MyDialogContentType> = ({ children }) => {
  const styles = useStyles();

  return (
    <DialogContent className={styles.container}>
      {children}
    </DialogContent>
  );
};
