import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckIcon from '@material-ui/icons/Check';

import { MyDialog } from '../../components/Dialog/MyDialog';
import { usePlateHoleHandler } from '../../hooks/usePlateHoleHandler';
import { useErrorHandler } from '../../hooks/useErrorHandler';

import { prepareOrder, setDeliveryDate, setDeliveryMethod } from '../../redux/actions/ordersActions';
import { COMMENT_TYPE } from '../../shared/constants';
import { WizardStep } from '../../components/WizardStep/WizardStep';

import { PrepareWizardQuestions } from '../../shared/WizardStep/PrepareWizardQuestions';
import { SPECIAL_COMPONENTS } from "../../shared/WizardStep/WizardEnums";
import { useCheckpointsHandler } from "../../hooks/useCheckpointsHandler";

export const PrepareDialog: React.FC<any> = ({ data: { id, comments, cutouts, delivery_date, delivery_method } }) => {
  const { handleError } = useErrorHandler();

  const deliveryMethods = useSelector((state: GlobalStoreType) => state.dictionary.deliveryMethodsDictionary);
  const checkpoints = useSelector((state: GlobalStoreType) => state.dictionary.prepareCheckpoints);
  const {
    dictionary,
    platesNumber,
    platesArray,
    platesArrayGroup,
    setPlates,
    setMultiSelect,
    onNoManualFinish,
    onNoEdgePolish,
    onEdgePolishOnCnc,
    onPlateNumberChange,
    setTypeCount,
    platesHoleHandlerHasError,
  } = usePlateHoleHandler(cutouts);
  const {
    checkpointsState,
    handleCheckpointChange,
  } = useCheckpointsHandler(checkpoints);

  const [userId, setUserId] = React.useState<number>();

  const [selectedDate, setDate] = React.useState(delivery_date ? new Date(delivery_date) : null);
  const [selectedMethod, setMethod] = React.useState(delivery_method);
  const isCheckpointsHidden = !selectedMethod || selectedMethod.includes("IBSG") || selectedMethod.includes("IBNIT") || selectedMethod.includes("DBSG");
  const checkpointsToShow = isCheckpointsHidden ? checkpoints.filter(e => e.value.includes("Drawing")) : checkpoints;

  const submitDeliveryDate = (date: any) => setDate(date);

  const submitDeliveryMethod = (evt: any) => setMethod(evt.target.value);

  const isInfo = comments.find((element: CommentType) => element.comment_status && element.comment_type === COMMENT_TYPE.INFO);

  async function submitPrepareOrder() {
    try {
      await prepareOrder(id, {
        plates: platesArray,
        user_id: userId,
        delivery_date: selectedDate,
        delivery_method: selectedMethod
      });
    } catch (err) {
      // @ts-ignore
      handleError(err);
      throw err;
    }
  }

  const [allStepsCompleted, setAllStepsCompleted] = React.useState(false);

  const onStepsCompleted = (value: boolean) => {
    setAllStepsCompleted(value);
  }

  const onSubmit = () => {
    if (allStepsCompleted) {
      submitPrepareOrder();
    }
  }

  const getExtraData = (spec: SPECIAL_COMPONENTS) => {
    switch (spec) {
      case SPECIAL_COMPONENTS.SetPlateHoles:
        return {
          dictionary,
          platesNumber,
          platesArray,
          platesArrayGroup,
          setPlates,
          setMultiSelect,
          onNoManualFinish,
          onNoEdgePolish,
          onEdgePolishOnCnc,
          onPlateNumberChange,
          setTypeCount
        };

      case SPECIAL_COMPONENTS.CheckMethodAndDate:
        return {
          selectedDate,
          submitDeliveryDate,
          submitDeliveryMethod,
          selectedMethod,
          deliveryMethods,
          checkpointsToShow,
          checkpointsState,
          handleCheckpointChange
        };

      default:
        return {};
    }
  }

  const specHasError = React.useMemo(() => ({
    [SPECIAL_COMPONENTS.SetPlateHoles]: platesHoleHandlerHasError,
    [SPECIAL_COMPONENTS.CheckMethodAndDate]: !selectedMethod || !selectedDate
  }), [platesHoleHandlerHasError, selectedDate, selectedMethod])

  return (
    <MyDialog
      buttonLabel="Prepare"
      dialogTitle="Prepare"
      onSubmit={onSubmit}
      onSubmitDisabled={!allStepsCompleted}
      onSubmitClose
      modalSize="lg"
      minHeight={650}
      icon={<CheckIcon />}
      isStepper={true}
      color="primary"
      customColor={isInfo ? 'orange' : ''}
    >
      {({ onClose }: any) => (
        <WizardStep
          orderId={id}
          stepList={PrepareWizardQuestions}
          onStepsCompleted={onStepsCompleted}
          onUserIdChange={setUserId}
          onClose={onClose}
          getExtraData={getExtraData}
          specHasError={specHasError}
        />
      )}
    </MyDialog>
  );
}
