import * as React from 'react';
import { Tooltip, TableRow } from '@material-ui/core';
import classnames from 'classnames';

import { useStylesTable } from './MetaDataStyles';
import { MetaDataTableRowInformationElement } from './MetaDataTableRowInformationElement';
import { COMMENT_TYPE } from '../../shared/constants';
import OnHoldFlowConstants from '../../shared/constants/OnHoldFlowConstants';

export const MetaDataTableRow = ({ row, ...restParams }: any) => {
  const [isOpen, setOpen] = React.useState(false);
  const classes = useStylesTable();

  const handleMock = () => null;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const isPlate = !!row.order_id && !row.rootId;
  const isOnHoldComment = (row: Order & Plate, comment: CommentType) => {
    if (isPlate) {
      if (comment.order_id === row.order_id && !comment.plate_id) {
        return comment;
      }

      if (comment.order_id === row.order_id && comment.plate_id === row.id) {
        return comment;
      }

      return null;
    }

    return row.id === comment.order_id ? comment : null;
  };

  const onHoldComment = row.comments?.find((comment: CommentType) => 
    [COMMENT_TYPE.ON_HOLD, COMMENT_TYPE.ON_HOLD_FLOW].includes(comment.comment_type as any)
    && comment.comment_status && isOnHoldComment(row, comment));

  let commentText = '';
  
  if (onHoldComment) {
    commentText = onHoldComment.comment_type === COMMENT_TYPE.ON_HOLD_FLOW
      ? OnHoldFlowConstants.COMMENTS[onHoldComment?.comment_text]
      : onHoldComment?.comment_text;
  }

  const classRow = classnames({ [classes.onHoldAnimation]: onHoldComment });
  const onClick = row.noDeliveries ? handleMock : handleOpen;

  if (onHoldComment) {
    return (
      <React.Fragment>
        <Tooltip title={commentText} classes={{ popper: classes.tooltip }}>
          <TableRow  {...restParams} onClick={onClick} style={{ cursor: 'pointer' }} className={classRow} />
        </Tooltip>
        <MetaDataTableRowInformationElement isOpen={isOpen} handleClose={handleClose} row={row} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <TableRow  {...restParams} onClick={onClick} style={{ cursor: 'pointer' }} className={classRow} />
      <MetaDataTableRowInformationElement isOpen={isOpen} handleClose={handleClose} row={row} />
    </React.Fragment>
  );
};
