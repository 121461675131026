import * as React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Paper, Theme } from '@material-ui/core';

import { MetaDataTable } from './MetaDataTable/MetaDataTable';
import { ManageUsersAdd } from '../components/ManageUsers/ManageUsersAdd';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center'
  },
  value: {
    fontWeight: 700,
    marginLeft: theme.spacing(2)
  }
}));

export function ManageUsers() {
  // const dispatch = useDispatch();
  const styles = useStyles();
  const users = useSelector((state: GlobalStoreType) => state.configuration.users);

  return (
    <MetaDataTable
      pageSubtitle={
        <Paper className={styles.root}>
          <ManageUsersAdd />
        </Paper>
      }
      disableSorting
      title="Manage Users"
      actionType="users"
      rows={Object.keys(users).map(key => users[key as any]).map(element => ({ ...element, username: element.name }))}
    />
  );
}

