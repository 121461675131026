import * as React from 'react';
import { Paper, Theme, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

type PageTitleType = {
  title: string
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    },
  },
}));
export const PageTitle: React.FunctionComponent<PageTitleType> = ({ title }) => {
  const styles = useStyles();
  const theme = useTheme<Theme>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Paper className={styles.title}>
      <Typography variant={isMobile ? 'h5' : 'h4'}>
        {title}
      </Typography>
    </Paper>
  );
};
