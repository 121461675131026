import * as React from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { MetaDataTable } from "../MetaDataTable/MetaDataTable";
import { selectCheckpoints, selectDisplayWithInfoRow } from '../../redux/selectors';

export function InternalControlOrdersTable() {
  const orders: Order[] = useSelector(selectDisplayWithInfoRow(element => element.measure_date && !element.approve_date && !element.cancel_date))
    .sort((a: Order, b: Order) => dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1);
  const checkpoints: CheckpointType[] = useSelector(selectCheckpoints("internalControlCheckpoints"));

  return (
    <MetaDataTable
      title="Internal Control"
      actionType="internal_control"
      rows={orders}
      checkpoints={checkpoints}
      disableSorting
      addExtraInfo
      withFlag
    />
  );
}
