import * as orderConstants from "../constants/ordersConstants";

const INITIAL_STATE = {
  files: []
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case orderConstants.GET_INVOICES_SUCCESS:
      return {
        ...state,
        files: action.payload
      };
    default:
      return state;
  }
};
