import jwt from "jsonwebtoken";

import * as accountConstants from "../constants/accountConstants";

const INITIAL_STATE = {
  token: "",
  isLoggedIn: false
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case accountConstants.LOGIN_SUCCESS:
      const account = jwt.decode(action.payload);
      return {
        ...state,
        token: action.payload,
        name: account.navn,
        email: account.epost,
        isProduction: account.rolle === "PRODUKSJON",
        isMonter: account.rolle === "MONTØR",
        isAdmin: account.isAdmin,
        isLoggedIn: true,
        sellerTeams: account.measuringTeam || account.installationTeam 
          ? {
            measuringId: account.measuringTeam,
            installationId: account.installationTeam
          } : false
      };

    case accountConstants.LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};
