import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import { TextField, Select, MenuItem } from '@material-ui/core';

type EditRowType = {
  label: string,
  value: string,
  setValue?: Function,
  options?: string[],
  multiline?: boolean
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: '8px',
  },
  label: {
    flex: '0 0 150px',
    marginRight: '16px',
    fontWeight: 'bold',
  },
  value: {
    flex: '1'
  },
}));

const EditRow = ({ label, value, setValue, options, multiline }: EditRowType) => {
  const classes = useStyles();

  if (!value && !setValue) {
    return null;
  }

  const onChange = (e: any) => {
    if (setValue) {
      setValue(e.target.value);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.label}>{label}</div>
      <div className={classes.value}>
        {setValue && !options && (
          <TextField 
            value={value}
            onChange={onChange}
            fullWidth
            multiline
            rows={multiline ? 3 : 1}
          />
        )}
        {setValue && options && (
          <Select 
            value={value} 
            onChange={onChange}
            fullWidth
          >
            <MenuItem>Choose...</MenuItem>
            {options.map(item => (
              <MenuItem key={item} value={item}>{item}</MenuItem>
            ))}
          </Select>
        )}
        {!setValue && (<span>{value}</span>)}
      </div>
    </div>
  );
};

export default EditRow;