import * as React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export const FileUploadTableImage = ({ file }: { file: FileType & FileUploadServer }) => {
  const [open, setOpen] = React.useState(false);
  const onClick = () => setOpen(!open);

  const path = `${file.path}?timestamp=${new Date(file.created_at!).getTime()}` ;
  return (
    <React.Fragment>
      <img alt=" " src={path} style={{ maxHeight: '60px', margin: '8px 0' }} onClick={onClick} />

      {open && (
        <Lightbox
          reactModalStyle={{ overlay: { zIndex: 1400 } }}
          mainSrc={path}
          onCloseRequest={onClick}
        />
      )}
    </React.Fragment>
  );
};
