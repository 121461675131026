import * as React from 'react';
import { useDispatch } from 'react-redux';

import { MeasurementsEstimateDialog } from './MeasurementsEstimateDialog';
import { MeasurementsConfirmDialog } from './MeasurementsConfirmDialog';
import { MyDialog } from '../../components/Dialog/MyDialog';

import { fetchFilesForRecord } from '../../redux/files/FileActions';

import { measureOrder } from '../../redux/actions/ordersActions';
import { COMMENT_TYPE } from '../../shared/constants';
import { getMeasurementWizardQuestions } from "../../shared/WizardStep/MeasurementWizardQuestions";
import { WizardStateEnum } from "../../shared/WizardStep/WizardEnums";
import { WizardStep } from '../../components/WizardStep/WizardStep';
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { SPECIAL_COMPONENTS } from "../../shared/WizardStep/WizardEnums";
import { formatToDate, formatToDateUtc, formatToTimeUtc } from '../../services/DateFormatter';

type MeasurementsDialogType = {
  data: Order
}

export function MeasurementsDialog({ data }: MeasurementsDialogType) {
  const dispatch = useDispatch();
  const { handleError } = useErrorHandler();

  React.useEffect(() => {
    dispatch(fetchFilesForRecord(data.id));
  }, []);

  const [userId, setUserId] = React.useState<number>();

  async function submitMeasureOrder() {
    try {
      await measureOrder(data.id, { user_id: userId });
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  const isInfo = data.comments.find(element => element.comment_status && element.comment_type === COMMENT_TYPE.INFO);
  const isUncontrolled = !data.control_measures;

  const [allStepsCompleted, setAllStepsCompleted] = React.useState(false);

  const onStepsCompleted = (value: boolean) => {
    setAllStepsCompleted(value);
  }

  const onSubmit = () => {
    if (allStepsCompleted) {
      submitMeasureOrder();
    }
  }

  const hasAllTabsAvailable = !!data.confirm_date;
  const stepList = React.useMemo(() => getMeasurementWizardQuestions(data), [data]);

  const setStepList = React.useMemo(() => hasAllTabsAvailable ? stepList : stepList.map(step => ({
    ...step,
    state: step.required ? step.state : WizardStateEnum.DISABLED
  })), [hasAllTabsAvailable, stepList]);

  const getExtraData = (spec: SPECIAL_COMPONENTS) => {
    if (spec === SPECIAL_COMPONENTS.CutoutsEdit) {
      return { orderId: data.id }
    }

    return {};
  }

  if (!isUncontrolled && !data.is_estimated) {
    return (
      <MeasurementsEstimateDialog
        orderId={data.id} buttonLabel="Estimate"
        date={data.estimate_date} isEstimated={data.is_estimated}
      />
    );
  }

  if (!isUncontrolled && !data.confirm_date) {
    return (
      <MeasurementsConfirmDialog
        orderId={data.id} referenceNumber={data.reference_number}
        date={data.confirm_date} estimateDate={data.estimate_date}
      />
    );
  }

  const buttonLabel = isUncontrolled
    ? <div>{formatToDate(data.estimate_date)}</div>
    : <div>{formatToDateUtc(data.confirm_date)}<br />{formatToTimeUtc(data.confirm_date)}</div>

  return (
    <MyDialog
      buttonLabel={buttonLabel}
      color={isUncontrolled ? "secondary" : "primary"}
      customColor={isUncontrolled ? "greenBorder" : ""}
      variant={isInfo || !isUncontrolled ? "contained" : "outlined"}
      dialogTitle={data.reference_number}
      onSubmit={onSubmit}
      onSubmitClose
      onSubmitDisabled={!allStepsCompleted}
      cancelButtonText="Close"
      modalSize="xl"
      minHeight={650}
      isStepper={true}
    >
      {({ onClose }: any) => (
        <WizardStep
          orderId={data.id}
          stepList={setStepList}
          onStepsCompleted={onStepsCompleted}
          onUserIdChange={setUserId}
          onClose={onClose}
          getExtraData={getExtraData}
        />
      )}
    </MyDialog>
  );
}
