import { APIClient } from "./apiService";

const uploadImageServer = async (file: FileUploadServer, fileSettings: FileUploadSettings) => {
  await APIClient.post(`/files`, { file, fileSettings });
};

const getSignedUrls = async (key: string) => await APIClient.post("/files/upload-path", { key });
const uploadToAWS = async (url: string, file: File) => {
  try {
    await fetch(url, { method: "PUT", body: file });
  } catch (err) {
  }
};

const uploadImageAws = async ({ key, fileContent }: FileType, fileSettings: FileUploadSettings): Promise<any> => {
  const signedUrls = await getSignedUrls(key);

  await uploadToAWS(signedUrls.data.urls, fileContent);
  await uploadImageServer({ name: fileContent.name, key: key }, fileSettings);
  return {
    name: fileContent.name,
    key: key,
    file_type: fileSettings.type
  };
};

export const uploadFiles = async (files: FileType[], fileSettings: FileUploadSettings) => {
  const promises: Promise<FileUploadServer>[] = [];
  files.forEach(file => {
    promises.push(uploadImageAws(file, fileSettings));
  });

  return await Promise.all(promises);
};

