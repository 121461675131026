import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { SearchOrder } from '../components/SearchOrder/SearchOrder';
import { Navigation } from '../components/Navigation/Navigation';
import { withSearchContext } from '../components/Layout';

const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    height: '100%'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%'
  },
  columnRight: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  showSearch: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      left: 0,
      top: theme.mixins.toolbar.minHeight + theme.spacing(1),
      right: 0,
      bottom: 0,
      display: 'block',
      zIndex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.2)'
    },
  }
});

export class Dashboard extends Component {
  render() {
    const { classes, searchOpen } = this.props;

    const classNameRight = classnames({
      [classes.column]: true,
      [classes.columnRight]: true,
      [classes.showSearch]: searchOpen
    });

    return (
      <div className={classes.root}>
        <div className={classes.column}>
          <Navigation />
        </div>
        <div className={classNameRight}>
          <SearchOrder />
        </div>
      </div>
    );
  }
}

Dashboard = withStyles(styles)(withSearchContext(Dashboard));
