import { Button, Paper, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useCallback } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';

type EnforceRefreshType = {};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%'
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2)
  },
  text: {
    textAlign: 'center',
    marginBottom: theme.spacing(1)
  }
}));

export const EnforceRefresh: React.FunctionComponent<EnforceRefreshType> = ({ children }) => {
  const onClick = useCallback(() => {
    window.location.reload();
    return false;
  }, []);

  const requireRefresh = useSelector((state: GlobalStoreType) => state.configuration.requireRefresh);
  const styles = useStyles();

  if (requireRefresh) {
    return (
      <div className={styles.root}>
        <Paper className={styles.container}>
          <Typography variant="h4" className={styles.text}>
            Your connection was lost.
          </Typography>
          <Typography variant="h5" className={styles.text}>
            Click below to refresh.
          </Typography>
          <div className={styles.button}>
            <Button onClick={onClick} color="primary" variant="contained">
              Refresh
            </Button>
          </div>
        </Paper>
      </div>
    );
  }

  return <>{children}</>;
};
