export const SET_DELIVERY_DATE_SUCCESS = "SET_DELIVERY_DATE_SUCCESS";
export const SET_DELIVERY_METHOD_SUCCESS = "SET_DELIVERY_METHOD_SUCCESS";

export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";

export const GET_CONTACT_LIST_SUCCESS = "GET_CONTACT_LIST_SUCCESS";

export const GET_SELLER_TEAMS_SUCCESS = "GET_SELLER_TEAMS_SUCCESS";
export const SET_MEASURING_TEAMS_SUCCESS = "SET_MEASURING_TEAMS_SUCCESS";
export const SET_INSTALLATION_TEAMS_SUCCESS = "SET_INSTALLATION_TEAMS_SUCCESS";

export const GET_CUTOUTS_DETAILS_SUCCESS = "GET_CUTOUTS_DETAILS_SUCCESS";
