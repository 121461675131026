import * as React from 'react';
import { useDispatch } from "react-redux";
import { PageTitle } from '../../components/Page/PageTitle';
import { Avatar, Chip, Paper, Theme, TextField } from '@material-ui/core';
import HouseIcon from '@material-ui/icons/House';
import { makeStyles } from '@material-ui/styles';
import { useIsAdmin } from "../../containers/withIsAdmin";

import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

import { EventInformation } from './EventInformation';
import { getOptions, SelectOption, SelectFilter } from './EventFilterHelpers';
import { EventBadge } from './EventBadge';
import "./react-big-calendar_custom.css";

import moment from 'moment';

import { useSelector } from "react-redux";
import { selectCalendarEvents } from '../../selectors/PlateSelectors';

import { setDeliveryDate } from "../../redux/actions/ordersActions";
import { setPlateDeliveryDate } from "../../redux/actions/plateActions";
import TwoWeekView from "./TwoWeekView";

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    flex: '1'
  },
  filters: {
    display: 'flex',
    gap: '10px',
    marginBottom: theme.spacing(1)
  },
  calendar: {
    padding: '10px',
    marginTop: theme.spacing(2)
  }
}));

//@ts-ignore
const DragAndDropCalendar = withDragAndDrop<Plate>(Calendar);

const localizer = momentLocalizer(moment);

export function CalendarView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();

  const [filteredIds, setFilteredIds] = React.useState<number[]>([]);
  //@ts-ignore
  const calendarEvents: CalendarEvent[] = useSelector(selectCalendarEvents());

  const events = filteredIds.length
    ? calendarEvents.filter(event => filteredIds.includes(event.id))
    : calendarEvents;

  const optMaterials = getOptions(calendarEvents, 'name');
  const optStoneTypes = getOptions(calendarEvents, 'type');
  const optThickness = getOptions(calendarEvents, 'thickness');

  const [selectedMaterials, setSelectedMaterials] = React.useState<SelectOption[]>([]);
  const [selectedStoneTypes, setSelectedStoneTypes] = React.useState<SelectOption[]>([]);
  const [selectedThickness, setSelectedThickness] = React.useState<SelectOption[]>([]);

  const onChangeSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value.toLowerCase();

    if (!value) {
      return setFilteredIds([]);
    }

    const filteredPlates = calendarEvents.filter(event => 
      event.reference_number.includes(value) || 
      event.material.name.toLowerCase().includes(value) || 
      event.material.type.toLowerCase().includes(value)
    );
    const filteredIds = filteredPlates.length ? filteredPlates.map(p => p.id) : [0];
    setFilteredIds(filteredIds);
  };

  const handleEventMove = async ({ event, start, end }: any) => {
    if (event.order_id) {
      await dispatch(setPlateDeliveryDate(event.id, moment(start).format("YYYY-MM-DD")));
    } else {
      await dispatch(setDeliveryDate(event.id, moment(start).format("YYYY-MM-DD")));
    }

    calendarEvents.map(existingEvent => {
      return existingEvent.id == event.id
        ? { ...existingEvent, start, end }
        : existingEvent
    });
  };

  const eventStyleGetter = (event: any) => ({
    style: {
      backgroundColor: event.plates ? (event.isFinish ? '#207145' : '#3174ad') : '#ad6a31'
    }
  });

  React.useEffect(() => {
    const listMaterial = selectedMaterials.map(item => item.value);
    const listStoneType = selectedStoneTypes.map(item => item.value);
    const listThickness = selectedThickness.map(item => item.value);

    if (!(listMaterial.length || listStoneType.length || listThickness.length)) {
      setFilteredIds([]);
    } else {
      const filteredPlates = calendarEvents.filter((event: CalendarEvent) => (
        (!listMaterial.length || listMaterial.includes(event.material.name)) &&
        (!listStoneType.length || listStoneType.includes(event.material.type)) &&
        (!listThickness.length || listThickness.includes(event.material.thickness))
      ));

      const filteredIds = filteredPlates.length ? filteredPlates.map(p => p.id) : [0];

      setFilteredIds(filteredIds);
    }
  }, [selectedMaterials, selectedStoneTypes, selectedThickness]);

  return (
    <React.Fragment>
      <PageTitle title="Calendar" />
      <Paper className={classes.calendar}>
        <div className={classes.filters}>
          <SelectFilter label="Material" options={optMaterials} setSelected={setSelectedMaterials} />
          <SelectFilter label="Stone type" options={optStoneTypes} setSelected={setSelectedStoneTypes} />
          <SelectFilter label="Thickness" options={optThickness} setSelected={setSelectedThickness} isSmall={true} />
          <TextField label="Search" onChange={onChangeSearch} style={{ flex: 2 }} />
        </div>
        <DragAndDropCalendar
          // @ts-ignore
          views={{
            day: true,
            week: true,
            // @ts-ignore
            work_week: TwoWeekView,
            month: true,
          }}
          events={events}
          messages={{ work_week: "Two-Week" }}
          localizer={localizer}
          onEventDrop={handleEventMove}
          draggableAccessor={() => isAdmin}
          eventPropGetter={eventStyleGetter}
          showAllEvents={true}
          components={{
            event: EventComponent
          }}
        />
      </Paper>
    </React.Fragment>
  );
}

const EventComponent = ({ event }: any) => {
  const [isOpen, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <React.Fragment>
      <div onClick={handleOpen}>
        <strong>{event.title}:</strong>
        <span>&ensp;{event.material.name} - {event.material.type} {event.material.thickness}</span>
      </div>
      <div className="event-types">
        {event.types && event.types.map((type: PlateTypes) => (
          <div key={type.id} className={"event-chip"}>
            {type.glue_sink && (
              <HouseIcon
                style={{ color: type.in_stock ? "green" : "red", verticalAlign: "middle" }}
                stroke={"white"}
                cstroke-width={1}
              />)}
            <Chip
              size="small"
              color={type.in_stock ? "primary" : "secondary"}
              key={type.plate_type}
              label={type.plate_type}
              avatar={<Avatar>{type.count}</Avatar>}
            />
          </div>
        ))}
      </div>
      <div>
        {event.plates?.length && event.plates.map((plate: Plate) => (
          <EventBadge key={plate.id} plate={plate} />
        ))}
      </div>
      <EventInformation
        isOpen={isOpen}
        handleClose={handleClose}
        event={event}
      />
    </React.Fragment>
  );
};
