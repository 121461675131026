import { APIClient } from '../../services/apiService';
import * as ordersConstants from '../constants/ordersConstants';
import { LOG_HISTORY_UPDATE } from '../orders/OrderTypes';

export const moveOrderBackward = ({ order_id, user_id, actionType }) => async () =>
  await APIClient.put(`orders/backward`, { user_id, order_id, actionType });

export const estimateOrder = (id, data) => async (dispatch) => {
  await APIClient.put(`orders/${id}/estimate`, data);
};

export const confirmOrder = async (id, data) => {
  await APIClient.put(`orders/${id}/confirm`, data);
};

export const internalControlOrder = (id, data) => async () => {
  await APIClient.put(`orders/${id}/internal-control`, data);
};

export const prepareOrder = async (id, data) => {
  await APIClient.put(`orders/${id}/prepare`, data);
};

export const logHistoryUpdateAction = (payload) => ({ type: LOG_HISTORY_UPDATE, payload });

export const logWizardHistory = async (id, data) =>  {
  await APIClient.put(`orders/${id}/wizard-history`, data);
};

export const measureOrder = async (id, data) => {
  await APIClient.put(`orders/${id}/measure`, data);
};

export const installationDate = async (id, data) => {
  await APIClient.put(`orders/${id}/installationDate`, data);
};

export const completeOrder = async (id, data) => {
  await APIClient.post(`orders/${id}/complete`, data);
};

export const resetOrderDate = (id) => async () => {
  await APIClient.put(`orders/${id}/restore-date`);
};

export const cancelOrder = (id, data) => async (dispatch) => {
  await APIClient.post(`orders/${id}/cancel`, data);
};

export const deleteOrder = (id, data) => async (dispatch) => {
  await APIClient.post(`orders/${id}/delete`, data);
};

export const sendConfirmationDataInfo = (id, data) => async () => {
  await APIClient.post(`orders/${id}/confirmationDataInfo`, data);
};

const setDeliveryDateSuccess = (payload) => ({ type: ordersConstants.SET_DELIVERY_DATE_SUCCESS, payload });
export const setDeliveryDate = (id, date) => async (dispatch) => {
  try {
    await APIClient.put(`orders/${id}/delivery-date`, { date });
    dispatch(setDeliveryDateSuccess({ id, date }));
  } catch (error) {
  }
};

const setDeliveryMethodSuccess = (payload) => ({ type: ordersConstants.SET_DELIVERY_METHOD_SUCCESS, payload });
export const setDeliveryMethod = (id, method) => async (dispatch) => {
  try {
    await APIClient.put(`orders/${id}/delivery-method`, { method });
    dispatch(setDeliveryMethodSuccess({ id, method }));
  } catch (error) {
  }
};

const getInvoicesSuccess = (payload) => ({ type: ordersConstants.GET_INVOICES_SUCCESS, payload });
export const getInvoices = (offerId) => async (dispatch) => {
  try {
    dispatch(getInvoicesSuccess(false));
    const response = await APIClient.get(`orders/${offerId}/invoices`);
    dispatch(getInvoicesSuccess(response.data));
  } catch (error) {
  }
};

const getSellerTeamsSuccess = (payload) => ({ type: ordersConstants.GET_SELLER_TEAMS_SUCCESS, payload });
export const getSellerTeams = () => async (dispatch) => {
  try {
    dispatch(getSellerTeamsSuccess(false));
    const response = await APIClient.get(`orders/sellerTeams`);
    dispatch(getSellerTeamsSuccess(response.data));
  } catch (error) {
  }
};

const setMeasuringTeamsSuccess = (payload) => ({ type: ordersConstants.SET_MEASURING_TEAMS_SUCCESS, payload });
export const setMeasuringTeams = (teams) => async (dispatch) => {
  try {
    dispatch(setMeasuringTeamsSuccess(teams));
  } catch (error) {
  }
};

const setInstallationTeamsSuccess = (payload) => ({ type: ordersConstants.SET_INSTALLATION_TEAMS_SUCCESS, payload });
export const setInstallationTeams = (teams) => async (dispatch) => {
  try {
    dispatch(setInstallationTeamsSuccess(teams));
  } catch (error) {
  }
};

const getContactListSuccess = (payload) => ({ type: ordersConstants.GET_CONTACT_LIST_SUCCESS, payload });
export const getContactList = (offerId) => async (dispatch) => {
  try {
    dispatch(getContactListSuccess(false));
    const response = await APIClient.get(`orders/${offerId}/contactList`);
    dispatch(getContactListSuccess(response.data));
  } catch (error) {
  }
};

const getCutoutsDetailsSuccess = (payload) => ({ type: ordersConstants.GET_CUTOUTS_DETAILS_SUCCESS, payload });
export const getCutoutsDetails = (offerId) => async (dispatch) => {
  try {
    dispatch(getCutoutsDetailsSuccess(false));
    const response = await APIClient.get(`orders/${offerId}/cutoutsDetails`);
    dispatch(getCutoutsDetailsSuccess(response.data));
  } catch (error) {
  }
};

export const syncCutoutDetails = (offerId, data, accountEmail) => async () => {
  try {
    await APIClient.post(`orders/${offerId}/cutoutDetails`, { data, accountEmail });
  } catch (error) {
  }
};

