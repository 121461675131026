import React from 'react';
import { Typography, Paper, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const linkToListStyles = theme => ({
  container: {
    display: 'flex',
    textDecoration: 'none',
    transition: 'transform 0.1s linear',
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(1.5),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      textAlign: 'center',
      flex: '1 1 40%',
      '&:hover': {
        transform: 'scale(1.1)'
      }
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      order: 2
    }
  },
  root: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    padding: theme.spacing(2),
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5),
      flexDirection: 'row',
      justifyContent: 'left'
    },
  },
  label: {
    overflowWrap: 'anywhere',
    [theme.breakpoints.down('sm')]: {
      order: 1
    }
  },
  count: {
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1)
    }
  },
  mergedRow: {
    gridRow: 'span 3',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      gridRow: ''
    },
    '& h4, h6': {
      [theme.breakpoints.down('xs')]: {
        fontWeight: 'bold',
      }
    }
  },
  mergedCol: {
    gridColumn: 'span 2',
    [theme.breakpoints.down('xs')]: {
      gridColumn: ''
    }
  },
  moveToEnd: {
    [theme.breakpoints.down('xs')]: {
      order: 1
    }
  }
});

const _LinkToList = ({ link, label, count = null, mode = null, toEnd = false, classes }) => (
  <Link to={link} className={`${classes.container} ${mode ? classes[mode] : ''} ${toEnd || classes.moveToEnd}`}>
    <Paper className={classes.root}>
      <Typography variant="h6" className={classes.label}>
        {label}
      </Typography>
      <Typography variant="h4" className={classes.count}>
        {count}
      </Typography>
    </Paper>
  </Link>
);


export const LinkToList = withStyles(linkToListStyles)(_LinkToList);
