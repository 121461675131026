import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Theme } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/styles';

import { useIsAdmin } from '../../containers/withIsAdmin';
import { setFaultByPlate } from '../../redux/actions/plateActions';
import { PreventPropagation } from '../PreventPropagation';

type DataTableCostCellType = {
  faultId: number,
  faultBy: number
}

const useStyles = makeStyles((theme: Theme) => ({
  select: { padding: theme.spacing(1, 2) },
}));

export const DataTableFaultByCell: React.FunctionComponent<DataTableCostCellType> = ({ faultId, faultBy }: DataTableCostCellType) => {
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const styles = useStyles();

  const users = useSelector((state: GlobalStoreType) => Object.keys(state.configuration.users).map((key: any) => state.configuration.users[key]));

  if (!isAdmin) {
    return <React.Fragment>{users.find(user => user.id === faultBy)?.name}</React.Fragment>;
  }

  const onChange = (evt: React.ChangeEvent<OnChangeMaterial>) => {
    dispatch(setFaultByPlate(faultId, evt.target.value));
  };

  return (
    <PreventPropagation>
      <FormControl variant="outlined" fullWidth>
        <Select
          value={faultBy || ''}
          onChange={onChange}
          variant="outlined"
          fullWidth
          input={
            <OutlinedInput
              labelWidth={0}
              name="fault-by-method"
              id="fault-by-method-input"
              classes={{ input: styles.select }}
            />
          }
        >
          {users.map(({ id, name }: User) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </PreventPropagation>
  );
};
