import * as React from 'react'
import { Row } from "../Common/Row";
import { Button, DialogActions, TextField, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

type Props = {
  index: number;
  value: string;
  isMultiple?: boolean;
  isMultiline?: boolean;
  onChange: (value: string, index: number) => void;
  onDelete: (index: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  row: { justifyContent: 'space-between', marginBottom: `${theme.spacing(1)} !important` },
  button: { flex: 1, display: 'flex', alignSelf: 'flex-end', marginLeft: theme.spacing(2) }
}));

export const WizardStepStageTextRow: React.FC<React.PropsWithChildren<Props>> = ({
                                                                                   value,
                                                                                   isMultiple,
                                                                                   isMultiline,
                                                                                   onChange,
                                                                                   index,
                                                                                   onDelete,
                                                                                   children
                                                                                 }) => {
  const classes = useStyles();

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value, index);
  };

  const handleDelete = () => onDelete(index);

  return (
    <Row className={classes.row}>
      <TextField
        fullWidth
        label={isMultiple ? `Value #${index + 1}` : 'Value'}
        value={value}
        multiline={isMultiline}
        {...(isMultiline ? { minRows: 2 } : {})}
        onClick={handleClick}
        onChange={handleChange}
      />
      {children}
      {isMultiple && (
        <div className={classes.button}>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleDelete}>Remove</Button>
          </DialogActions>
        </div>
      )}
    </Row>
  )
}
