import * as React from 'react';
import { TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { MyDialog } from '../Dialog/MyDialog';
import { addUser } from '../../redux/AppConfigurationRedux';
import { Row } from '../Common/Row';

type ManageUsersAddType = {}

export const ManageUsersAdd: React.FunctionComponent<ManageUsersAddType> = () => {
  const dispatch = useDispatch();

  const [userId, setUserId] = React.useState('');
  const [name, setName] = React.useState('');

  const onChangeId = (evt: React.ChangeEvent<OnChangeMaterial>) => setUserId(evt.target.value as string);
  const onChangeName = (evt: React.ChangeEvent<OnChangeMaterial>) => setName(evt.target.value as string);

  const onSubmit = async () => {
    dispatch(await addUser({ userId, name }));
  };

  return (
    <MyDialog
      dialogTitle="Add User"
      size="medium"
      color="primary"
      buttonLabel="Add User"
      modalSize="sm"
      variant="outlined"
      onSubmit={onSubmit}
    >
      <div>
        <Row>
          <TextField
            value={userId}
            onChange={onChangeId}
            label="ID"
            fullWidth
          />
        </Row>
        <Row>
          <TextField
            value={name}
            onChange={onChangeName}
            label="Name"
            fullWidth
          />
        </Row>
      </div>
    </MyDialog>
  );
};

