import * as React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { FileUploadTableRow } from './FileUploadTableRow';
import { useIsAdmin } from '../../containers/withIsAdmin';

type FileUploadTableType = {
  files: (FileType & FileUploadServer)[],
  isDrawing?: boolean,
  withActions?: boolean
};

const useStyles = makeStyles((theme: Theme) => ({
  mobileColumn: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  }
}));

export function FileUploadTable({ files, isDrawing, withActions }: FileUploadTableType) {
  const classes = useStyles();
  const isAdmin = useIsAdmin();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" />
          <TableCell align="right" padding="checkbox" style={{ width: 80 }}>User</TableCell>
          <TableCell align="right" padding="checkbox" style={{ width: 140 }} className={classes.mobileColumn}>
            Date
          </TableCell>
          {withActions && (
            <React.Fragment>
              {isAdmin && isDrawing && (
                <TableCell align="center" padding="checkbox" style={{ width: 40 }} className={classes.mobileColumn}>
                  Move
                </TableCell>
              )}
              <TableCell align="right" padding="checkbox" style={{ width: 60 }} className={classes.mobileColumn} />
            </React.Fragment>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {files.map((file, index) => (
          <FileUploadTableRow key={index} file={file} isDrawing={isDrawing} withActions={withActions} />
        ))}
      </TableBody>
    </Table>
  );
}

FileUploadTable.defaultProps = {
  withActions: true
};
