import * as React from "react";

type LockOrientationType = {
  isDesktopDevice: boolean,
  setIsDesktopDevice: (value: boolean) => void,
  isMediumDevice: boolean,
  setIsMediumDevice: (value: boolean) => void,
}

export const LockOrientationContext = React.createContext<LockOrientationType>({
  isDesktopDevice: false,
  isMediumDevice: false,
  setIsDesktopDevice: () => {
  },
  setIsMediumDevice: () => {
  },
});
