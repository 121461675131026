import * as HistoryTypes from './HistoryTypes';
import { HistoryActionTypes } from './HistoryTypes';

const initialState: HistoryStoreType = {
  entities: {},
  list: []
};


export function historyReducer(state = initialState, action: HistoryActionTypes): HistoryStoreType {
  switch (action.type) {
    case HistoryTypes.FETCH_HISTORY:
      return {
        ...state,
        entities: action.history.entities.history,
        list: action.history.result
      };

    default:
      return state;
  }
}
