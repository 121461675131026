import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, IconButton, Menu } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';

import { fetchNotifications } from '../../redux/notifications/NotificationActions';
import { NotificationElement } from './NotificationElement';
import { NotificationMarkAll } from './NotificationMarkAll';
import dayjs from 'dayjs';

type NotificationsType = {}

export const Notifications: React.FunctionComponent<NotificationsType> = () => {
  const dispatch = useDispatch();

  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const notifications = useSelector((state: GlobalStoreType) => state.notifications.list
    .map(element => state.notifications.entities[element])
    .filter((element) => dayjs(element.created_at).isAfter(dayjs(state.notifications.lastNotificationDate!))));

  React.useEffect(() => {
    dispatch(fetchNotifications());
  }, []);

  return (
    <React.Fragment>
      <IconButton
        aria-label="Notifications"
        onClick={handleClick}
        color={anchorElement ? 'secondary' : 'inherit'}
      >
        <Badge badgeContent={notifications.length} color="secondary">
          <MailIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleClose}
      >
        <NotificationMarkAll onClickHandler={handleClose} />
        {notifications.map(element => <NotificationElement key={element.id} notification={element} />)}
      </Menu>
    </React.Fragment>
  );
};
