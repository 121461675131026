import * as React from "react";

export function useUserInput() {
  const [user, setUser] = React.useState<number | undefined>(undefined);
  const [userError, setUserError] = React.useState(false);

  const onUserChange = (evt: any) => {
    setUserError(false);
    setUser(evt.target.value);
  };

  const resetUser = () => {
    setUserError(false);
    setUser(0);
  };

  return { user: user as number, userError, onUserChange, setUserError, resetUser };
}
