import { APIClient } from '../../services/apiService';
import * as plateConstants from '../constants/plateConstants';

export const proceedToNextStage = (id, { user_id, checkboxes, current_stage }) => async () => {
  await APIClient.put(`plates/${id}`, { user_id, checkboxes, current_stage });
};

export const movePlateBackward = ({ plate_id, order_id, user_id, current_stage }) => async () => await APIClient.put(`plates/backward`, {
  user_id,
  plate_id,
  order_id,
  current_stage
});

export const proceedToNextStageEndControl = (id, { user_id, checkboxes, current_stage }) => async () => {
  const result = await APIClient.put(`plates/${id}/end-control`, { user_id, checkboxes, current_stage });
  return result.data;
};

export const proceedToNextStageOrder = (id, { user_id, checkboxes, current_stage }) => async () => {
  await APIClient.put(`plates/${id}/all`, { user_id, checkboxes, current_stage });
};

export const faultPlate = ({ id, userId, reason, comment, moveToFind }) => async () => {
  await APIClient.post(`plates/${id}/fault`, { user_id: userId, reason, comment, moveToFind });
};

export const updateFaultPlate = ({ id, userId, reason, commentId, commentText }) => async () => {
  await APIClient.put(`plates/${id}/fault`, { user_id: userId, reason, commentId, commentText });
};

const setDeliveryDateSuccess = (payload) => ({ type: plateConstants.SET_PLATE_DELIVERY_DATE_SUCCESS, payload });
export const setPlateDeliveryDate = (id, date) => async (dispatch) => {
  try {
    await APIClient.put(`plates/${id}/delivery-date`, { date });
    dispatch(setDeliveryDateSuccess({ id, date }));
  } catch (error) {
  }
};

const setDeliveryMethodSuccess = (payload) => ({ type: plateConstants.SET_PLATE_DELIVERY_METHOD_SUCCESS, payload });
export const setPlateDeliveryMethod = (id, method) => async (dispatch) => {
  try {
    await APIClient.put(`plates/${id}/delivery-method`, { method });
    dispatch(setDeliveryMethodSuccess({ id, method }));
  } catch (error) {
  }
};

export const setFaultCostPlate = (id, cost) => async () => {
  await APIClient.put(`plates/${id}/cost`, { cost });
};

export const setFaultByPlate = (id, faultBy) => async () => {
  await APIClient.put(`plates/${id}/faultBy`, { faultBy });
};
