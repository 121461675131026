import { combineReducers } from "redux";

import { orderReducer as orders } from '../orders/OrderReducer';
import { plateReducer as plates } from '../plates/PlateReducer';
import { reducer as dictionary } from './dictionaryReducer';
import { reducer as account } from './accountReducer';
import { reducer as invoice } from './invoiceReducer';
import { reducer as confirmation } from './confirmationReducer';
import { reducer as sellerTeams } from './sellerTeamsReducer';
import { reducer as cutoutsDetails } from './cutoutsReducer';
import { commentReducer as comments } from '../comments/CommentReducer';
import { historyReducer as history } from '../history/HistoryReducer';
import { materialReducer as materials } from '../materials/MaterialReducer';
import { plateTypeReducer as plateTypes } from '../plateTypes/PlateTypeReducer';
import { fileReducer as files } from '../files/FileReducer';
import { notificationReducer as notifications } from '../notifications/NotificationReducer';
import { configurationReducer as configuration } from '../AppConfigurationRedux';

export const rootReducer = combineReducers({
  orders,
  plates,
  plateTypes,
  dictionary,
  account,
  invoice,
  confirmation,
  sellerTeams,
  comments,
  materials,
  history,
  files,
  notifications,
  configuration,
  cutoutsDetails,
});

