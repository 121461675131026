import * as React from 'react';
import { Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { deleteComment } from '../../redux/comments/CommentActions';
import { MyDialog } from '../Dialog/MyDialog';
import { useDispatch } from 'react-redux';
import { useIsAdmin, useIsProduction } from '../../containers/withIsAdmin';

type DataTableInformationCommentDeleteType = {
  comment: CommentType
};

export function DataTableInformationCommentDelete({ comment }: DataTableInformationCommentDeleteType) {
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();
  const isProduction = useIsProduction();

  const onSubmit = async () => {
    dispatch(await deleteComment(comment));
  };

  if (!isAdmin && !isProduction) {
    return null;
  }

  return (
    <MyDialog
      dialogTitle="Test"
      icon={<DeleteIcon />}
      size="medium"
      color="secondary"
      modalSize="sm"
      submitButtonColor="secondary"
      submitButtonText="Delete"
      onSubmit={onSubmit}
    >
      <Typography variant="subtitle2">
        Are you sure that you want to delete Comment
        <Typography variant="subtitle2" style={{ fontWeight: 700 }}>{comment.comment_text}?</Typography>
      </Typography>
    </MyDialog>
  );
}
