import OnHoldFlowConstants from "../constants/OnHoldFlowConstants";
import { COMMENT_TYPE } from "../constants";

export const isFlowRelatedOnHold = (reason: any) => {
  const OnHoldFlowTypes = Object.values(OnHoldFlowConstants.TYPES);
  return OnHoldFlowTypes.some(e => e === reason)
};

export const getCommentText = (isDrawingNotReady: boolean, isAffectPrice: boolean) => {
  if (isDrawingNotReady || isAffectPrice) {
    if (isAffectPrice && isDrawingNotReady) {
      return OnHoldFlowConstants.TYPES.DRAWING_NOT_READY_AFFECT_PRICE;
    }

    if (isDrawingNotReady) {
      return OnHoldFlowConstants.TYPES.DRAWING_NOT_READY;
    }

    if (isAffectPrice) {
      return OnHoldFlowConstants.TYPES.AFFECT_PRICE
    }
  }

  return undefined;
}

export const getFlowCommentText = (comment: any) => {
  if (comment.comment_type === COMMENT_TYPE.ON_HOLD_FLOW) {
    return OnHoldFlowConstants.COMMENTS[comment.comment_text] || comment.comment_text;
  } else {
    return comment.comment_text;
  }
}