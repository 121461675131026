import { applyMiddleware, createStore, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'remote-redux-devtools';
import thunk from 'redux-thunk';

import { rootReducer } from './reducers';
import { getForEnv } from '../shared/getForEnv';

const persistConfig = {
  key: 'root',
  whitelist: ['account'],
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default (preloadedState = {}) => {
  const middlewareEnhancer = applyMiddleware(thunk);
  const enhancers = [middlewareEnhancer];
  const composedEnhancers = getForEnv(composeWithDevTools({ realtime: true, port: 8000 }), compose);

  const store = createStore(persistedReducer, preloadedState, composedEnhancers(...enhancers));
  const persistor = persistStore(store);
  return { store, persistor };
}

