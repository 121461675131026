import * as React from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import AttachFile from '@material-ui/icons/AttachFile';
import GetApp from '@material-ui/icons/GetApp';

import { useSelector, useDispatch } from 'react-redux';
import { getInvoices } from '../../redux/actions/ordersActions';

import { Button } from '../../components/Button';
import { PreventPropagation } from '../../components/PreventPropagation';

type DataTableInvoicedCellType = {
  referenceNumber: string,
  isInvoiced: boolean
}

export const DataTableInvoicedCell: React.FunctionComponent<DataTableInvoicedCellType> = ({ referenceNumber, isInvoiced }) => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = React.useState(false);

  const files = useSelector((state: GlobalStoreType) => state.invoice.files);

  const onOpen = async () => {
    dispatch(getInvoices(referenceNumber));
    setOpen(true);
  };

  if (!isInvoiced) return null;

  return <PreventPropagation>
    <a onClick={onOpen}>
      <AttachFile />
    </a>
    <PreventPropagation>
      <Dialog
        maxWidth="sm"
        open={isOpen}
        onClose={() => setOpen(false)}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Invoices</DialogTitle>
        <DialogContent>
          {!files && <strong>LOADING...</strong>}
          {files.length > 0 && (files.map((file: any) => 
            <div>
              <GetApp style={{verticalAlign: "middle", paddingRight: "5px"}}/>
              <a href={file.url} download={file.name} target="_blank">{file.name}</a>
            </div>
          ))}
          {files.length == 0 && <strong>NO FILES</strong>}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </PreventPropagation>
  </PreventPropagation>
};
