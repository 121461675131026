import React, { useState } from 'react';
import { groupPlatesTypes } from "../shared/plateHelper";

export function usePlateHoleHandler(cutouts: HoleDictionary[]) {
  const [dictionary, setDictionary] = useState(cutouts);
  const [platesNumber, onSetPlatesNumber] = useState(0);
  const [platesArray, onSetPlatesArray] = useState([] as SetPlate[]);

  const platesArrayGroup = React.useMemo(() => groupPlatesTypes(platesArray), [platesArray]);

  React.useEffect(() => {
    setDictionary(cutouts);
  }, [cutouts])

  const createPlates = (value: number): SetPlate[] => {
    const newPlates = [] as any;
    for (let i = 0; i < value; i++) {
      newPlates.push({
        id: i + 1,
        types: [],
        noEdgePolish: false,
        noManualFinish: false,
        edgePolishOnCnc: false
      });
    }

    return newPlates;
  };

  const onNoManualFinish = (id: number) => {
    const newArray = platesArray.map((element: SetPlate) => {
      if (element.id == id) {
        return {
          ...element,
          noManualFinish: !element.noManualFinish
        };
      }

      return element;
    });

    onSetPlatesArray(newArray);
  };

  const onNoEdgePolish = (id: number) => {
    const newArray = platesArray.map((element: SetPlate) => {
      if (element.id == id) {
        return {
          ...element,
          noEdgePolish: !element.noEdgePolish
        };
      }

      return element;
    });

    onSetPlatesArray(newArray);
  };

  const onEdgePolishOnCnc = (id: number) => {
    const newArray = platesArray.map((element: SetPlate) => {
      if (element.id == id) {
        return {
          ...element,
          edgePolishOnCnc: !element.edgePolishOnCnc
        };
      }

      return element;
    });

    onSetPlatesArray(newArray);
  };

  const onPlateNumberChange = (id: number, plateNumber: number) => {
    const newArray = platesArray.map((element: SetPlate) => {
      if (element.id == id) {
        return {
          ...element,
          plateNumber: plateNumber
        };
      }

      return element;
    });

    onSetPlatesArray(newArray);
  };

  const setPlates = (value: number) => {
    onSetPlatesNumber(value);
    onSetPlatesArray(createPlates(value));
  };

  const setMultiSelect = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = evt.target as any;
    const newArray = platesArray.map((element: SetPlate) => {
      if (element.id == name) {
        return {
          id: element.id,
          types: value.map((newValue: string) => {
            const oldValue = element.types.find(oldType => oldType.type === newValue);
            if (oldValue) {
              return oldValue;
            }

            return ({ type: newValue, count: 1 });
          }),
          noEdgePolish: element.noEdgePolish,
          noManualFinish: element.noManualFinish,
          edgePolishOnCnc: element.edgePolishOnCnc,
          plateNumber: element.plateNumber
        };
      }

      return element;
    });

    onSetPlatesArray(newArray);
  };

  const setTypeCount = (value: any) => {
    const handleValue = (value: string) => {
      const parsedValue = parseFloat(value).toString();
      let [big, small] = parsedValue.split(/[.,]/);

      if (parseInt(small) >= 5) {
        small = '5';
      } else {
        small = '0';
      }

      return parseFloat(`${big}.${small}}`);
    };

    const { plate, type, count } = value;
    const newArray = platesArray.map((element: SetPlate) => {
      if (element.id === plate.id) {
        return {
          id: element.id,
          types: element.types.map((currentType: any) => {
            if (currentType.type === type) {
              return { type: currentType.type, count: handleValue(count) };
            }

            return currentType;
          }),
          noEdgePolish: element.noEdgePolish,
          noManualFinish: element.noManualFinish,
          edgePolishOnCnc: element.edgePolishOnCnc,
          plateNumber: element.plateNumber
        };
      }

      return element;
    });

    onSetPlatesArray(newArray);
  };

  const platesHoleHandlerHasError = React.useMemo(() => {
    const array = dictionary || []
    return !array.every((cutout: any) => {
      const element = platesArrayGroup.find(element => element.type === cutout.key);
      return element && element.count === cutout.count;
    })
  }, [platesArrayGroup, dictionary])

  return {
    dictionary,
    platesNumber,
    platesArray,
    platesArrayGroup,
    platesHoleHandlerHasError,
    setPlates,
    setMultiSelect,
    onNoManualFinish,
    onNoEdgePolish,
    onEdgePolishOnCnc,
    onPlateNumberChange,
    setTypeCount
  };
}
