import * as orderConstants from "../constants/ordersConstants";

const INITIAL_STATE = {
  allMeasuringTeams: [],
  allInstallationTeams: [],
  selMeasuringTeams: [],
  selInstallationTeams: [],
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case orderConstants.GET_SELLER_TEAMS_SUCCESS:
      return {
        ...state,
        allMeasuringTeams: action.payload.measuring || [],
        allInstallationTeams: action.payload.installation || []
      };
    case orderConstants.SET_MEASURING_TEAMS_SUCCESS:
      return {
        ...state,
        selMeasuringTeams: action.payload || [],
      };
    case orderConstants.SET_INSTALLATION_TEAMS_SUCCESS:
      return {
        ...state,
        selInstallationTeams: action.payload || [],
      }
    default:
      return state;
  }
};
