import * as React from "react";
import { Table, TableBody } from "@material-ui/core";
import dayjs from "dayjs";

import { DataTableInformationComment } from "./DataTableInformationComment";
import { DataTableInformationCommentHeader } from "./DataTableInformationCommentHeader";

type DataTableInformationTableCommentsType = {
  orderId?: number,
  plateId?: number,
  comments: CommentType[],
  withAddedTo?: boolean,
  withStatus?: boolean,
  withDelete?: boolean,
  withIsSeparately?: boolean
};

export function DataTableInformationTableComments({ withStatus, withDelete, withAddedTo, withIsSeparately, comments, plateId, orderId}: DataTableInformationTableCommentsType) {
  return (
    <Table>
      <DataTableInformationCommentHeader withAddedTo={withAddedTo} withDelete={withDelete} withStatus={withStatus} withIsSeparately={withIsSeparately} />
      <TableBody>
        {comments
          .sort((a, b) => dayjs(a.comment_date).isAfter(dayjs(b.comment_date)) ? -1 : 1)
          .map(comment => (
          <DataTableInformationComment
            key={comment.id}
            comment={comment}
            withStatus={withStatus}
            withDelete={withDelete}
            withAddedTo={withAddedTo}
            withIsSeparately={withIsSeparately}
            plateId={plateId}
            orderId={orderId}
          />
        ))}
      </TableBody>
    </Table>
  );
}
