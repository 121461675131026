import * as React from 'react';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import dayjs from 'dayjs';

import { PlateDateFormatter } from '../../containers/MetaDataTable/MetaDataGroupingTable';
import { HistoryTableCheckboxFormatter } from './HistoryTableFormatters';
import { HistoryTableColumns, HistoryTableColumnsWidth } from './HistoryTableColumns';

import { Cell } from '../../containers/MetaDataTable/MetaDataTableCell';

type HistoryTableType = {
  rows: HistoryMetaAction[]
}

export function HistoryTable({ rows }: HistoryTableType) {
  const historyRows = rows.sort((a, b) => dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? 1 : -1);

  let editableTimestamp = "";
  historyRows.forEach(row => {
    if (!editableTimestamp && row.step === 'MEASUREMENT') {
      editableTimestamp = row.created_at;
    }
    row.disabled = (editableTimestamp !== row.created_at);
  });

  return (
    <Grid
      rows={historyRows}
      columns={HistoryTableColumns}
    >
      <HistoryTableCheckboxFormatter for={['value']} />
      <PlateDateFormatter for={['created_at']} />
      <Table cellComponent={Cell} columnExtensions={HistoryTableColumnsWidth} />
      <TableHeaderRow />
    </Grid>
  );
}
