import * as React from "react";

import { COMMENT_TYPE } from "../../shared/constants";
import { DataTableInformationCommentHold } from "./DataTableInformationCommentHold";
import { DataTableInformationTableComments } from "./DataTableInformationTableComments";

type DataTableInformationHoldType = {
  files: FileType[],
  orderId: number,
  plateId?: number,
  comments: CommentType[],
  reference_number: string,
  noHoldOptions: boolean
}

export function DataTableInformationHold({ files, orderId, plateId, comments, reference_number, noHoldOptions }: DataTableInformationHoldType) {
  const currentOnHoldTypes = [COMMENT_TYPE.ON_HOLD, COMMENT_TYPE.ON_HOLD_FLOW];
  const currentOnHoldComment = comments
    .find(comment => comment.comment_status && currentOnHoldTypes.includes(comment.comment_type as any)) 
    || { comment_status: false, comment_text: "" };

  return (
    <React.Fragment>
      <DataTableInformationCommentHold
        files={files}
        plateId={plateId}
        orderId={orderId}
        isDisabled={currentOnHoldComment.comment_status}
        reference_number={reference_number}
        withHoldOptions={!plateId && !noHoldOptions}
      />
      <DataTableInformationTableComments comments={comments} withStatus withIsSeparately={!!plateId} plateId={plateId} orderId={orderId}/>
    </React.Fragment>
  );
}
