import { NormalizedSchema } from 'normalizr';

export const FETCH_HISTORY = 'FETCH_HISTORY';
export const FETCH_HISTORY_BY_PLATE = 'FETCH_HISTORY_BY_PLATE';
export const FETCH_HISTORY_BY_ORDER = 'FETCH_HISTORY_BY_ORDER';

export type FetchHistoryAction = { readonly type: typeof FETCH_HISTORY, history: NormalizedSchema<HistoryEntity, number[]> }
export type FetchHistoryPlateAction = { readonly type: typeof FETCH_HISTORY_BY_PLATE, id: number, history: HistoryType[] }
export type FetchHistoryOrderAction = { readonly type: typeof FETCH_HISTORY_BY_ORDER, id: number, history: HistoryType[] }

export type HistoryActionTypes =
  FetchHistoryAction | FetchHistoryPlateAction | FetchHistoryOrderAction
