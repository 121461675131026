import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, FormControlLabel, TextField, Theme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/styles';
import { ErrorMessage } from '../../components/Common/ErrorMessage';

import { MyDialog } from '../../components/Dialog/MyDialog';
import { MyDialogCheckboxes } from '../../components/Dialog/MyDialogCheckboxes';
import { MyDialogUser } from '../../components/Dialog/MyDialogUser';
import { FileUploader } from '../../components/FileUploader';
import { FileUploadTable } from '../../components/FileUploadTable/FileUploadTable';
import { useCheckpointsHandler } from '../../hooks/useCheckpointsHandler';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { useFileUploader } from '../../hooks/useFileUploader';
import { useUserInput } from '../../hooks/useUserInput';
import { completeOrder } from '../../redux/actions/ordersActions';

import { fetchFilesForRecord } from '../../redux/files/FileActions';
import { InstallationPlateCheckbox } from './InstallationPlateCheckbox';
import { FileDropzone } from '../../components/Common/FileDropzone';

type InstallationCompleteOrderType = {
  reference_number: string,
  orderId: number,
  plates: Plate[],
  isInfo: boolean
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1
  },
  picturesTable: {
    flex: 1
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  comment: {
    marginTop: theme.spacing(1)
  },
  description: {
    marginBottom: theme.spacing(2)
  }
}));

export function InstallationCompleteOrder({
                                            reference_number,
                                            orderId,
                                            isInfo,
                                            plates
                                          }: InstallationCompleteOrderType) {
  const classes = useStyles();

  const checkpoints = useSelector((state: GlobalStoreType) => state.dictionary.installationCheckpoints);
  const [siliconColor, setSiliconColor] = React.useState('');
  const files = useSelector((state: GlobalStoreType) => state.orders.entities[orderId].files) || [];
  const dispatch = useDispatch();

  const { checkpointsState, handleCheckpointChange, getPreparedCheckpoints } = useCheckpointsHandler(checkpoints);
  const { onUserChange, user } = useUserInput();
  const { error, handleError, resetError, isUserError } = useErrorHandler();

  const [comment, setComment] = React.useState('');
  const [backToCustomer, setBackToCustomer] = React.useState(false);
  const [platesChecked, onChangePlatesChecked] = React.useState({} as any);
  const [damageOnKitchen, setDamageOnKitchen] = React.useState(false);
  const [damageDescription, setDamageDescription] = React.useState('');

  const isPutSilicon = checkpointsState.some(state => state == "Put silicon");

  const onChangeCheckbox = (value: number) => onChangePlatesChecked({
    ...platesChecked,
    [value]: !platesChecked[value]
  });

  const { filesState, handleSetFilesAndSend, resetState } = useFileUploader({
    prependName: `INSTALLATION-ORDER-${reference_number}`,
    type: 'PICTURE',
    orderId: orderId,
    userId: user,
    isPlate: false
  });

  const onSubmit = async () => {
    try {
      await completeOrder(orderId, {
        user_id: user,
        comment: comment,
        checkboxes: getPreparedCheckpoints(),
        plates: Object.keys(platesChecked).filter(key => platesChecked[key]),
        backToCustomer: backToCustomer,
        siliconColor: siliconColor,
        damageOnKitchen: damageOnKitchen,
        damageDescription: damageDescription
      });
    } catch (err) {
      handleError(err);
      throw err;
    }
  };

  const onChangeSiliconColor = (e: React.ChangeEvent<HTMLInputElement>) => setSiliconColor(e.target.value);

  const onChangeComment = (e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value);

  const onChangeDamageDescription = (e: React.ChangeEvent<HTMLInputElement>) => setDamageDescription(e.target.value);

  const onUpload = async (evt: HTMLInputEvent) => {
    try {
      await handleSetFilesAndSend(evt);
      resetState();
    } catch (err) {
      handleError(err);
      throw err;
    }
  };

  const completedFiles = files.filter((file: FileType) => file.key.includes('INSTALLATION'));

  React.useEffect(() => {
    resetError();
  }, [user]);

  React.useEffect(() => {
    dispatch(fetchFilesForRecord(orderId));
  }, []);

  return (
    <MyDialog
      buttonLabel="Complete"
      dialogTitle="Complete"
      onSubmit={onSubmit}
      onSubmitClose
      icon={<CheckIcon />}
      color="primary"
      customColor={isInfo ? 'orange' : ''}
    >
      <div className={classes.root}>
        <MyDialogCheckboxes
          checkpoints={checkpoints}
          selected={checkpointsState}
          onChange={handleCheckpointChange}
        />
        {isPutSilicon && (
          <TextField 
            value={siliconColor} 
            onChange={onChangeSiliconColor}
            label="Silicon color"
          />
        )}
        <InstallationPlateCheckbox plates={plates} onChangeCheckbox={onChangeCheckbox} isChecked={platesChecked} />
        <MyDialogUser onChange={onUserChange} userError={isUserError} user={user} />
        <ErrorMessage message={error} isVisible={!!error} />
        <FileDropzone onUpload={onUpload}>
          <TextField
            value={comment}
            onChange={onChangeComment}
            multiline
            rows={2}
            label="Comment (VISIBLE FOR KITCHENSTUDIO)"
            className={classes.comment}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={backToCustomer}
                onChange={() => setBackToCustomer(!backToCustomer)}
                color="primary"
              />
            }
            label="Go Back To Customer"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={damageOnKitchen}
                onChange={() => setDamageOnKitchen(!damageOnKitchen)}
                color="primary"
              />
            }
            label="Damage on kitchen"
          />
          {damageOnKitchen && (
            <TextField
              value={damageDescription}
              onChange={onChangeDamageDescription}
              label="Damage description"
              className={classes.description}
            />
          )}
          <div className={classes.picturesTable}>
            <FileUploadTable files={[...completedFiles, ...filesState] as (FileUploadServer & FileType)[]} />
          </div>
          <div className={classes.button}>
            <FileUploader addFiles={onUpload} />
          </div>
        </FileDropzone>
      </div>
    </MyDialog>
  );
}
