import { Dispatch } from 'redux';

import { APIClient } from '../../services/apiService';

import * as NotificationTypes from './NotificationTypes';
import { normalize, NormalizedSchema, schema } from 'normalizr';

type NotificationObj = {
  last_notification_id: number,
  updated_at: Date
}

export const fetchNotifications = () => async (dispatch: Dispatch) => {
  const response = await APIClient.get(`notifications`);

  const notificationSchema = new schema.Entity('notification');
  const notificationsSchema = new schema.Array(notificationSchema);
  const normalizedData = normalize<'notification', NotificationEntity, number[]>(response.data.notifications, notificationsSchema);

  dispatch(fetchNotificationsSuccess(normalizedData, response.data.lastNotificationId || {
    last_notification_id: 0,
    updated_at: null
  }));
};

export const setLastNotificationId = (notificationId: number) => async (dispatch: Dispatch) => {
  const response = await APIClient.post(`notifications`, { notificationId });

  dispatch(setLastNotificationSuccess(response.data));
};

const setLastNotificationSuccess = (lastNotification: NotificationObj): NotificationTypes.NotificationActionTypes => ({
  type: NotificationTypes.SET_LAST_NOTIFICATION,
  lastNotificationId: lastNotification.last_notification_id,
  lastNotificationDate: lastNotification.updated_at
});

const fetchNotificationsSuccess = (notifications: NormalizedSchema<NotificationEntity, number[]>, lastNotification: NotificationObj): NotificationTypes.NotificationActionTypes => ({
  type: NotificationTypes.FETCH_NOTIFICATIONS,
  notifications,
  lastNotificationId: lastNotification.last_notification_id,
  lastNotificationDate: lastNotification.updated_at
});

export const fetchNotificationBySocket = (notification: NotificationType): NotificationTypes.NotificationActionTypes => ({
  type: NotificationTypes.FETCH_NOTIFICATION_BY_SOCKET,
  notification
});

