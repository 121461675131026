import * as React from 'react'
import { Button, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

type Props = { isSelected: boolean; onClick: (e: any) => void; }

const useStyles = makeStyles((theme: Theme) => ({
  selected: {},
  notSelected: { backgroundColor: theme.palette.primary.dark + "66" }
}));

export const WizardStepStageBool: React.FC<React.PropsWithChildren<Props>> = ({ isSelected, children, onClick }) => {
  const styles = useStyles();

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick(e)
  };

  return (
    <Button variant="contained" className={isSelected ? styles.selected : styles.notSelected} color="primary"
            onClick={handleOnClick}>
      {children}
    </Button>
  );
}
