import * as React from "react";
import { DialogActions, PropTypes } from "@material-ui/core";
import { Button } from "../Button";

type MyDialogActionsType = {
  onCancel(): void,
  onPress(): void,
  cancelButtonText?: string,
  onSubmitEnabled?: boolean,
  onSubmitDisabled?: boolean,
  submitButtonText?: string,
  submitButtonColor?: PropTypes.Color
}

export const MyDialogActions = ({
                                  onCancel,
                                  onPress,
                                  cancelButtonText,
                                  onSubmitEnabled,
                                  onSubmitDisabled,
                                  submitButtonText,
                                  submitButtonColor
                                }: MyDialogActionsType) => (
  <DialogActions>
    <Button onClick={onCancel} color="secondary">
      {cancelButtonText}
    </Button>
    {onSubmitEnabled && (
      <Button variant="contained" onClick={onPress} color={submitButtonColor} disabled={onSubmitDisabled}>
        {submitButtonText}
      </Button>
    )}
  </DialogActions>
);

MyDialogActions.defaultProps = {
  cancelButtonText: "Cancel",
  onSubmitEnabled: true,
  submitButtonColor: "primary",
  submitButtonText: "Submit"
};
