import * as accountConstants from "../constants/accountConstants";
import { APIClient } from "../../services/apiService";

const loginSuccess = (payload) => ({ type: accountConstants.LOGIN_SUCCESS, payload });
export const login = ({ username, password }) => async (dispatch) => {
  const response = await APIClient.post("/account/login", { username, password });
  dispatch(loginSuccess(response.data));
};

export const logout = () => ({ type: accountConstants.LOGOUT });
