import * as PlateTypeTypes from './PlateTypeTypes';
import { PlateTypeActionTypes } from './PlateTypeTypes';

const initialState: PlateTypeStoreType = {
  entities: {},
  list: []
};


export function plateTypeReducer(state = initialState, action: PlateTypeActionTypes): PlateTypeStoreType {
  switch (action.type) {
    case PlateTypeTypes.FETCH_PLATE_TYPES:
      return {
        ...state,
        entities: action.plateTypes
      };

    case PlateTypeTypes.FETCH_PLATE_TYPES_BY_SOCKET:
      return {
        ...state,
        entities: {
          ...Object.keys(state.entities).map((key: any) => {
            if(state.entities[key].id === action.plateTypes.id) {
              state.entities[key].in_stock = action.plateTypes.in_stock;
              state.entities[key].plate_type = action.plateTypes.plate_type;
            }
            return state.entities[key]})
        }
      };

    default:
      return state;
  }
}
