import * as React from 'react';
import { Typography } from '@material-ui/core';
import Select from 'react-select';

export const getOptions = (events: CalendarEvent[], columnName: string) => {
  const materialAll = events.map((event: any) => event.material[columnName]);
  const materialUnique = [ ...new Set(materialAll)];
  const materialSort = materialUnique.sort((a, b) => a > b ? 1 : -1);
  return materialSort.map(name => ({value: name, label: name}));
};

export class SelectOption {
  value: string;
  label: string;

  constructor(_value: string, _label: string) {
    this.value = _value;
    this.label = _label;
  }
}

type SelectFilterType = {
	label: string,
	setSelected: (arr: any[]) => void,
	options: any[]
	isSmall?: boolean
};

export function SelectFilter({ label, options, setSelected, isSmall }: SelectFilterType) {
  return (
		<div style={{ flex: isSmall ? 1 : 2, zIndex: 5 }}>
			<Typography variant="caption">{label}</Typography>
			<Typography variant="body2">
				<Select
					options={options} 
					onChange={(selected) => setSelected([...selected])}
					isMulti 
					/>
			</Typography>
		</div>	
	);	
}
