import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MetaDataTable } from '../MetaDataTable/MetaDataTable';

import { fetchDeviations } from '../../redux/comments/CommentActions';
import { fetchCompletedOrders } from '../../redux/orders/OrderActions';
import { COMMENT_TYPE } from '../../shared/constants';
import dayjs from 'dayjs';

export function DeviationOrdersTable() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchDeviations());
    dispatch(fetchCompletedOrders());
  }, []);

  const deviations: any[] = useSelector((state: GlobalStoreType) => state.comments.list
    .map(key => state.comments.entities[key])
    .filter(comment => comment.comment_type === COMMENT_TYPE.DEVIATION)
    .map(comment => ({
      id: comment.plate_id,
      order_id: comment.order_id,
      created_at: state.orders.entities[comment.order_id]?.created_at,
      order_reference_number: state.orders.entities[comment.order_id]?.reference_number,
      reference_number: state.plates.entities[comment.plate_id]?.reference_number,
      comment: comment.comment_text,
      user: state.configuration.users[comment.comment_user]?.name
    }))
    .sort((a, b) => dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? 1 : -1));

  return (
    <MetaDataTable
      title="Deviations"
      actionType="deviations"
      rows={deviations}
    />
  );
}

