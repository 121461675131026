export const groupPlatesTypes = (platesArray) => {
  const reducedArray = platesArray.map(pa => pa.types).reduce((a, b) => [...a, ...b], []);
  return groupTypes(reducedArray);
};

export const groupTypes = (reducedArray, property = "type") => {
  const newData = [];
  reducedArray.forEach(function (item) {
    let keyElement = newData.find(nd => nd[property] === item[property]);
    if (keyElement) {
      keyElement.count += item.count;
    } else {
      newData.push({ ...item });
    }
  });

  return newData;
};
