import { useState } from 'react';

export function useCheckpointsHandler(checkpoints: CheckpointType[]) {
  const INITIAL_STATE: string[] = [];
  const [checkpointsState, setCheckpointsState] = useState(INITIAL_STATE);

  function handleCheckpointChange(checkpoint: CheckpointType): void {
    setCheckpointsState(checkpointsState.includes(checkpoint.value) ? [
      ...checkpointsState.filter((element: string) => element !== checkpoint.value),
    ] : [
      ...checkpointsState.filter((element: string) => element !== checkpoint.value),
      checkpoint.value
    ]);
  }

  function getPreparedCheckpoints() {
    return checkpoints.map((cs: CheckpointType) => ({
      key: cs.value,
      value: checkpointsState.includes(cs.value),
      extraValue: ""
    }));
  }

  function resetState() {
    setCheckpointsState([]);
  }

  return { handleCheckpointChange, getPreparedCheckpoints, checkpointsState, resetState };
}
