import * as React from "react";

type PreventPropagationType = {
  children: JSX.Element | JSX.Element[]
};

export function PreventPropagation({ children }: PreventPropagationType) {
  return (
    <div onClick={(evt: React.MouseEvent<HTMLDivElement>) => evt.stopPropagation()}>
      {children}
    </div>
  );
}
