import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '../Button';
import { Theme } from '@material-ui/core';

type DataTableInformationTableCommentsType = {
  filters: string[],
  filter: string[],
  onChange: (value: string) => () => void
};

const useStyles = makeStyles((theme: Theme) => ({
  filter: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  button: {
    marginRight: theme.spacing(1)
  }
}));

export function DataTableInformationTableFilter({ filter, filters, onChange }: DataTableInformationTableCommentsType) {
  const classes = useStyles();

  return (
    <div className={classes.filter}>
      {filters.map((value, index) => (
        <Button
          key={index}
          className={classes.button}
          onClick={onChange(value)}
          variant={filter.includes(value) ? 'outlined' : 'contained'}
          color={filter.includes(value) ? 'primary' : 'default'}
        >
          {value}
        </Button>
      ))}
    </div>
  );
}
