import { APIClient } from '../../services/apiService';

export const insertRegularComment = ({ orderId, plateId, comment_text, comment_for, user_id }: CommentAction & RegularCommentAction) => async () => {
  await APIClient.post(`comments/${orderId}/regular`, { comment_text, comment_for, user_id, plate_id: plateId });
};

export const insertInfoComment = ({ orderId, plateId, comment_text, user_id }: CommentAction) => async () => {
  await APIClient.post(`comments/${orderId}/info`, { comment_text, user_id, plate_id: plateId });
};

export const insertDeviationComment = ({ orderId, plateId, comment_text, user_id }: CommentAction) => async () => {
  await APIClient.post(`comments/${orderId}/deviation`, { comment_text, user_id, plate_id: plateId });
};

export const insertOnHoldComment = ({ orderId, plateId, comment_text, user_id, is_separately, hold_option, extra_info, is_send_to_customer, files_info }: CommentAction) => async () => {
  const url = plateId ? `plates/${plateId}/on-hold` : `orders/${orderId}/on-hold`;
  await APIClient.post(url, { comment_text, user_id, order_id: orderId, is_separately, hold_option, extra_info, is_send_to_customer, files_info });
};

export const insertOnHoldOffComment = ({ orderId, plateId, comment_text, user_id, comment_id }: CommentAction) => async () => {
  const url = plateId ? `plates/${plateId}/off-hold` : `orders/${orderId}/off-hold`;
  await APIClient.post(url, { comment_text, user_id, comment_id, order_id: orderId });
};

export const setOld = (comment: CommentType) => async () => {
  await APIClient.put(`/comments/old`, comment);
};
