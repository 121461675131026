import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";

export const useStylesTable = makeStyles(() => ({
  onHoldAnimation: {
    animation: "$onHoldAnimation 1s ease-in infinite alternate"
  },
  [`@keyframes onHoldAnimation`]: {
    from: {
      backgroundColor: "transparent"
    },
    to: {
      backgroundColor: red[100]
    },
  },
  tooltip: {
    marginTop: -20
  }
}));
