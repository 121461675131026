import { StageType } from "./WizardTypes";

type Dependencies = { [key: string]: Array<string> };

function buildStepDependencies(stages: Array<StageType>, stepId: string, currentValues: Record<string, any>) {
  const dependencies: Dependencies = {};
  stages.forEach((stage) => {
    if (stage.isTrue !== undefined && stage.isTrue !== null) {
      dependencies[stage.id] = dependencies[stage.id] || [];
      dependencies[stage.id].push(stage.isTrue);
    }

    if (stage.isFalse !== undefined && stage.isFalse !== null) {
      dependencies[stage.id] = dependencies[stage.id] || [];
      dependencies[stage.id].push(typeof stage.isFalse === "function" ? stage.isFalse(currentValues, stepId) : stage.isFalse);
    }

    if (stage.isConfirm !== undefined && stage.isConfirm !== null) {
      dependencies[stage.id] = dependencies[stage.id] || [];
      dependencies[stage.id].push(stage.isConfirm);
    }
  });

  return dependencies;
}

function getImpactedSteps(stepId: string, dependencies: Dependencies, impactedSteps = new Set<string>()) {
  const nextSteps: Array<string> = dependencies[stepId as keyof typeof dependencies];
  if (nextSteps) {
    nextSteps.forEach((nextStepId: string) => {
      if (!impactedSteps.has(nextStepId)) {
        impactedSteps.add(nextStepId);
        getImpactedSteps(nextStepId, dependencies, impactedSteps);
      }
    });
  }

  return Array.from(impactedSteps);
}

export function getValueKey(stepId: string, stageId: string) {
  return `${stepId}_${stageId}`;
}

type WizardObjectProps = {
  stages: Array<StageType>,
  currentStageId: string,
  currentStepId: string,
  currentValues: Record<string, any>,
  newValue: any,
  oldValue: any
}

export function prepareWizardObject(props: WizardObjectProps): StatusDictionary {
  const { stages, currentStageId, currentStepId, currentValues, oldValue, newValue } = props;
  if (Array.isArray(newValue) || typeof newValue === 'string' || oldValue === newValue) {
    return currentValues;
  }

  const stepDependencies = buildStepDependencies(stages || [], currentStepId, currentValues);
  const impactedSteps = getImpactedSteps(currentStageId, stepDependencies);

  impactedSteps.forEach((key) => {
    const objectKey = getValueKey(currentStepId, key)
    if (currentValues[objectKey] !== undefined) {
      delete currentValues[objectKey];
    }
  });

  return currentValues;
}
