import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Theme } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FileUploadTable } from "../FileUploadTable/FileUploadTable";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(1)
  },
  table: {
    margin: "0 1px",
    overflowX: "hidden"
  }
}));

type DataTAbleInformationDrawingOldType = {
  files: (FileUploadServer & FileType)[]
}

export function DataTableInformationDrawingOld({ files }: DataTAbleInformationDrawingOldType) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ExpansionPanel className={classes.table}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography variant="h6">Old Drawings</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <FileUploadTable
            isDrawing
            files={files}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
