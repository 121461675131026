import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';

import { useIsAdmin } from '../../containers/withIsAdmin';
import { useUserInput } from '../../hooks/useUserInput';
import { resetOrderDate } from '../../redux/actions/ordersActions';
import { MyDialog } from '../Dialog/MyDialog';
import { MyDialogUser } from '../Dialog/MyDialogUser';
import { ErrorMessage } from '../Common/ErrorMessage';
import { useErrorHandler } from '../../hooks/useErrorHandler';

type DataTableInformationDeleteType = {
  orderId: number,
  referenceNumber: string,
  callback: () => void
}

export function DataTableInformationRestoreDate({ orderId, referenceNumber, callback }: DataTableInformationDeleteType) {
  const isAdmin = useIsAdmin();

  const dispatch = useDispatch();
  const { onUserChange, user } = useUserInput();
  const { error, handleError, isUserError, resetError } = useErrorHandler();

  React.useEffect(() => {
    resetError();
  }, [user]);

  if (!isAdmin) {
    return null;
  }

  const onSubmit = async () => {
    try {
      await dispatch(resetOrderDate(orderId));
      callback();
    } catch (err) {
      // @ts-ignore
      handleError(err);
      throw err;
    }
  };

  return (
    <div style={{ marginLeft: 8, marginRight: 8 }}>
      <MyDialog
        dialogTitle={`Do you want to restore date on order ${referenceNumber}?`}
        size="medium"
        color="secondary"
        variant="text"
        buttonLabel="Restore Date"
        modalSize="sm"
        onSubmit={onSubmit}
        onSubmitClose
        submitButtonColor="secondary"
      >
        <div style={{ flex: 1 }}>
          <Typography variant="subtitle2">
            Are you sure you want to restore date from order app?
          </Typography>
          <MyDialogUser onChange={onUserChange} userError={isUserError} user={user} />
          <ErrorMessage message={error} isVisible={!!error} />
        </div>
      </MyDialog>
    </div>
  );
}
