import * as React from "react";
import { connect } from "react-redux";
import { Dialog, DialogActions, DialogContent, DialogTitle, Theme, withMobileDialog } from '@material-ui/core';
import { InjectedProps } from "@material-ui/core/withMobileDialog";
import { makeStyles } from "@material-ui/styles";

import CheckIcon from "@material-ui/icons/Check";
import { Button } from "../../components/Button";
import { ErrorMessage } from '../../components/Common/ErrorMessage';

import { MyDialog } from "../../components/Dialog/MyDialog";
import { MyDialogCheckboxes } from "../../components/Dialog/MyDialogCheckboxes";
import { MyDialogUser } from "../../components/Dialog/MyDialogUser";
import { PreventPropagation } from "../../components/PreventPropagation";
import { useCheckpointsHandler } from "../../hooks/useCheckpointsHandler";
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { useUserInput } from "../../hooks/useUserInput";
import {
  proceedToNextStage,
  proceedToNextStageOrder,
  proceedToNextStageEndControl
} from "../../redux/actions/plateActions";
import { PlateDeviation } from "./PlateDeviation";
import { PLATE_TYPE } from "../../shared/constants";

type NextStageParams = {
  user_id: number,
  checkboxes: any[],
  current_stage: string
}

type PlateProceedDialogType = {
  data: Plate,
  checkpoints: any[],
  isInfo: boolean,
  isOnHold: boolean,
  hasSubmitAll: boolean,
  proceedToNextStage: (plateId: number, params: NextStageParams) => void,
  proceedToNextStageOrder: (orderId: number, params: NextStageParams) => void,
  proceedToNextStageEndControl: (plateId: number, params: NextStageParams) => any
};


const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    margin: 0
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: 200,
    flex: 1
  },
  table: {
    flex: 1,
    overflowY: "auto"
  },
  leftButtons: {
    display: "flex",
    flex: 1
  },
  deviation: {
    display: "flex",
    marginTop: theme.spacing(2),
    justifyContent: "center"
  }
}));

function PlateProceedDialog({ data, checkpoints, isInfo, isOnHold, proceedToNextStage, proceedToNextStageOrder, proceedToNextStageEndControl, hasSubmitAll }: PlateProceedDialogType & InjectedProps) {
  const classes = useStyles();
  const { onUserChange, user, resetUser } = useUserInput();
  const { error, handleError, resetError, isUserError } = useErrorHandler();

  const { checkpointsState, getPreparedCheckpoints, handleCheckpointChange, resetState } = useCheckpointsHandler(addExtraCheckpoints(data));
  const [isOpen, setOpen] = React.useState(false);
  const [isOpenEndControl, setOpenEndControl] = React.useState(false);

  function getKeyText(plateType: string) {
    if (plateType.includes("VASK"))
      return plateType + " with pipes is packed";
    else if (plateType.includes("KOKETOPP") || plateType.includes("EL-KONTAKT"))
      return plateType + " with manuals is packed";
    else if (plateType.includes("RUNDE-HULL"))
      return plateType + " equipments is packed";
    else
      return plateType + " is packed";
  }
  
  function addExtraCheckpoints(currentPlate: Plate) {
    if (currentPlate.current_stage === PLATE_TYPE.PACKING && currentPlate.types?.length) {
      const extra = currentPlate.types
        .filter(item => item.in_stock)
        .map(item => {
          const keyText = getKeyText(item.plate_type);
          return ({
           text: keyText,
           value: keyText
          })
        });
      return [...checkpoints, ...extra];
    } else {
      return checkpoints;
    }
  }

  async function onSubmitFind() {
    setOpen(true);
  }

  function resetLocalState() {
    resetState();
    resetUser();
    setOpen(false);
  }

  async function onSubmit() {
    try {
      await proceedToNextStage(data.id, {
        user_id: user,
        checkboxes: getPreparedCheckpoints(),
        current_stage: data.current_stage
      });

      resetLocalState();
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  async function onSubmitAll() {
    try {
      await proceedToNextStageOrder(data.order_id, {
        user_id: user,
        checkboxes: getPreparedCheckpoints(),
        current_stage: data.current_stage
      });

      resetLocalState();
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  async function onSubmitEndControl() {
    try {
      const { isUserSame } = await proceedToNextStageEndControl(data.id, {
        user_id: user,
        checkboxes: getPreparedCheckpoints(),
        current_stage: data.current_stage
      });

      if (isUserSame) {
        setOpenEndControl(true);
      } else {
        resetLocalState();
      }
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  function getFunction() {
    if (data.current_stage === PLATE_TYPE.END_CONTROL) {
      return onSubmitEndControl;
    }

    return hasSubmitAll ? onSubmitFind : onSubmit;
  }

  React.useEffect(() => {
    resetError();
  }, [user]);

  if (isOnHold) {
    return null;
  }

  return (
    <React.Fragment>
      <PreventPropagation>
        <MyDialog
          buttonLabel="Proceed"
          dialogTitle="Proceed"
          onSubmit={getFunction()}
          onSubmitClose
          icon={<CheckIcon />}
          color="primary"
          customColor={isInfo ? "orange" : ""}
        >
          <div className={classes.root}>
            <MyDialogCheckboxes
              needImpregnation={data.material?.need_impregnation}
              checkpoints={addExtraCheckpoints(data)}
              selected={checkpointsState}
              onChange={handleCheckpointChange}
            />
            <div>
              <MyDialogUser onChange={onUserChange} userError={isUserError} user={user} />
              <ErrorMessage message={error} isVisible={!!error} />

              <div className={classes.deviation}>
                <MyDialog
                  buttonLabel="Deviation"
                  dialogTitle="Deviation"
                >
                  <PlateDeviation
                    id={data.id}
                    order_id={data.order_id}
                    reference_number={data.reference_number}
                    files={data.files}
                    comments={data.comments}
                  />
                </MyDialog>
              </div>
            </div>
          </div>
        </MyDialog>
      </PreventPropagation>
      <PreventPropagation>
        <Dialog
          maxWidth="sm"
          open={isOpen}
          onClose={() => setOpen(false)}
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          classes={{ paper: classes.modal }}
        >
          <DialogTitle id="form-dialog-title">Confirm All?</DialogTitle>
          <DialogContent>
            <div>
              Do you want to confirm all plates within that order?
            </div>
          </DialogContent>
          <DialogActions>
            <div className={classes.leftButtons}>
              <Button color="default" onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </div>
            <Button variant="contained" color="secondary" onClick={onSubmitAll}>
              Confirm all plates
            </Button>

            <Button variant="contained" color="primary" onClick={onSubmit}>
              Confirm only this
            </Button>
          </DialogActions>
        </Dialog>
      </PreventPropagation>
      <PreventPropagation>
        <Dialog
          maxWidth="sm"
          open={isOpenEndControl}
          onClose={() => setOpenEndControl(false)}
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          classes={{ paper: classes.modal }}
        >
          <DialogTitle id="form-dialog-title">Confirm All?</DialogTitle>
          <DialogContent>
            <div>
              You already did the manual finish on this piece. Another worker should do the end-control. Are you sure you want to continue?
            </div>
          </DialogContent>
          <DialogActions>
            <div className={classes.leftButtons}>
              <Button color="default" onClick={() => setOpenEndControl(false)}>
                Cancel
              </Button>
            </div>
            <Button variant="contained" color="primary" onClick={onSubmit}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </PreventPropagation>
    </React.Fragment>
  );
}

PlateProceedDialog.defaultProps = {
  isInfo: false,
  hasSubmitAll: false
};

const connected = connect(null, {
  proceedToNextStage,
  proceedToNextStageOrder,
  proceedToNextStageEndControl
})(withMobileDialog<PlateProceedDialogType>()(PlateProceedDialog));
export { connected as PlateProceedDialog };
