import * as React from "react";

import { PlateDeliveryDateInline } from "../../containers/PlatesTableOrders/PlateDeliveryDateInline";
import { useIsAdmin } from "../../containers/withIsAdmin";
import { formatToDate } from '../../services/DateFormatter';

type DataTableDateType = {
  orderId: number,
  delivery_date: string,
  plateId?: number,
  small?: boolean,
  disable?: boolean
};

export function DataTableDate({ delivery_date, plateId, orderId, small, disable }: DataTableDateType) {
  const isAdmin = useIsAdmin();

  if (isAdmin && !disable) {
    return <PlateDeliveryDateInline plateId={plateId} orderId={orderId} date={delivery_date} small={small} />;
  }

  if (!delivery_date) {
    return null
  }

  return (
    <React.Fragment>
      {formatToDate(delivery_date)}
    </React.Fragment>
  );
}
