import { Dispatch } from 'redux';
import { normalize, NormalizedSchema, schema } from 'normalizr';

import * as OrderTypes from './OrderTypes';
import { OrderActionTypes } from './OrderTypes';

import { APIClient } from '../../services/apiService';

const orderSchema = new schema.Entity('order');
const orderInfoSchema = new schema.Entity('order', {}, { idAttribute: 'offerId' });
const ordersSchema = new schema.Array(orderSchema);
const ordersInfoSchema = new schema.Array(orderInfoSchema);

const fetchNewOrdersSuccess = (orders: NormalizedSchema<OrderEntity, number[]>): OrderActionTypes => ({
  type: OrderTypes.FETCH_NEW_ORDERS,
  orders
});
const fetchExistingOrdersSuccess = (orders: NormalizedSchema<OrderEntity, number[]>): OrderActionTypes => ({
  type: OrderTypes.FETCH_EXISTING_ORDERS,
  orders
});
const fetchNewOrdersInfoSuccess = (orders: NormalizedSchema<OrderEntity, number[]>): OrderActionTypes => ({
  type: OrderTypes.FETCH_NEW_ORDERS_INFO,
  orders
});

const defaultMaterial = (element: Order): Order => ({
  ...element,
  material: { type: '', thickness: '', name: '', size: 0, need_impregnation: false },
  history: element.history || {}
});
export const fetchOrders = () => async (dispatch: Dispatch) => {
  const response = await APIClient.get(`orders`);
  const { newOrders, existingOrders, newOrdersInfo } = response.data;

  const normalizedNewOrders = normalize<'order', OrderEntity, number[]>(newOrders.map(defaultMaterial), ordersSchema);
  const normalizedExistingOrders = normalize<'order', OrderEntity, number[]>(existingOrders.map(defaultMaterial), ordersSchema);
  const normalizedNewOrdersInfo = normalize<'order', OrderEntity, number[]>(newOrdersInfo, ordersInfoSchema);

  dispatch(fetchNewOrdersSuccess(normalizedNewOrders));
  dispatch(fetchExistingOrdersSuccess(normalizedExistingOrders));
  dispatch(fetchNewOrdersInfoSuccess(normalizedNewOrdersInfo));
};

export const fetchCompletedOrders = () => async (dispatch: Dispatch) => {
  const response = await APIClient.get(`orders/completed`);
  const normalizedExistingOrders = normalize<'order', OrderEntity, number[]>(response.data.completed.map(defaultMaterial), ordersSchema);
  dispatch(fetchExistingOrdersSuccess(normalizedExistingOrders));
}

export const fetchOrderInfoById = (orderId: number) => async (dispatch: Dispatch) => {
  const response = await APIClient.get(`orders`);
  const order = response.data.newOrders.find((o: any) => o.id == orderId);
  
  if (order) {
    const orderInfo = response.data.newOrdersInfo.find((i: any) => i.offerId == order.reference_number);
    dispatch(fetchOrderInfoBySocket(orderInfo));
  }
};

export const fetchOrderBySocket = (order: Order) => ({
  type: OrderTypes.FETCH_ORDER_BY_SOCKET,
  order
});

export const fetchOrderInfoBySocket = (order: OrderInfo) => ({
  type: OrderTypes.FETCH_ORDER_INFO_BY_SOCKET,
  order
});

export const deleteOrderBySocket = (id: number) => ({
  type: OrderTypes.DELETE_ORDER_BY_SOCKET,
  id
})
