import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, FormControlLabel, TableCell, TableRow, Theme } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { COMMENT_TYPE } from "../../shared/constants";
import { getFlowCommentText } from '../../shared/helpers/onHoldFlowHelper';

import { setOld } from "../../redux/actions/commentActions";
import { DataTableInformationCommentDelete } from "./DataTableInformationCommentDelete";
import { DataTableInformationCommentHoldOff } from "./DataTableInformationCommentHoldOff";
import { formatToDateWithTime } from "../../services/DateFormatter";

type DataTableInformationCommentType = {
  orderId?: number,
  plateId?: number,
  comment: CommentType,
  withAddedTo?: boolean,
  withStatus?: boolean,
  withDelete?: boolean,
  withIsSeparately?: boolean
}

export function DataTableInformationComment({ comment, withStatus, withDelete, withAddedTo, withIsSeparately, plateId, orderId }: DataTableInformationCommentType) {
  const { id, comment_date, comment_text, plate_id } = comment;

  const dispatch = useDispatch();
  const user = useSelector((state: GlobalStoreType) => (typeof comment.comment_user === 'string') ? comment.comment_user : state.configuration.users[comment.comment_user]?.name);
  const plateReferenceNumber = useSelector((state: GlobalStoreType) => state.plates.entities[plate_id!]?.reference_number);

  const theme = useTheme<Theme>();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const confirmOld = () => dispatch(setOld(comment));

  return (
    <TableRow key={id} style={{ height: 45, padding: "40px" }}>
      {withStatus && (
        <TableCell style={{ width: "40px" }} padding="none">
          {[COMMENT_TYPE.ON_HOLD, COMMENT_TYPE.ON_HOLD_FLOW].includes(comment.comment_type as any) && (
            <DataTableInformationCommentHoldOff comment={comment} plateId={plateId} orderId={orderId} />
          )}
        </TableCell>
      )}
      <TableCell padding="checkbox" style={{ width: "400px", padding: "25px 0" }}>
        {getFlowCommentText(comment)}
        {comment.extra_info && (
          <div style={{ marginTop: "10px"}}>
            <div>Missing items:</div>
            <div style={{whiteSpace: "pre"}}>{comment.extra_info}</div>
          </div>
        )}
      </TableCell>
      {withIsSeparately && (
        <TableCell align="right" style={{ width: "60px" }} padding="checkbox">
          {[COMMENT_TYPE.ON_HOLD, COMMENT_TYPE.ON_HOLD_FLOW].includes(comment.comment_type as any) && (
            <FormControlLabel
              control={<Checkbox value="isSeparately" />}
              style={{ margin: 0 }}
              checked={comment.is_separately}
              label=""
              disabled={true}
            />
          )}
        </TableCell>
      )}
      <TableCell align="right" style={{ width: "80px" }} padding="checkbox">{user}</TableCell>
      {isDesktop && (
        <TableCell align="right" style={{ width: "100px" }} padding="checkbox">
          {formatToDateWithTime(comment_date)}
        </TableCell>
      )}
      {withAddedTo && isDesktop && (
        <TableCell align="right" padding="checkbox" style={{ width: "200px" }}>{comment.comment_for
          .replace("}", "")
          .replace("{", "")
          .replace(/,/g, "\n")
          .replace(/"/g, "")}
        </TableCell>
      )}
      <TableCell padding="checkbox" align="right" style={{ width: "60px" }}>{plateReferenceNumber}</TableCell>
      {!withStatus && (
        <TableCell padding="checkbox" align="right" style={{ width: 75 }}>
          {
            <FormControlLabel
              control={<Checkbox value="isOld" />}
              style={{ margin: 0 }}
              checked={comment.is_old}
              onChange={confirmOld}
              label=""
            />
          }
        </TableCell>
      )}
      {withDelete && isDesktop && (
        <TableCell align="right" style={{ width: "100px" }}>
          {<DataTableInformationCommentDelete comment={comment} />}
        </TableCell>
      )}
    </TableRow>
  );
}
