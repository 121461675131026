import * as dictionaryConstants from '../constants/dictionaryConstants';
import { APIClient } from '../../services/apiService';

const getDictionariesSuccess = (payload) => ({ type: dictionaryConstants.GET_DICTIONARIES_SUCCESS, payload });

export const getDictionaries = () => async (dispatch) => {
  try {
    const response = await APIClient.get(`dictionaries`);
    const external = await APIClient.get(`dictionaries/external`);

    const dictionaries = {
      ...response.data,
      delivery_method: external.data.delivery_methods,
      affect_price_options: external.data.affect_price_options
    };

    dispatch(getDictionariesSuccess(dictionaries));
  } catch (error) {
  }
};
