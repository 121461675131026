import { Dispatch } from 'redux';

import * as PlateTypeTypes from './PlateTypeTypes';
import { PlateTypeActionTypes } from './PlateTypeTypes';

import { APIClient } from '../../services/apiService';

const fetchPlateTypesSuccess = (plateTypes: PlateType[]): PlateTypeActionTypes => ({
  type: PlateTypeTypes.FETCH_PLATE_TYPES,
  plateTypes
});

export const fetchPlateTypes = () => async (dispatch: Dispatch) => {
  const response = await APIClient.get(`plate-types`);
  dispatch(fetchPlateTypesSuccess(response.data));
};

export const fetchPlateTypesBySocket = (plateTypes: PlateType) => ({
  type: PlateTypeTypes.FETCH_PLATE_TYPES_BY_SOCKET,
  plateTypes
});
