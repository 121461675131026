import * as React from 'react'
import { DialogActions, Theme } from "@material-ui/core";
import { FileDropzone } from "../Common/FileDropzone";
import { FileUploadTable } from "../FileUploadTable/FileUploadTable";
import { FileUploader } from "../FileUploader";
import { useFileUploader } from "../../hooks/useFileUploader";
import { makeStyles } from "@material-ui/styles";

type Props = {
  files: Array<FileType>;
  stepId?: string;
  fileType: string;
  orderId: number;
  userId: number;
  referenceNumber: string;
  label?: string;
}

const useClasses = makeStyles((theme: Theme) => ({
  confirm: {
    display: "flex",
    justifyContent: "end",
    marginRight: theme.spacing(1),
  },
  filesGallery: {
    display: "flex",
    overflowY: 'auto',
    justifyContent: 'space-between'
  },
  filesTable: {
    flex: '0 0 calc(50% - 2.5%)'
  },
}));


export const WizardStepStageFile: React.FC<Props> = ({ files, stepId, fileType, orderId, userId, referenceNumber, label }) => {
  const classes = useClasses();

  const measurementFiles = files.filter(e => e.key.includes('MEASUREMENTS'));
  const stageFiles = measurementFiles.filter(e => e.file_type === fileType);
  const { filesState, handleSetFilesAndSend, resetState } = useFileUploader({
    prependName: `MEASUREMENTS-ORDER-${referenceNumber}`,
    type: fileType,
    orderId: orderId,
    userId: userId,
    isPlate: false
  });


  const onUploadFile = async (evt: HTMLInputEvent) => {
    try {
      await handleSetFilesAndSend(evt);
      resetState();
    } catch (err) {
    }
  };

  const stageFilesA = stageFiles.filter((_file, index) => index % 2 === 0);
  const stageFilesB = stageFiles.filter((_file, index) => index % 2 === 1);

  const filesStateA = filesState.filter((_file, index) => index % 2 === 0);
  const filesStateB = filesState.filter((_file, index) => index % 2 === 1);

  const fileUploaderLabel = label || `Upload ${stepId || 'picture'}`;
  return (
      <FileDropzone onUpload={onUploadFile}>
        <div className={classes.filesGallery}>
          <div className={classes.filesTable}>
            <FileUploadTable
              files={[...stageFilesA, ...filesStateA] as (FileUploadServer & FileType)[]} />
          </div>
          <div className={classes.filesTable}>
            <FileUploadTable
              files={[...stageFilesB, ...filesStateB] as (FileUploadServer & FileType)[]} />
          </div>
        </div>
        <br />
        <div className={classes.confirm}>
          <FileUploader label={fileUploaderLabel} addFiles={onUploadFile} />
        </div>
      </FileDropzone>
  )
}
