import * as React from 'react';
import { DataTableInformationModal } from '../../components/DataTableInformation/DataTableInformationModal';

type EventInformationType = {
  isOpen: boolean,
  handleClose: () => void,
  event: any
};

export function EventInformation({ isOpen, event, handleClose }: EventInformationType) {
	if (!isOpen) {
    return null;
  }

	return (
    <DataTableInformationModal
      information={event}
      noMoveBackward={event.noMoveBackward}
      onCancel={handleClose}
    />
  );
}
