import { StepType } from "../../components/WizardStep/WizardStep";
import { STAGE, SPECIAL_COMPONENTS } from "./WizardEnums";

export enum PrepareSteps {
  SET_PLATES = "SET_PLATES",
  SEND_INFO = "SEND_INFO",
  DELIVERY_DATA = "DELIVERY_DATA"
}

export enum SendInfoStages {
  SEND_SMS_TO_CUSTOMER = "SEND_SMS_TO_CUSTOMER",
  SEND_EMAIL_TO_CUSTOMER = "SEND_EMAIL_TO_CUSTOMER"
}

export const PrepareWizardQuestions: StepType[] = [
  {
    id: PrepareSteps.SET_PLATES,
    label: "Set plates",
    stages: [],
    specialComponent: SPECIAL_COMPONENTS.SetPlateHoles,
  },
  {
    id: PrepareSteps.SEND_INFO,
    label: "Send info",
    stages: [
      {
        id: SendInfoStages.SEND_SMS_TO_CUSTOMER,
        type: STAGE.BOOL,
        label: "Send SMS to customer?",
        isTrue: SendInfoStages.SEND_EMAIL_TO_CUSTOMER,
        isFalse: SendInfoStages.SEND_EMAIL_TO_CUSTOMER
      },
      {
        id: SendInfoStages.SEND_EMAIL_TO_CUSTOMER,
        type: STAGE.BOOL,
        label: "Send email to customer?"
      },
    ]
  },
  {
    id: PrepareSteps.DELIVERY_DATA,
    label: "Delivery data",
    stages: [],
    specialComponent: SPECIAL_COMPONENTS.CheckMethodAndDate
  }
];
