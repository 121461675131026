import * as React from 'react';

import { AxiosResponse } from 'axios';

enum ERRORS {
  UNEXPECTED_ERROR = 'Something went wrong. Try to refresh page',
  USER_DOES_NOT_EXIST = 'User does not exist.',
  USER_IS_NOT_ADMIN = 'User has insufficient rights.',
  IS_ON_HOLD = 'Element is currently On Hold - it needs to be turned off to proceed.',
  INCORRECT_TYPES = 'You set incorrect plate types, please set them correctly.'
}

const errorHandler = (response: AxiosResponse) => {
  if (!response) {
    return { message: ERRORS.UNEXPECTED_ERROR };
  }

  switch (response.status) {
    case 400:
      if (response.data.isOnHold) {
        return { message: ERRORS.IS_ON_HOLD };
      }

      if (response.data.hasIncorrectTypes) {
        return { message: ERRORS.INCORRECT_TYPES };
      }

      return { message: ERRORS.UNEXPECTED_ERROR };

    case 401:
      return { message: ERRORS.USER_DOES_NOT_EXIST, isUserError: true };

    case 403:
      return { message: ERRORS.USER_IS_NOT_ADMIN, isUserError: true };

    default:
      return { message: ERRORS.UNEXPECTED_ERROR };
  }
};

export function useErrorHandler() {
  const [error, setError] = React.useState('');
  const [isUserError, setIsUserError] = React.useState(false);

  const resetError = () => {
    setError('');
    setIsUserError(false);
  };

  const handleError = ({ response }: { response: AxiosResponse }) => {
    const { message, isUserError = false } = errorHandler(response);
    setError(message);
    setIsUserError(isUserError);
  };

  return { error, isUserError, handleError, resetError };
}
