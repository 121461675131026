import { Dispatch } from 'redux';
import { normalize, NormalizedSchema, schema } from 'normalizr';

import * as HistoryTypes from './HistoryTypes';
import { HistoryActionTypes } from './HistoryTypes';

import { APIClient } from '../../services/apiService';

const fetchHistorySuccess = (history: NormalizedSchema<HistoryEntity, number[]>): HistoryActionTypes => ({ type: HistoryTypes.FETCH_HISTORY, history });
export const fetchHistory = () => async (dispatch: Dispatch) => {
  const response = await APIClient.get(`history`);
  const historySchema = new schema.Entity('history');
  const historiesSchema = new schema.Array(historySchema);
  const normalizedData = normalize<"history", HistoryEntity, number[]>(response.data, historiesSchema);

  dispatch(fetchHistorySuccess(normalizedData));
};

const fetchHistoryByPlate = (id: number, history: HistoryType[]): HistoryActionTypes => ({
  type: HistoryTypes.FETCH_HISTORY_BY_PLATE,
  id,
  history
});
const fetchHistoryByOrder = (id: number, history: HistoryType[]): HistoryActionTypes => ({
  type: HistoryTypes.FETCH_HISTORY_BY_ORDER,
  id,
  history
});
export const fetchHistoryForRecord = (orderId: number, plateId?: number) => async (dispatch: Dispatch) => {
  if (plateId) {
    const response = await APIClient.get(`history/order/${orderId}/plate/${plateId}`);
    dispatch(fetchHistoryByPlate(plateId, response.data));
    dispatch(fetchHistoryByOrder(orderId, response.data));
  } else {
    const response = await APIClient.get(`history/order/${orderId}`);
    dispatch(fetchHistoryByOrder(orderId, response.data));
  }
};

export const updateHistoryById = ( id: number, newValue: boolean, newKey?: string ) => async () => {
  await APIClient.put(`/history/${id}`, { newValue: newValue, newKey: newKey });
};
