import * as React from "react";

import { PlateDeliveryMethodInlineEdit } from "../../containers/PlatesTableOrders/PlateDeliveryMethodInlineEdit";
import { withIsAdmin } from "../../containers/withIsAdmin";

type DataTableDeliveryMethod = {
  orderId: number,
  plateId: number,
  delivery_method: string,
  small?: boolean
};

function DataTableDeliveryMethod({ orderId, plateId, delivery_method, isAdmin, small }: DataTableDeliveryMethod &  AccountStoreType) {
  if (isAdmin) {
    return (
      <PlateDeliveryMethodInlineEdit
        orderId={orderId}
        plateId={plateId}
        deliveryMethod={delivery_method}
        small={small}
      />
    );
  }

  return <React.Fragment>{delivery_method}</React.Fragment>;
}

const connected = withIsAdmin<DataTableDeliveryMethod>(DataTableDeliveryMethod);
export { connected as DataTableDeliveryMethod };
