import * as React from "react";

import { useSelector } from "react-redux";
import { PLATE_TYPE } from "../shared/constants";
import { MetaDataGroupingTable } from "./MetaDataTable/MetaDataGroupingTable";
import { selectPlateFullData } from '../selectors/PlateSelectors';
import { selectCheckpoints } from '../redux/selectors';

export function CutOrdersTable() {
  const plates: Plate[] = useSelector(selectPlateFullData(PLATE_TYPE.CUT));
  const checkpoints: CheckpointType[] = useSelector(selectCheckpoints("cutCheckpoints"));

  return (
    <MetaDataGroupingTable
      rows={plates}
      title="Cut"
      checkpoints={checkpoints}
    />
  );
}
