import * as React from "react";

import { COMMENT_TYPE } from "../../shared/constants";
import { DataTableInformationCommentAdd } from "./DataTableInformationCommentAdd";
import { DataTableInformationTableComments } from "./DataTableInformationTableComments";

type DataTableInformationInfoType = {
  orderId?: number,
  plateId?: number,
  comments: CommentType[]
}

export function DataTableInformationInfo({ orderId, plateId, comments }: DataTableInformationInfoType) {
  return (
    <React.Fragment>
      <DataTableInformationCommentAdd
        orderId={orderId}
        plateId={plateId}
        type={COMMENT_TYPE.INFO}
      />
      <DataTableInformationTableComments comments={comments}/>
    </React.Fragment>
  );
}
