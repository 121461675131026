import React from "react";
import { Typography } from "@material-ui/core";

type IProps = {
  message: string,
  isVisible: boolean
}

export function ErrorMessage({ message, isVisible }: IProps) {
  if (!isVisible) {
    return null;
  }

  return (
    <Typography variant="caption" color="error">
      {message}
    </Typography>
  );
}
