import * as React from 'react';
import { Button } from '../Button';

export const FileUploadTablePdf = ({ file }: { file: FileType & FileUploadServer }) => {
  return (
    <Button onClick={() => window.open(file.path, '_blank', 'fullscreen=yes')} color="secondary" variant="outlined">
      {file.name}
    </Button>
  );
};
