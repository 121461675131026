import { STAGE, SPECIAL_COMPONENTS } from "./WizardEnums";
import { StepType } from "./WizardTypes";
import { getValueKey } from "./wizardHelpers";
import { PRICE_FROM_OFFICE } from "../constants/OnHoldFlowConstants";

export enum MeasurementSteps {
  PICTURES = "PICTURES",
  DRAWING = "DRAWING",
  AGREEMENTS = "AGREEMENTS",
  LIFTING = "LIFTING_HELP",
  REGISTRATION_EQUIPMENTS = "REGISTRATION_EQUIPMENTS",
  KITCHEN_COMPLETED = "KITCHEN_COMPLETED",
  ENTRANCE = "ENTRANCE"
}

export enum PictureStages {
  CABINET_PICTURE_FILE = "CABINET_PICTURE_FILE",
  ENTRANCE_PATH_PICTURE_FILE = "ENTRANCE_PATH_PICTURE_FILE",
  REFERENCE_KITCHEN_PICTURE_FILE = "REFERENCE_KITCHEN_PICTURE_FILE",
  KITCHEN_OVERVIEW_PICTURE_FILE = "KITCHEN_OVERVIEW_PICTURE_FILE",
  COOKTOP_SINK_PICTURE_FILE = "COOKTOP_SINK_PICTURE_FILE",
  HEIGHT_FROM_FLOOR_PICTURE_FILE = "HEIGHT_FROM_FLOOR_PICTURE_FILE",
}

export enum DrawingStages {
  DRAWING_READY = "DRAWING_READY",
  MISSING_DETAILS = "MISSING_DETAILS",
  OVERHANG_COUNT = "OVERHANG_COUNT",
  UPLOAD_DRAWING = "UPLOAD_DRAWING",
}

export enum AgreementStages {
  DETAILS_AGREED_CUSTOMER = "DETAILS_AGREED_CUSTOMER",
  AGREED_DETAILS_NOT_SPECIFIED = "AGREED_DETAILS_NOT_SPECIFIED",
  DETAILS_AGREED_CRAFTSMEN = "DETAILS_AGREED_CRAFTSMEN",
  AGREED_DETAILS_CRAFTSMEN = "AGREED_DETAILS_CRAFTSMEN",
}

export enum LiftingHelpStages {
  ADDITIONAL_LIFTING_HELP = "ADDITIONAL_LIFTING_HELP",
  PEOPLE_NEEDED = "PEOPLE_NEEDED",
  INSTALLATION_TIME = "INSTALLATION_TIME",
  CRANE_NEED = "CRANE_NEED",
  CRANE_LENGTH = "CRANE_LENGTH",
  CRANE_STANDING_PLACE = "CRANE_STANDING_PLACE",
  STONE_FRAME_PLACEMENT = "STONE_FRAME_PLACEMENT",
  GLUE_SINK_IN_THE_HOUSE = "GLUE_SINK_IN_THE_HOUSE"
}

export enum KitchenCompletedStages {
  WALL_SUPPORT_CHECK = "WALL_SUPPORT_CHECK",
  WALL_SUPPORT_CHECK_NO_ANSWER = "WALL_SUPPORT_CHECK_NO_ANSWER",
  LEVEL_CHECK = "LEVEL_CHECK",
  LEVEL_CHECK_NO_ANSWER = "LEVEL_CHECK_NO_ANSWER",
  EXTRA_SUPPORT_CHECK = "EXTRA_SUPPORT_CHECK",
  EXTRA_SUPPORT_CHECK_YES_ANSWER = "EXTRA_SUPPORT_CHECK_YES_ANSWER",
  FINAL_PREPARATION_CHECK = "FINAL_PREPARATION_CHECK",
  FINAL_PREPARATION_CHECK_YES_ANSWER = "FINAL_PREPARATION_CHECK_YES_ANSWER",
  KITCHEN_IS_POINT_ABOVE = "KITCHEN_IS_POINT_ABOVE",
}

export enum EntranceStages {
  WHICH_FLOOR_KITCHEN = "WHICH_FLOOR_KITCHEN",
  WHERE_PLANNED_STONE = "WHERE_PLANNED_STONE",
}

export const MeasurementWizardQuestions: StepType[] = [
  {
    id: MeasurementSteps.PICTURES,
    label: "Pictures",
    stages: [
      {
        id: PictureStages.CABINET_PICTURE_FILE,
        type: STAGE.FILE,
        label: "Picture of each cabinet from 1m distance",
        isConfirm: PictureStages.ENTRANCE_PATH_PICTURE_FILE,
        alwaysShow: true
      },
      {
        id: PictureStages.ENTRANCE_PATH_PICTURE_FILE,
        type: STAGE.FILE,
        label: "Picture of entrance / walking path / frame-placement",
        isConfirm: PictureStages.REFERENCE_KITCHEN_PICTURE_FILE,
        alwaysShow: true
      },
      {
        id: PictureStages.REFERENCE_KITCHEN_PICTURE_FILE,
        type: STAGE.FILE,
        label: "Picture of reference on kitchen cabinets / wall plates",
        isConfirm: PictureStages.KITCHEN_OVERVIEW_PICTURE_FILE,
        alwaysShow: true
      },
      {
        id: PictureStages.KITCHEN_OVERVIEW_PICTURE_FILE,
        type: STAGE.FILE,
        label: "Overview picture of the kitchen",
        isConfirm: PictureStages.COOKTOP_SINK_PICTURE_FILE,
        alwaysShow: true
      },
      {
        id: PictureStages.COOKTOP_SINK_PICTURE_FILE,
        type: STAGE.FILE,
        label: "Picture of cooktop, sink, tap, el-contact that you find in house",
        isConfirm: PictureStages.HEIGHT_FROM_FLOOR_PICTURE_FILE,
        alwaysShow: true
      },
      {
        id: PictureStages.HEIGHT_FROM_FLOOR_PICTURE_FILE,
        type: STAGE.FILE,
        label: "Picture of height from ready floor until top cabinet (when sidepanels)",
        alwaysShow: true,
        uploadFilesOptional: () => true,
      },
    ]
  },
  {
    id: MeasurementSteps.DRAWING,
    label: "Drawing",
    required: true,
    stages: [
      {
        id: DrawingStages.DRAWING_READY,
        type: STAGE.BOOL,
        label: "Is the drawing ready to be send to production?",
        isTrue: DrawingStages.OVERHANG_COUNT,
        isFalse: DrawingStages.MISSING_DETAILS
      },
      {
        id: DrawingStages.MISSING_DETAILS,
        type: STAGE.TEXT,
        label: "Which information about solution/details do you miss?",
        isSubQuestion: true,
        multiple: true,
        multiline: true,
        isConfirm: DrawingStages.OVERHANG_COUNT
      },
      {
        id: DrawingStages.OVERHANG_COUNT,
        type: STAGE.TEXT,
        label: "Which overhang is counted?",
        multiline: true,
        isConfirm: DrawingStages.UPLOAD_DRAWING
      },
      {
        id: DrawingStages.UPLOAD_DRAWING,
        type: STAGE.FILE,
        label: "Upload drawing",
        isConfirm: null,
      },
    ]
  },
  {
    id: MeasurementSteps.AGREEMENTS,
    label: "Agreements",
    stages: [
      {
        id: AgreementStages.DETAILS_AGREED_CUSTOMER,
        type: STAGE.BOOL,
        label: "Did you agree any details with Customer, that's NOT specified in order?",
        isTrue: AgreementStages.AGREED_DETAILS_NOT_SPECIFIED,
        isFalse: AgreementStages.DETAILS_AGREED_CRAFTSMEN,
      },
      {
        id: AgreementStages.AGREED_DETAILS_NOT_SPECIFIED,
        type: STAGE.TEXT,
        label: "Which details were agreed?",
        multiple: true,
        multiline: true,
        affectPrice: true,
        multilineBooleanLabel: "Does the change affect the price?",
        isSubQuestion: true,
        uploadFiles: true,
        uploadFilesLabel: "Confirmation Picture",
        uploadFilesOptional,
        isConfirm: AgreementStages.DETAILS_AGREED_CRAFTSMEN,
      },
      {
        id: AgreementStages.DETAILS_AGREED_CRAFTSMEN,
        type: STAGE.BOOL,
        label: "Did you agree any details with other craftsmen?",
        isTrue: AgreementStages.AGREED_DETAILS_CRAFTSMEN,
        isFalse: null
      },
      {
        id: AgreementStages.AGREED_DETAILS_CRAFTSMEN,
        type: STAGE.TEXT,
        label: "Which details were agreed?",
        multiple: true,
        multiline: true,
        affectPrice: true,
        multilineBooleanLabel: "Does the change affect the price?",
        isSubQuestion: true,
        uploadFiles: true,
        uploadFilesLabel: "Confirmation Picture",
        uploadFilesOptional,
        isConfirm: null,
      },
    ]
  },
  {
    id: MeasurementSteps.LIFTING,
    label: "Installation",
    stages: [
      {
        id: LiftingHelpStages.ADDITIONAL_LIFTING_HELP,
        type: STAGE.BOOL,
        label: "Will you need more help to lift the plates than our 2 installers?",
        isTrue: LiftingHelpStages.PEOPLE_NEEDED,
        isFalse: LiftingHelpStages.INSTALLATION_TIME
      },
      {
        id: LiftingHelpStages.PEOPLE_NEEDED,
        type: STAGE.TEXT,
        label: "How many people do you need?",
        isConfirm: LiftingHelpStages.INSTALLATION_TIME,
      },
      {
        id: LiftingHelpStages.INSTALLATION_TIME,
        type: STAGE.TEXT,
        label: "What is the estimated installation time?",
        isConfirm: LiftingHelpStages.CRANE_NEED,
      },
      {
        id: LiftingHelpStages.CRANE_NEED,
        type: STAGE.BOOL,
        label: "Will you need crane to lift inside the plate at installation?",
        isTrue: LiftingHelpStages.CRANE_LENGTH,
        isFalse: LiftingHelpStages.GLUE_SINK_IN_THE_HOUSE
      },
      {
        id: LiftingHelpStages.CRANE_LENGTH,
        type: STAGE.TEXT,
        label: "How long crane do you need?",
        isConfirm: LiftingHelpStages.CRANE_STANDING_PLACE,
      },
      {
        id: LiftingHelpStages.CRANE_STANDING_PLACE,
        type: STAGE.TEXT,
        label: "Where have you planned the crane can stand?",
        isConfirm: LiftingHelpStages.STONE_FRAME_PLACEMENT,
        multiline: true,
        uploadFiles: true,
        uploadFilesLabel: "Upload",
      },
      {
        id: LiftingHelpStages.STONE_FRAME_PLACEMENT,
        type: STAGE.TEXT,
        label: "Where have you planned the stone frame can be putted?",
        isConfirm: LiftingHelpStages.GLUE_SINK_IN_THE_HOUSE,
        multiline: true,
        uploadFiles: true,
        uploadFilesLabel: "Upload",
      },
      {
        id: LiftingHelpStages.GLUE_SINK_IN_THE_HOUSE,
        type: STAGE.BOOL,
        label: "Glue sink in house?",
      }
    ]
  },
  {
    id: MeasurementSteps.REGISTRATION_EQUIPMENTS,
    label: "Registration equipments",
    required: true,
    stages: [],
    specialComponent: SPECIAL_COMPONENTS.CutoutsEdit,
  },
  {
    id: MeasurementSteps.KITCHEN_COMPLETED,
    label: "Kitchen completed?",
    stages: [
      {
        id: KitchenCompletedStages.WALL_SUPPORT_CHECK,
        type: STAGE.BOOL,
        label: "Is the support on the wall good enough to install our plates?",
        isTrue: KitchenCompletedStages.LEVEL_CHECK,
        isFalse: KitchenCompletedStages.WALL_SUPPORT_CHECK_NO_ANSWER
      },
      {
        id: KitchenCompletedStages.WALL_SUPPORT_CHECK_NO_ANSWER,
        type: STAGE.TEXT,
        label: "What need to be done before we can do the installation?",
        isConfirm: KitchenCompletedStages.LEVEL_CHECK,
        isSubQuestion: true,
        uploadFilesLabel: "Picture Support",
        uploadFiles: true,
        multiline: true,
        multiple: true,
      },
      {
        id: KitchenCompletedStages.LEVEL_CHECK,
        type: STAGE.BOOL,
        label: "Is the level good enough to install our plates?",
        isTrue: KitchenCompletedStages.EXTRA_SUPPORT_CHECK,
        isFalse: KitchenCompletedStages.LEVEL_CHECK_NO_ANSWER
      },
      {
        id: KitchenCompletedStages.LEVEL_CHECK_NO_ANSWER,
        type: STAGE.TEXT,
        label: "What need to be done before we can do the installation?",
        isConfirm: KitchenCompletedStages.EXTRA_SUPPORT_CHECK,
        isSubQuestion: true,
        uploadFilesLabel: "Picture Level",
        uploadFiles: true,
        multiline: true,
        multiple: true,
      },
      {
        id: KitchenCompletedStages.EXTRA_SUPPORT_CHECK,
        type: STAGE.BOOL,
        label: "Do you need any extra support in front of cooktop/sink?",
        isTrue: KitchenCompletedStages.EXTRA_SUPPORT_CHECK_YES_ANSWER,
        isFalse: KitchenCompletedStages.FINAL_PREPARATION_CHECK,
      },
      {
        id: KitchenCompletedStages.EXTRA_SUPPORT_CHECK_YES_ANSWER,
        type: STAGE.TEXT,
        label: "What need to be done before we can do the installation?",
        isConfirm: KitchenCompletedStages.FINAL_PREPARATION_CHECK,
        isSubQuestion: true,
        uploadFilesLabel: "Picture Extra Support",
        uploadFiles: true,
        multiline: true,
        multiple: true,
      },
      {
        id: KitchenCompletedStages.FINAL_PREPARATION_CHECK,
        type: STAGE.BOOL,
        label: "Is it anything else that need to be done before we can install our plates?",
        isTrue: KitchenCompletedStages.FINAL_PREPARATION_CHECK_YES_ANSWER,
        isFalse: (currentValues: Record<string, string | boolean>, stepId: string) => {
          const stepsRequired = [
            { id: KitchenCompletedStages.WALL_SUPPORT_CHECK, value: true },
            { id: KitchenCompletedStages.LEVEL_CHECK, value: true },
            { id: KitchenCompletedStages.EXTRA_SUPPORT_CHECK, value: false },
            { id: KitchenCompletedStages.FINAL_PREPARATION_CHECK, value: false },
          ]

          let isDisplayed = stepsRequired.every((pt) => {
            const value = currentValues[getValueKey(stepId, pt.id || '')];
            return value !== undefined
          })

          if (isDisplayed) {
            isDisplayed = stepsRequired.some((pt) => {
              const value = currentValues[getValueKey(stepId, pt.id || '')];
              return value !== pt.value
            });

            if (isDisplayed) {
              return KitchenCompletedStages.KITCHEN_IS_POINT_ABOVE;
            }
          }

          return ''
        },
      },
      {
        id: KitchenCompletedStages.FINAL_PREPARATION_CHECK_YES_ANSWER,
        type: STAGE.TEXT,
        label: "What need to be done before we can do the installation?",
        isSubQuestion: true,
        isConfirm: KitchenCompletedStages.KITCHEN_IS_POINT_ABOVE,
        uploadFilesLabel: "Picture Final Preparation",
        uploadFiles: true,
        multiline: true,
        multiple: true,
      },
      {
        id: KitchenCompletedStages.KITCHEN_IS_POINT_ABOVE,
        type: STAGE.BOOL,
        label: "Is the point above informed to the kitchen installers?",
      },
    ]
  },
  {
    id: MeasurementSteps.ENTRANCE,
    label: "Entrance",
    stages: [
      {
        id: EntranceStages.WHICH_FLOOR_KITCHEN,
        type: STAGE.TEXT,
        label: "Which floor is the kitchen on?",
        isConfirm: EntranceStages.WHERE_PLANNED_STONE,
      },
      {
        id: EntranceStages.WHERE_PLANNED_STONE,
        type: STAGE.TEXT,
        label: "Where have you planned to go with the stone?",
        multiline: true,
        isConfirm: null,
        uploadFiles: true,
        uploadFilesLabel: "Upload",
      }
    ]
  }
];

export function getMeasurementWizardQuestions(options: any): StepType[] {
  const { carryAssistanceTypes } = options;
  return MeasurementWizardQuestions.map(step => {
    if (
      carryAssistanceTypes &&
      carryAssistanceTypes.length > 0 &&
      step.id === MeasurementSteps.LIFTING
    ) {
      const types = carryAssistanceTypes.map((type: string) => `"${type.toLowerCase()}"`).join(' ');
      const extraText = ` + helper ${types}`;

      const updatedStages = step.stages.map(stage => {
        if (stage.id === LiftingHelpStages.ADDITIONAL_LIFTING_HELP) {
          return {
            ...stage,
            label: stage.label.replace("?", extraText),
          };
        }
        return stage;
      });

      return {
        ...step,
        stages: updatedStages
      };
    } else {
      return step;
    }
  })
}

function uploadFilesOptional(key: any, array: any, files: any) {
  const items = key && Array.isArray(array) ? array.map((item: any, index: number) => {
    return {
      ...item,
      hasFiles: files.some((file: any) => file.file_type === key+index)
    }
  }) : [];
  return items && !items.some(item => item.affectPriceOption !== PRICE_FROM_OFFICE && !item.hasFiles);
}
