import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, Tooltip } from '@material-ui/core';
import Cancel from "@material-ui/icons/Cancel";

import { formatToDateWithTime } from '../../services/DateFormatter';

type DataTableFaultyOrderType = {
  cancelDate: Date
};

const useStyles = makeStyles((theme: Theme) => ({
  fault: {
    marginRight: theme.spacing(1)
  },
}));

export function DataTableCanceledOrder({ cancelDate }: DataTableFaultyOrderType) {
  const classes = useStyles();

  if (!cancelDate) {
    return null;
  }

  return (
    <Tooltip title={`Cancel Date ${formatToDateWithTime(cancelDate)}`}>
      <Cancel color="secondary" classes={{ root: classes.fault }}/>
    </Tooltip>
  );
}
