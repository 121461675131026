export const PLATE_TYPE = {
  PREPARE: "PREPARE" as "PREPARE",
  FIND: "FIND" as "FIND",
  CUT: "CUT" as "CUT",
  EDGE_POLISH: "EDGE_POLISH" as "EDGE_POLISH",
  HOLES: "HOLES" as "HOLES",
  MANUAL_FINISH: "MANUAL_FINISH" as "MANUAL_FINISH",
  INSTALL_SINK: "INSTALL_SINK" as "INSTALL_SINK",
  END_CONTROL: "END_CONTROL" as "END_CONTROL",
  PACKING: "PACKING" as "PACKING",
  INSTALLATION: "INSTALLATION" as "INSTALLATION",
  COMPLETED: "COMPLETED" as "COMPLETED"
};

export const CANCEL_TYPE = "CANCEL_TYPE";

export const ORDER_TYPE = {
  ESTIMATION: "ESTIMATION" as "ESTIMATION",
  CONFIRMATION: "CONFIRMATION" as "CONFIRMATION",
  MEASUREMENT: "MEASUREMENT" as "MEASUREMENT",
  INTERNAL_CONTROL: "INTERNAL_CONTROL" as "INTERNAL_CONTROL",
  PREPARE: "PREPARE" as "PREPARE",
  INSTALLATION: "INSTALLATION" as "INSTALLATION"
};

export const COMMENT_TYPE = {
  REGULAR: "REGULAR" as "REGULAR",
  ON_HOLD: "ON_HOLD" as "ON_HOLD",
  ON_HOLD_FLOW: "ON_HOLD_FLOW" as "ON_HOLD_FLOW",
  ON_HOLD_OFF: "ON_HOLD_OFF" as "ON_HOLD_OFF",
  DEVIATION: "DEVIATION" as "DEVIATION",
  INFO: "INFO" as "INFO",
  MEASUREMENT: "MEASUREMENT" as "MEASUREMENT",
  INSTALLATION: "INSTALLATION" as "INSTALLATION",
  FAULT: "FAULT" as "FAULT"
};

export enum NOTIFICATION_TYPE {
  FAULT = 'FAULT',
}

export const COMMENT_FOR = ["MEASUREMENT", "OFFICE", "PRODUCTION", "INSTALLATION"];