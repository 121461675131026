import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import classnames from 'classnames';

type RowType = {
  className?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(1)
  }
}));

export const Row: React.FunctionComponent<RowType> = ({ children, className = "" }) => {
  const styles = useStyles();
  const rootStyle = classnames({
    [styles.root]: true,
    [className]: !!className
  });

  return (
    <div className={rootStyle}>
      {children}
    </div>
  );
};
