import * as React from 'react'
import { InputLabel, MenuItem, Select, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";

type Props = {
  index: number;
  currentValue?: string;
  onClick: (index: number, property: 'affectPriceOption', value: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(3),
  },
  select: {
    width: 450,
  }
}));

export const WizardAffectPriceOption: React.FC<Props> = ({ currentValue, index, onClick }) => {
  const classes = useStyles();
  const onSelect = (e: React.ChangeEvent<{ value: unknown }>) => {
    onClick(index, 'affectPriceOption', e.target.value as string)
  }
  const affectPriceOptionsDictionary = useSelector((state: GlobalStoreType) => state.dictionary.affectPriceOptionsDictionary);
  if (!affectPriceOptionsDictionary) {
    return null;
  }

  return (
    <div className={classes.root}>
      <FormControl classes={{ root: classes.select }}>
        <InputLabel id={`select-helper-${index}`}>Solution</InputLabel>
        <Select
          value={currentValue}
          onChange={onSelect}
          fullWidth
          inputProps={{ name: 'select', id: `select-helper-${index}` }}
        >
          {affectPriceOptionsDictionary.map(({ text, value }: DictionaryType) => (
            <MenuItem key={value} value={value}>
              {text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
