import * as React from 'react'
import { DialogActions, Theme } from "@material-ui/core";
import { WizardStepStageBool } from "./WizardStepStageBool";
import { makeStyles } from "@material-ui/styles";

type Props = {
  index: number;
  currentValue?: boolean;
  onClick: (index: number, property: 'affectPrice', value: boolean) => void;
}


const useStyles = makeStyles((theme: Theme) => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.fontSize,
    fontWeight: 'bold'
  },
}));

export const WizardAffectPrice: React.FC<Props> = ({ currentValue, index, onClick }) => {
  const classes = useStyles();
  return (
    <DialogActions>
      <div className={classes.label}>
        Affect Price
      </div>
      <WizardStepStageBool isSelected={currentValue === true} onClick={() => onClick(index, 'affectPrice', true)}>
        Yes
      </WizardStepStageBool>
      <WizardStepStageBool isSelected={currentValue === false} onClick={() => onClick(index, 'affectPrice', false)}>
        No
      </WizardStepStageBool>
    </DialogActions>
  )
}
